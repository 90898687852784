.left_bar{
  width: 360px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 10px;
}

.left_bar_close{
  display: none;
  width: 30px;
  height: 30px;
  background-image: url(../../../img/cross_white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  position: absolute;
  left: calc(100% + 10px);
  top: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1499px) and (min-width: 1000px){
	.left_bar{
	  width: 300px;
	}
}

@media screen and (max-width: 999px){
  .left_bar{
	height: 100%;
	position: fixed;
	top: 0;
	left: -360px;
	transition: left .5s;
	z-index: 6;
  }

  .left_bar.active{
	left: 0;
  }

  .left_bar.active .left_bar_close{
	display: block;
  }
}

@media screen and (max-width: 599px){
  .left_bar{
	width: 270px;
	left: -270px;
  }
}
