@import "@sitestyles";

.chartContainer {
    overflow: auto !important;
}
.container {
	max-width: 100% !important;
}
.stl {
	overflow: auto !important;
	flex: auto !important;
}
