@import "@sitestyles";

.letters_attach_files_item{
	position: relative;
	padding: 0 10px;
	margin-right: 15px;
	background-color: #efefef;
	border-radius: 5px;
  	max-height: 100px;
}
.letters_attach_files_item .rhap_container{
	box-shadow: none;
	background-color: transparent;
	height: 53px;
	width: 210px;
}

.letters_attach_files_item .rhap_main-controls{
	top: -8px;
}

.letters_attach_files_item .rhap_progress-section{
	width: calc(100% - 40px);
	position: relative;
	top: 28px;
	left: 40px;
	justify-content: space-between;
}
.letters_attach_files_item .rhap_volume-controls{
	width: 0;
}
.letters_attach_files_item .rhap_volume-controls{
	display: none;
}
.letters_attach_files_item .rhap_progress-container{
	display: inline-block;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.letters_attach_files_item .rhap_current-time{
	position: relative;
	top: 3px;
	font-size: 12px;
	left: 0;
	color: rgba(51, 51, 51, 0.6);
	letter-spacing: -0.03em;
}
.letters_attach_files_item .rhap_play-pause-button{
	position: relative;
	top: 0px;
	width: 31px;
	height: 31px;
}

.letters_attach_files_item .rhap_play-pause-button img{
	margin: 0;
	top: 0;
	vertical-align: top;
	width: 31px;
}

.letters_attach_files_item .rhap_total-time{
	position: relative;
	top: 3px;
	font-size: 12px;
	right: 0;
	color: rgba(51, 51, 51, 0.6);
	letter-spacing: -0.03em;
}


.letters_attach_files_item .letters_attach_files_item_play_title{
	position: absolute;
	top: 6px;
	left: 50px;
	color: #4C3083;
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	width: 170px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	z-index: 1;
}
.letters_attach_files_item .rhap_progress-indicator {
	width: 15px;
	height: 15px;
	top: -6px;
	display: none;
}

.letters_attach_files_item img {
	display: inline-block !important;
	position: relative;
	top: 2px;
	margin-right: 5px;
}

.popup_write_letter .letters_attach_files_item .rhap_progress-section {
  //left: 22px;
  left: 42px;
}

.popup_write_letter .letters_attach_files_item .letters_attach_files_item_play_title{
	top: 13px;
}

.popup_write_letter .letters_attach_files_item .letters_attach_files_item_play_title{
	left: 52px;
}

.popup_write_letter .letters_attach_files_item .rhap_current-time{
	right: -22px;
}

.popup_write_letter .letters_attach_files_item .rhap_total-time{
	right: 22px;
}

@media screen and (max-width: 599px){
	.letters_attach_files_item .rhap_container{
		width: 175px;
	}

	.letters_attach_files_item .letters_attach_files_item_play_title{
		width: 120px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item.file_audio .rhap_container{
		width: 150px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item .letters_attach_files_item_play_title{
		width: 105px;
		font-size: 10px;
		top: 2px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item.file_audio .rhap_container{
		height: 43px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item.file_audio{
		height: 38px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item .rhap_progress-section{
		top: 18px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item .rhap_current-time{
		top: 5px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item .rhap_total-time{
		top: 5px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item .rhap_main-controls{
		top: -11px;
	}
}
