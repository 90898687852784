@import "@sitestyles";

.clmn_3_chat_bottom{
  position: relative;
  background-color: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 9px;
  padding: 10px 10px 10px 10px;
}

.clmn_3_chat_textarea{
  font-family: "Roboto", sans-serif;
  display: block;
  width: 100%;
  height: 148px;
  background-color: #F7F8FB;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  resize: none;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 16px;
  color: #4F4F4F;
  outline: none;
}

.clmn_3_chat_select_input{
  margin: 10px 0;
}

.clmn_3_chat_textarea_double{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
}

.clmn_3_chat_textarea_wrap{
  width: calc(50% - 3px);
  padding: 10px 12px;
  background-color: #F7F8FB;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 5px;
}

.clmn_3_chat_textarea_translate_info{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 14px;
  justify-content: space-between;
}

.clmn_3_chat_textarea_translate_text{
  font-family: "Roboto", sans-serif;
  color: #0B6BA7;
  margin-right: 8px;
}

.clmn_3_chat_textarea_translate_language{
  width: 168px;
  height: 27px;
  line-height: 27px;
  padding-left: 11px;
  background-color: #EAEDF6;
  border-radius: 5px;
  background-image: url(../../../../img/v2/language-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  font-size: 13px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #374E6C;
  cursor: pointer;
}

.clmn_3_chat_textarea_translate_select {
  width: 200px;

  @media screen and (max-width: 1600px) {
	width: 160px;
  }
}

.clmn_3_chat_textarea_translate_symbols {
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #205376;
  margin-left: 15px;

  &.error {
	color: #d04c4c;
  }

  @media screen and (max-width: 1600px) {
	font-size: 11px;
  }
}

.clmn_3_chat_textarea_inner{
  font-family: "Roboto", sans-serif;
  display: block;
  width: 100%;
  height: 84px;
  background-color: #F7F8FB;
  resize: none;
  font-size: 16px;
  color: #4F4F4F;
  padding: 3px;
}

.clmn_3_chat_textarea_translate_arrow_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0 40px 0;
}

.clmn_3_chat_textarea_translate_arrow {
  background: #F7F8FB;;
  border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 3px;
  margin: 0 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: max-content;
  align-self: center;
  cursor: pointer;

  &:active {
	background-color: #EAEDF6;
  }

  &.disabled_btn {
	opacity: .5;
	pointer-events: none;
  }
}

.clmn_3_chat_bottom_nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
}

.clmn_3_chat_bottom_nav_left{

}


.clmn_3_chat_bottom_options{
display: flex;
}

.clmn_3_chat_bottom_options_hidden{
display: flex;
}

.clmn_3_chat_bottom_option_btn{
width: 30px;
height: 30px;
background-repeat: no-repeat;
background-position: center;
margin-right: 20px;
cursor: pointer;
}

.clmn_3_chat_bottom_option_btn:last-child{
margin-right: 0;
}

.clmn_3_chat_bottom_option_btn.image{
background-image: url(../../../../img/v2/c3op-image.svg);
}

.clmn_3_chat_bottom_option_btn.video{
background-image: url(../../../../img/v2/c3op-video.svg);
}

.clmn_3_chat_bottom_option_btn.audio{
background-image: url(../../../../img/v2/c3op-audio.svg);
}

.clmn_3_chat_bottom_option_btn.smiles{
background-image: url(../../../../img/v2/c3op-smiles.svg);
}

.clmn_3_chat_bottom_option_btn.wink{
background-image: url(../../../../img/v2/c3op-wink.svg);
}

.clmn_3_chat_bottom_option_btn.media{
background-image: url(../../../../img/v2/c3op-media.svg);
}

.clmn_3_chat_bottom_option_btn.virtual_gift {
  background-image: url(../../../../img/v2/c3op-gift.svg);
  background-size: contain;

  @media screen and (max-width: 599px) {
	background-size: 20px;
  }
}

.clmn_3_chat_bottom_option_btn.like{
background-image: url(../../../../img/v2/c3op-like.svg);
//margin-left: 20px;
flex: none;
}

.clmn_3_chat_bottom_symbols{
font-size: 12px;
letter-spacing: -0.01em;
color: #205376;
margin-right: auto;
flex: 1;
text-align: center;
}

.clmn_3_chat_bottom_nav_right{
display: -webkit-flex;
display: -moz-flex;
display: -ms-flex;
display: -o-flex;
display: flex;
}

.clmn_3_chat_bottom_like_dis{
display: flex;
align-items: center;
margin-right: 38px;
}

.clmn_3_chat_bottom_like_dis_item{
width: 30px;
height: 30px;
background-repeat: no-repeat;
background-position: center;
cursor: pointer;
}

.clmn_3_chat_bottom_like_dis_item.like{
background-image: url(../../../../img/v2/chat-bottom-like.svg);
margin-right: 14px;
}

.clmn_3_chat_bottom_like_dis_item.like.active{
background-image: url(../../../../img/v2/chat-bottom-like-a.svg);
}

.clmn_3_chat_bottom_like_dis_item.dislike{
background-image: url(../../../../img/v2/chat-bottom-dislike.svg);
}

.clmn_3_chat_bottom_like_dis_item.dislike.active{
background-image: url(../../../../img/v2/chat-bottom-dislike-a.svg);
}

.clmn_3_chat_bottom_translate{
display: -webkit-flex;
display: -moz-flex;
display: -ms-flex;
display: -o-flex;
display: flex;
-ms-align-items: center;
align-items: center;
margin-right: 27px;
}

.clmn_3_chat_bottom_translate_tmblr{
position: relative;
margin-right: 13px;
}

.clmn_3_chat_bottom_translate_tmblr_inp{
display: none;
}

.clmn_3_chat_bottom_translate_tmblr_label{
display: block;
width: 36px;
height: 17px;
background-color: #CDD3DA;
border-radius: 19px;
position: relative;
cursor: pointer;
border: 1px solid #CDD3DA;
}

.clmn_3_chat_bottom_translate_tmblr_label:before{
content: '';
display: block;
width: 13px;
height: 13px;
background-color: #fff;
border-radius: 100%;
position: absolute;
left: 1px;
top: 1px;
transition: .3s;
}

.clmn_3_chat_bottom_translate_tmblr_inp:checked + .clmn_3_chat_bottom_translate_tmblr_label{
background-color: #fff;
border-color: #E9EEF2;
}

.clmn_3_chat_bottom_translate_tmblr_inp:checked + .clmn_3_chat_bottom_translate_tmblr_label:before{
background-color: #3664A1;
left: 21px;
}


.clmn_3_chat_bottom_translate_text{
font-weight: 500;
font-size: 16px;
letter-spacing: -0.02em;
text-transform: uppercase;
color: #374E6C;
}


.clmn_3_chat_bottom_send_btn{
width: 109px;
height: 49px;
display: -webkit-flex;
display: -moz-flex;
display: -ms-flex;
display: -o-flex;
display: flex;
justify-content: center;
-ms-align-items: center;
align-items: center;
background: $color5;
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
border-radius: 4px;
font-weight: bold;
font-size: 16px;
letter-spacing: 0.01em;
color: #fff;
cursor: pointer;

&.disabled_btn {
opacity: .5;
pointer-events: none;
}
}

.clmn_3_chat_bottom_send_btn img{
margin-bottom: 6px;
}

.c3_attach{
display: flex;
}

.dis_active{
cursor: not-allowed;
}

.clmn_3_chat_bottom_option_btn.media > svg{
max-width: 28px;
height: 28px;
stroke: #357E89;
flex: none;
cursor: pointer;
}

@media screen and (max-width: 1599px){
//.clmn_3_chat_bottom_options{
//flex: 0;
//width: calc(100% - 300px);
//position: absolute;
//overflow: hidden;
//z-index: 3;
//background-color: #fff;
//padding: 5px;
//border-radius: 5px;
//}
//
//.clmn_3_chat_bottom_options:hover{
//overflow: visible;
//width: 370px;
//box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
//}

//.clmn_3_chat_bottom_options:after{
//content: '';
//display: block;
//width: 42px;
//height: 100%;
//position: absolute;
//right: 0;
//top: 50%;
//transform: translateY(-50%);
//background-image: url(../../../../img/v2/chat_form_mob_more.svg);
//background-repeat: no-repeat;
//background-position: center;
//background-color: #fff;
//}
//
//.clmn_3_chat_bottom_options:hover:after{
//display: none;
//}


.clmn_3_chat_bottom_send_btn{
width: auto;
padding: 0 10px;
height: 38px;
font-size: 14px;
}

.clmn_3_chat_bottom_send_btn img{
width: 22px;
margin-bottom: 4px;
margin-right: 6px;
}

.clmn_3_chat_bottom_translate_text{
font-size: 14px;
}
}

@media screen and (max-width: 1599px) and (min-width: 1000px), screen and (max-width: 599px){
.clmn_3_chat_bottom_nav{
//flex-direction: column;
//align-items: flex-start;
}

.clmn_3_chat_bottom_nav_right{
//margin-top: 10px;
//margin-left: auto;
}

  .clmn_3_chat_bottom_options {
	display: grid;
	grid-template-columns: repeat(4, 30px);
	grid-gap: 15px;
  }

  .clmn_3_chat_bottom_option_btn.image {
	//grid-column-start: 3;
	//grid-column-end: 5;
  }

.clmn_3_chat_bottom_option_btn:not(.sender){
display: flex;
align-items: center;
justify-content: center;
margin: 0;
}

.clmn_3_chat_bottom_option_btn.media svg{
//height: 25px;
}
}

@media screen and (max-width: 999px){
.clmn_3_chat_bottom{
padding: 10px;
}

.clmn_3_chat_textarea{
height: 55px;
font-size: 14px;
}

.clmn_3_chat_bottom_translate_text{
font-size: 12px;
text-transform: uppercase;
}

.clmn_3_chat_bottom_send_btn{
width: 88px;
height: 32px;
font-size: 14px;
}

.clmn_3_chat_bottom_send_btn img{
margin-right: 4px;
width: 20px;
}

.clmn_3_chat_bottom_option_btn{
width: 24px;
height: 24px;
background-size: contain;
}

.clmn_3_chat_bottom_option_btn.media > svg{
width: 100%;
height: 100%;
}

.clmn_3_chat_bottom_option_btn.media{
//margin-right: 0;
}
}

@media screen and (max-width: 599px){
.clmn_3_chat_bottom_option_btn{
width: 30px;
height: 30px;
background-size: 20px;
margin-right: 0;
}

.clmn_3_chat_bottom_option_btn.media{
display: flex;
align-items: center;
justify-content: center;
}

.clmn_3_chat_bottom_option_btn.media > svg{
width: 20px;
height: 20px;
}

.clmn_3_chat_bottom_options{
//margin-left: auto;
//margin-right: auto;
grid-gap: 5px;
}

.clmn_3_chat_textarea_translate_info{
display: block;
}

.clmn_3_chat_textarea_inner{
width: 100%;
height: 50px;
outline: none;
padding: 5px;
}

.clmn_3_chat_textarea_translate_language{
width: 100%;
}

.clmn_3_chat_bottom_translate{
flex-direction: column;
margin-right: 0;
margin-bottom: 5px;
}

.clmn_3_chat_bottom_translate_tmblr{
margin-right: 0;
margin-bottom: 5px;
}

.clmn_3_chat_bottom_option_btn.image{
//grid-column-start: unset;
//grid-column-end: unset;
}

.clmn_3_chat_bottom_option_btn.like{
//grid-row-start: 1;
//grid-row-end: 3;
//grid-column-start: 4;
//grid-column-end: 5;
//margin-left: 0;
//margin-top: auto;
//margin-bottom: auto;
}

.clmn_3_chat_bottom_nav_right{
flex-direction: column;
}
}
