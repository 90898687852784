@import "@sitestyles";

.choose_sender{
  background-color: #fff;
  padding: 0 0 11px 11px;
  border-radius: 4px;
  margin-bottom: 10px;
  height: 100%;
}

.list{
  margin-top: 6px;
  height: 96%;
  overflow-y: auto;
  margin-right: 3px;
  padding-right: 3px;
}

.list::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.list::-webkit-scrollbar-thumb {
  background: #96B6CF;
  border-radius: 3px;
}

.item{
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 8px;
  padding: 7px 22px 7px 11px;
  cursor: pointer;
}

.item:hover{
  background-color: rgba(135, 171, 198, .2);
}

.item.active{
  background: $color7;
}

.profile{
  display: flex;
  align-items: center;
}

.photo_wrap{
  position: relative;
  margin-right: 9px;
}

.photo_wrap:after{
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  right: 3px;
  bottom: 3px;
  background-color: #b2b2b2;
  border: 2px solid #fff;
}

.active .photo_wrap:after{
  border-color: #A8C5CB;
}

.photo_wrap.online:after{
  background-color: #27AE60;
}

.photo{
  width: 56px;
  height: 56px;
  border-radius: 100%;
  object-fit: cover;
}

.name{
  font-weight: 600;
  color: $color3;
  margin-bottom: 4px;
}

.info_bottom{
  display: flex;
  align-items: center;
  width: 100%;
}

.id{
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: rgba(51, 51, 51, 0.7);
  margin-right: 8px;
}

.place{
  font-size: 10px;
  letter-spacing: -0.03em;
  color: #565E61;
  display: flex;
  align-items: center;
}

.place img{
  height: 9px;
  border-radius: 2px;
  margin-right: 4px;
}

.check_img{
  display: block;
  width: 22px;
  height: 22px;
  background-image: url(../../../../img/v2/sender_choose_label.svg);
  background-repeat: no-repeat;
  background-position: center;
  flex: none;
}
.info{
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.active .check_img{
  background-image: url(../../../../img/v2/sender_choose_label_a.svg);
}

.clmn_1_mm_chat_list_item_status_green{
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #357F89;
  margin-left: 6px;
  margin-bottom: 4px;
  align-self: center;
}
