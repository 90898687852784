@import "@sitestyles";

.letters_chat_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.letters_chat {
  flex: 1;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 4px 4px;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 20px 0;
}

.letters_chat_list_scroll {
  height: 100%;
  overflow-y: auto;
  padding: 0 10px 0 20px;
  margin-right: 10px;
}

.letters_chat_list_scroll::-webkit-scrollbar {
  width: 2px;
  background: #fff;
  border-radius: 3px;
}

.letters_chat_list_scroll::-webkit-scrollbar-thumb {
  background: #96B6CF;
  border-radius: 3px;
}

.letters_chat_list {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
}

.letters_chat_item {
  border: 1px solid #A7C4CB;
  border-radius: 4px;
  margin-bottom: 12px;
  position: relative;
}

.letters_chat_item_top {
  padding: 20px 20px 10px 20px;
}

.letters_chat_item:last-child {
  margin-bottom: 0;
}

.letters_chat_item.from_you {
  background-color: #fff;
}

.letters_chat_item.to_you {
  background-color: #E5F0F7;
}

.letters_chat_item_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: flex-start;
}

.letters_chat_item_user {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $color3;
}

.letters_chat_item.from_you .letters_chat_item_user {
  margin-left: auto;

}

.letters_chat_item_user img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  display: block;
  object-fit: cover;
}

.letters_chat_item.from_you .letters_chat_item_user_photo {
  margin-left: 17px;
}

.letters_chat_item.to_you .letters_chat_item_user_photo {
  margin-right: 17px;
  position: relative;
}

.letters_chat_item.to_you .letters_chat_item_user_photo:after {
  content: '';
  box-sizing: border-box;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #DADADA;
  border: 2px solid #E5F0F7;
}

.letters_chat_item.to_you .letters_chat_item_user_photo.online:after {
  background-color: #27AE60;
}

.letters_chat_item_bookmark {
  cursor: pointer;
}

.letters_chat_item_text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 150%;
  color: #4F4F4F;
}

.letters_chat_item_text p {
  word-break: break-word;
  white-space: break-spaces;
}

.letters_chat_item_bottom {
  display: flex;
  align-items: center;
  margin-top: 19px;
  justify-content: flex-end;
}

.letters_chat_item_label {
  font-family: "Roboto", sans-serif;
  height: 21px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  background-color: #9947ed;
  color: #fff;
  width: 90px;
  position: absolute;
  right: 10px;
  top: 10px
}

.letters_chat_item_label.operator {
  left: 10px;
}

.letters_chat_item.to_you .letters_chat_item_bottom {
  justify-content: flex-start;
}

.letters_chat_item_time {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.4);
  margin-right: 10px;
}

.letters_chat_item_delete {
  cursor: pointer;
}

.letters_chat_item_delete img {
  display: block;
}

.letters_list_item_bottom {
  padding: 10px 0px;
  border-top: 1px solid #A7C4CB;
  position: relative;
  overflow: hidden;
}

.letters_list_item_media_slider {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.letters_list_item_media_item {
  margin-right: 4px;
  cursor: pointer;
}

.letters_list_item_media_item > img {
  height: 53px;
  width: auto;
  border-radius: 5px;
  min-width: 32px;
  object-fit: cover;
  display: block;
}

.letters_list_item_media_item.video {
  position: relative;
}

.letters_list_item_media_item.video:after {
  content: '';
  display: block;
  width: 29px;
  height: 29px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 5px rgba(47, 43, 99, 0.55);
  background-image: url(../../../img/v2/letters-media-play.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.letters_chat_form {
  height: 236px;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.clmn_3_chat_list_wrap {
  flex: 1px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  padding: 6px 0;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.clmn_3_chat_scroll {
  height: calc(100% - 18px);
  overflow-y: auto;
  padding: 0 12px 0 20px;
  margin-right: 5px;
}

.clmn_3_chat_scroll::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.clmn_3_chat_scroll::-webkit-scrollbar-thumb {
  background: #ADBAFF;
  border-radius: 3px;
}


.clmn_3_chat_list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
}

.clmn_3_chat_typing {
  font-family: "Roboto", sans-serif;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.chat_typing_left {
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: $color1;
}

.chat_typing_right {
  text-align: right;
  letter-spacing: -0.01em;
  color: rgba(51, 51, 51, 0.7);
}

.chat_typing_left img {
  margin-right: 14px;
}

.clmn_3_chat_typing span span {
  font-weight: 500;
}

.chat_was_online {
  font-size: 12px;
  color: #27AE60;
}

.chat_was_online.week {
  color: #E99C2A;
}

.chat_was_online.month {
  color: #EB5757;
}

.wrapper_main_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
}

.main_loader {
  left: unset;
  top: unset;
}

.message_status {
  width: 12px;
  height: 9px;
  background-size: contain;
  background-repeat: no-repeat;
}

.message_hide {
  margin-left: 3px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: #27AE60;
  cursor: pointer;
}

.message_status.send {
  background-image: url(../../../img/v2/send.svg);
}

.message_status.readed {
  background-image: url(../../../img/v2/readed.svg);
  width: 14px;
}
