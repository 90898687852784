@import "@sitestyles";

.clmn_2{
	width: 370px;
	padding: 10px 5px;
}
.clmn_2.clmn_2_chat{
	display: flex;
	flex-direction: column;
	height: 100%;
}

@media screen and (max-width: 999px){
	.clmn_2.clmn_2_chat{
		position: absolute;
		left: -100%;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #EEF1F5;
		z-index: 4;
		padding: 10px;
		transition: .3s;
	}

	.clmn_2.clmn_2_chat.active{
		left: 0;
	}
}

@media screen and (max-width: 599px){
	.clmn_2.clmn_2_chat{
		padding: 5px;
	}
}
