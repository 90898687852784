@import "@sitestyles";

.sender_drafts{
  background-color: #fff;
  padding: 0 11px 11px 11px;
  border-radius: 4px;
  margin-bottom: 10px;
  flex: 1;
  overflow: hidden;
}

.header_selects{
  display: flex;
  align-items: center;
}

.header_selects_title{
  font-weight: 500;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.select_item_wrap{
  position: relative;
  height: 25px;
  margin-left: 7px;
}

.select_item{
  width: max-content;
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
}

.select_item_head{
  height: 25px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #DBE6EE;
  border-radius: 20px;
  font-size: 12px;
  color: $color3;
  cursor: pointer;
}

.select_item.open .select_item_head{
  border-radius: 7px 7px 0 0;
}

.select_item.selected .select_item_head{
  font-weight: 500;
  color: #26607B;
}

.select_item_head_arrow{
  display: block;
  width: 6px;
  height: 9px;
  background-image: url(../../../../img/v2/sender_group_arrow.svg);
  background-repeat: no-repeat;
  margin-left: 13px;
  transition: .3s;
}

.select_item_head_more{
  font-size: 10px;
  color: #707070;
  font-weight: normal;
  margin-left: 4px;
}

.select_item.open .select_item_head_arrow{
  transform: rotate(-90deg);
}

.select_item_type.selected .select_item_head_arrow{
  display: none;
}

.select_item_list{
  background-color: #DBE6EE;
  border-radius: 0 0 7px 7px;
  display: none;
  padding-bottom: 6px;
}

.select_item.open .select_item_list{
  display: block;
}

.select_item_list_inner{
  margin-right: 3px;
  max-height: 135px;
  overflow-y: auto;
}

.select_item_list_inner::-webkit-scrollbar {
  width: 3px;
  background: transparent;
  border-radius: 3px;
}

.select_item_list_inner::-webkit-scrollbar-thumb {
  background: #96B6CF;
  border-radius: 3px;
}

.select_item_list_inner input{
  display: none;
}

.select_item_list_inner label{
  display: block;
  margin: 0 6px;
  padding: 8px 40px 10px 6px;
  cursor: pointer;
  font-size: 12px;
  color: $color3;
  border-radius: 5px;
}

.select_item_list_inner label.selected{
  font-weight: 600;
  background-image: url(../../../../img/v2/sender_drafts_remove.svg);
  background-size: 20px;
  background-position: right 5px center;
  background-repeat: no-repeat;
}

.select_item_list_inner label:hover{
  background-color: #C0D6E6;
}

.drafts_table{
  margin-top: 15px;
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
}

.drafts_table_head{
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  font-size: 12px;
  color: #535973;
}

.drafts_table_head_col_1{
  width: 457px;
  padding-left: 6px;
}

.drafts_table_head_col_2{
  width: 180px;
  margin: 0 16px;
  text-align: center;
}

.drafts_table_head_col_3{
  width: 160px;
  text-align: center;
}

.drafts_table_list{
  flex: 1;
  overflow-y: auto;
  margin-right: -5px;
  padding-right: 5px;
}

.drafts_table_list::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.drafts_table_list::-webkit-scrollbar-thumb {
  background: #96B6CF;
  border-radius: 3px;
}

.drafts_table_item{
  border: 1px solid #BCCBD6;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
}

.drafts_table_item.active{
  border: 3px solid #00aa37;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
}

.drafts_table_item:last-child{
  margin-bottom: 0;
}

.drafts_table_item_top{
  display: flex;
  align-items: flex-start;
}

.drafts_table_item_text{
  padding: 6px 2px 0 8px;
  color: #333;
  font-size: 12px;
  //width: 446px;
  flex: 1;
  max-height: 67px;
  overflow-y: auto;
  word-break: break-word;
  margin-right: 20px;
}

.drafts_table_item_text::-webkit-scrollbar {
  width: 3px;
  background: transparent;
  border-radius: 3px;
}

.drafts_table_item_text::-webkit-scrollbar-thumb {
  background: #96B6CF;
  border-radius: 3px;
}

.drafts_table_item_attach{
	cursor: pointer;
  position: relative;
}

.drafts_table_item_attach.photo img{
	height: 40px;
}

.drafts_table_item_attach.video video{
  height: 40px;
  display: block;
}

.drafts_table_item_attach_video_play{
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.drafts_table_item_reciever{
  width: 180px;
  margin: 0 16px;
  padding-top: 6px;
  text-align: center;
}

.drafts_table_item_reciever_item{
  font-weight: 500;
  font-size: 12px;
  color: #535973;
  margin-bottom: 5px;
}

.drafts_table_item_settings{
  width: 160px;
  text-align: center;
  padding-top: 6px;
}

.drafts_table_item_settings_no_spec{
  font-weight: 500;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
}

.drafts_table_item_options{
  margin-left: auto;
  display: flex;
  align-items: center;
}

.drafts_table_item_option_edit{
  cursor: pointer;
  margin-right: 15px;
}

.drafts_table_item_option_delete{
  cursor: pointer;
}

.drafts_table_item_bottom{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 8px;
}

.drafts_table_item_time{
  font-size: 10px;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.7);
}

.drafts_table_item_send_btn{
  width: 99px;
  height: 28px;
  background: $color1;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.drafts_table_item_send_btn img{
  width: 20px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.drafts_table_item_settings_item{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #535973;
  margin-bottom: 15px;
  justify-content: center;
}

.drafts_table_item_settings_item:last-child{
  margin-bottom: 0;
}

.drafts_table_item_settings_item img{
  margin-right: 8px;
}

.drafts_table_item_settings_item span + span{
  margin-left: 8px;
}
.not_active{
  cursor: not-allowed;
  background: #a1a6ad;
}


.drafts_table_item_media_list_scroll{
  overflow-x: auto;
  margin-bottom: 5px;
  margin-top: 15px;
}

.drafts_table_item_media_list_scroll::-webkit-scrollbar{
  width: 3px;
  height: 3px;
  background: #fff;
  border-radius: 3px;
}

.drafts_table_item_media_list_scroll::-webkit-scrollbar-thumb{
  background: #96B6CF;
  border-radius: 3px;
}

.drafts_table_item_media_list{
  width: max-content;
  display: flex;
  padding-bottom: 5px;
}

.drafts_table_item_media_list > * + *{
  margin-left: 10px;
}
