@import "@sitestyles";

.letters_list_wrap{
  height: 100%;
  overflow-y: auto;
  margin-right: -5px;
  padding-right: 5px;
}

.letters_list_wrap::-webkit-scrollbar{
  width: 2px;
  background: #fff;
  border-radius: 3px;
}

.letters_list_wrap::-webkit-scrollbar-thumb{
  background: #96B6CF;
  border-radius: 3px;
}

.letters_list{
  display: block;
}

.letters_list_item{
  display: flex;
  align-items: stretch;
  margin-bottom: 12px;
}

.letters_list_item:last-child{
  margin-bottom: 0;
}

.letters_list_item_main{
  background: #fff;
  border: 1px solid #E9EEF2;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.letters_list_item_main:hover{
  cursor: pointer;
  background-color: #ffe9bf;
}

.letters_list_item_main.unread{
  background-color: #e3ecfc;
}

.letters_list_item_top{
  display: flex;
  align-items: center;
  padding: 0 18px;
}

.letters_list_item_man_profile{
  width: 200px;
  display: flex;
  //align-items: center;
}

.letters_list_item_man_photo_wrap{
  position: relative;
  margin-right: 15px;
  align-self: flex-start;
}

.letters_list_item_man_photo_wrap:after{
  content: '';
  box-sizing: border-box;
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #B2B2B2;
  border: 2px solid #FFFFFF;
  position: absolute;
  right: 1px;
  bottom: 1px;
}

.letters_list_item_man_photo_wrap.online:after{
  background-color: #27AE60;
}

.letters_list_item_man_photo{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
}

.letters_list_item_man_info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //height: 60px;
}

.letters_list_item_man_last_online{
  font-family: "Roboto", sans-serif;
  color: #27ae60;
  font-size: 12px;
}

.letters_list_item_man_last_online.week{
  color: #FD9A04;
}

.letters_list_item_man_last_online.month{
  color: #EB5757;
}

.letters_list_item_man_name{
  font-weight: bold;
  font-size: 18px;
  color: $color1;

  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.letters_list_item_man_place{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #828282;
}

.letters_list_item_man_place img{
  height: 12px;
  border-radius: 2px;
  margin-right: 5px;
}

.letters_list_item_middle{
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 33px;
  padding-bottom: 7px;
}

.letters_list_item.with_woman .letters_list_item_middle{
  padding-top: 23px;
  padding-bottom: 23px;
}

.letters_list_item_text{
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 150%;
  color: #4F4F4F;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 568px;
  cursor: pointer;
  word-break: break-all;
}

.letter_list_item_text_from_man{
  font-size: 15px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  color: #4F4F4F;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 568px;
  cursor: pointer;
}

.letters_list_item.with_woman .letters_list_item_text{
  margin-bottom: 15px;
  width: 100%;
}

.letters_list_item_status{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.letters_list_item.with_woman .letters_list_item_status{
  margin-left: 0;
  width: 100%;
}

.letters_list_item_status_title{
  font-weight: 600;
  font-size: 10px;
  color: #333333;
  margin-right: 11px;
}

.letters_list_item_status_name{
  font-family: "Roboto", sans-serif;
  width: 73px;
  height: 21px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  margin-right: 10px;
}

.letters_list_item_status_name.unanswered{
  background-color: #EB5757;
  color: #fff;
}

.letters_list_item_status_name.answered{
  background-color: #99E3CD;
  color: #333;
}

.letters_list_item_status_name.delete{
  background-color: #9947ed;
  color: #fff;
  width: 90px;
}

.letters_list_item_status_freeze{
  margin-left: 5px;
}

.letters_list_item_status_freeze img{
  display: block;
}

.letters_list_item_time{
  font-family: "Roboto", sans-serif;
  width: 100%;
  text-align: right;
  color: rgba(51, 51, 51, 0.5);
  font-size: 10px;
  margin-left: auto;
  margin-top: 15px;
}

.letters_list_item.with_woman .letters_list_item_time{
  width: auto;
  margin-top: 0;
}

.letters_list_item_woman_profile{
  width: 229px;
  display: flex;
  //align-items: center;
  justify-content: flex-end;
}

.letters_list_item_woman_photo_wrap{
  position: relative;
  margin-left: 15px;
}

.letters_list_item_woman_photo_wrap:after{
  content: '';
  box-sizing: border-box;
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #B2B2B2;
  border: 2px solid #FFFFFF;
  position: absolute;
  right: 1px;
  bottom: 1px;
}

.letters_list_item_woman_photo_wrap.online:after{
  background-color: #27AE60;
}

.letters_list_item_woman_photo{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
}

.letters_list_item_woman_info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  //height: 60px;
}

.letters_list_item_woman_id{
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #828282;
}

.letters_list_item_woman_name{
  font-weight: bold;
  font-size: 18px;
  color: $color1;
}

.letters_list_item_woman_place{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 12px;
  color: #828282;
}

.letters_list_item_woman_place img{
  height: 12px;
  border-radius: 2px;
  margin-right: 5px;
}

.slick-slider{
  width: 100%;
}

.letters_list_item_bottom{
  padding: 10px 0;
  border-top: 1px solid #E9EEF2;
  overflow: hidden;
}

.letters_list_item_media_slider{
  display: flex;
  align-items: center;
  position: relative;
}

.letters_list_item_media_item{
  margin-right: 4px;
  cursor: pointer;
}

.letters_list_item_media_item > img{
  height: 53px;
  width: auto;
  border-radius: 5px;
  min-width: 32px;
  object-fit: cover;
  display: block;
}

.letters_list_item_media_item.video{
  position: relative;
}

.letters_list_item_media_item.video:after{
  content: '';
  display: block;
  width: 29px;
  height: 29px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 5px rgba(47, 43, 99, 0.55);
  background-image: url(../../../img/v2/letters-media-play.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.letters_list_item_options{
  width: 70px;
  margin-left: -10px;
  padding: 10px 0 10px 10px;
  background: #FCFCFC;
  border: 1px solid #E9EEF2;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0 8px 8px 0;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.letters_list_item_options_item{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.letters_list_item_options_item:last-child{
  margin-bottom: 0;
}

@media screen and (max-width: 1599px){
  .letters_list_item_top{
	padding-top: 15px;
	flex-wrap: wrap;
  }

  .letters_list_item_woman_profile,
  .letters_list_item_man_profile{
	flex: 1;
  }

  .letters_list_item_middle{
	flex: none;
	width: 100%;
	order: 3;
  }

  .letter_list_item_text_from_man{
	margin-bottom: 20px;
  }

  .letters_list_item_status_freeze{
	margin-right: 15px;
  }
}

@media screen and (max-width: 599px){
  .letters_list_item_top{
	padding-left: 8px;
	padding-right: 8px;
  }

  .letters_list_item_man_photo_wrap{
	margin-right: 7px;
  }

  .letters_list_item_man_photo{
	width: 40px;
	height: 40px;
  }

  .letters_list_item_man_last_online,
  .letters_list_item_woman_id{
	font-size: 10px;
  }

  .letters_list_item_man_name,
  .letters_list_item_woman_name{
	font-size: 14px;
  }

  .letters_list_item_man_place,
  .letters_list_item_woman_place{
	font-size: 10px;
  }

  .letters_list_item_woman_photo_wrap{
	margin-left: 7px;
  }

  .letters_list_item_woman_photo{
	width: 40px;
	height: 40px;
  }

  .letters_list_item{
	flex-direction: column;
  }

  .letters_list_item_options{
	width: 100%;
	flex-direction: row;

	padding: 15px 0 5px 0;
	margin: -10px 0 0 0;
	border-radius: 0 0 8px 8px;
  }

  .letters_list_item_options_item{
	margin: 0 7px;
	width: 25px;
	height: 25px;
  }

  .letters_list_item_options_item img{
	width: 18px;
  }

  .letter_list_item_text_from_man,
  .letters_list_item_text{
	width: 100%;
	word-break: break-word;
  }
}
