@import "@sitestyles";

@media screen and (max-width: 1799px) {

	/*.c3_head_right{
		display: none;
	}*/
	.c3_write_msg_change_translate {
		display: none;
	}

	.c3_write_msg_ico_item:nth-child(3),
	.c3_write_msg_ico_item:nth-child(4),
	.c3_write_msg_ico_item:nth-child(5) {
		/*display: none;*/
	}

	.column-3 .c3_head_right {
		display: none;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 62px;
		z-index: 99;
		background: #fff;
		width: 120px;
		flex-wrap: wrap;
		box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
		border-radius: 5px;
		padding: 20px 29px 0;
		justify-content: space-between;
	}

	.c3_head_right.active {
		display: flex;
	}

	.c3_head_right:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-bottom: solid 10px #fff;
		border-left: solid 10px transparent;
		border-right: solid 10px transparent;
		position: absolute;
		left: calc(50% - 9px);
		top: -9px;
	}

	.c3_head_mob_show_btn {
		display: block;
		position: absolute;
		top: 27px;
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
	}

	.c3_head_btn {
		margin-left: 0;
		margin-bottom: 20px;
	}

	.c3_head_btn.favs {
		background-image: url(/img/c3-top-mob-star.svg);
	}

	.c3_head_btn.favs.active {
		background-image: url(/img/c3-top-mob-star-h.svg);
	}

	.c3_head_btn.letter {
		background-image: url(/img/c3-top-mob-letter.svg);
	}

	.c3_head_btn.folder_1 {
		background-image: url(/img/c3-top-mob-f1.svg);
	}

	.c3_head_btn.folder_1.active {
		background-image: url(/img/c3-top-mob-f1-h.svg);
	}

	.c3_head_btn.folder_2 {
		background-image: url(/img/c3-top-mob-f2.svg);
	}

	.c3_head_btn.folder_2.active {
		background-image: url(/img/c3-top-mob-f2-h.svg);
	}

	.c3_head_btn.folder_3 {
		background-image: url(/img/c3-top-mob-f3.svg);
	}

	.c3_head_btn.folder_3.active {
		background-image: url(/img/c3-top-mob-f3-h.svg);
	}

	.c3_head_btn.block {
		background-image: url(/img/c3-top-mob-block.svg);
	}

	.c3_write_msg {
		height: 180px;
	}

	.c3_chat_scroll {
		height: calc(100% - 180px);
	}

	.c3_write_msg_textareas textarea {
		height: 100%;
	}

	.c3_write_msg_textareas {
		height: 100%;
	}

	.c3l_letters_list_write_top .c3_write_msg_textareas {
		height: 100%;
	}
}

@media screen and (max-width: 1749px) {

	.c4_item_text {
		height: auto;
	}

	.c4_item_top {
		-ms-align-items: flex-start;
		align-items: flex-start;
	}

	.c2l_filters_row {
		padding-left: 15px;
	}

	.c2l_id_input input {
		width: 120px;
	}

	.c2l_compose_btn {
		width: 215px;
		font-size: 14px;
	}

	.c4_item_photo_wrap {
		margin-right: 11px;
	}

	.c4_item_photo {
		width: 50px;
		height: 50px;
	}

	.c4_item_name {
		font-size: 14px;
	}

	.c4_item_text {
		margin-top: 5px;
	}

	.message .c4_item_text {
		-webkit-line-clamp: 3;
		max-height: 56px;
	}

	.c2_item_bottom_info {
		display: block;
	}

	.c2_item_bottom_place {
		margin-left: 0;
	}

	.c2_item_bottom_id:after {
		display: none;
	}

	.c2_item_time {
		align-self: center;
	}

	.c2_item_name{
		max-width: 100px;
	}

	.c2_item_status_date{
		bottom: 15px;
	}
}

@media screen and (max-width: 1649px) {

	.column-4.active {
		right: 0;
	}

	.c3_options {
		padding: 0 45px;
	}

	.c2_item_text {
		-webkit-line-clamp: 2;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		height: 38px;
		display: -webkit-box;
		font-weight: normal;
		width: 120px;
	}

	.c2_item_photo {
		/*width: 50px;
		height: 50px;*/
	}

	.c2_item_photo_counter {
		width: 25px;
		height: 25px;
		line-height: 27px;
		font-size: 14px;
	}

	.c2_top_search input {
		width: 100%;
	}

	.c2_top_online_text {
		font-size: 14px;
	}

	.c2_top_tumbler label {
		width: 30px;
		height: 14px;
	}

	.c2_top_tumbler label:before {
		height: 10px;
		width: 10px;
	}

	.c2_top_tumbler input:checked + label:before {
		left: 18px;
	}

	.c4_item_photo_wrap:after {
		width: 10px;
		height: 10px;
		bottom: 0px;
		right: 0px;
	}

	.c3l_letters_list_write_wrap {
		height: 270px;
	}

	.c3l_letters_list_box_wrap {
		height: calc(100% - 295px);
	}

	.c3l_letters_list_write_top.with_files {
		height: calc(100% - 56px);
	}
}

@media screen and (max-width: 1549px) {


	.c3_options_item {
		font-size: 13px;
	}

	.c3_options_item:first-child {
	}

	.c3_options_item.webcam:after {
		right: 11px;
	}

	.c1_menu_hide_wrap {
		display: none;
	}

	.c1_wrap2 {
		height: calc(100vh - 298px);
		height: calc(calc(var(--vh, 1vh) * 100) - 298px);
	}
}

@media screen and (max-width: 1549px) {
	.chat_wrap {
		padding-left: 141px;
	}
}

@media screen and (max-width: 1399px) {
	.message_text {
		padding: 16px;
	}

	.c3_options {
		padding: 0 15px;
	}

	.c3_head {
		padding-left: 20px;
	}

	.c3_head_btn.ring_not {
		margin-right: 40px;
	}

	.c3_options_item img {
		margin-right: 8px;
	}
}

@media screen and (max-width: 1299px) {

	.c3_options_item:first-child {
		width: 90px;
	}
}

@media screen and (max-width: 1199px) {
	html, body {
		width: 100vw;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		overflow: hidden;
		position: relative;
	}

	.left_menu_chat_list img {
		width: 34px;
	}

	.c3_head_btn.ring_not {
		position: fixed;
		right: 15px;
		top: 12px;
		margin-right: 0;
	}

	.chat_wrap {
		padding-left: 0;
		height: calc(100vh - 64px);
		height: calc(calc(var(--vh, 1vh) * 100) - 64px);
	}

	.c2_list_wrap {
		height: calc(100vh - 188px);
		height: calc(calc(var(--vh, 1vh) * 100) - 188px);
	}

	.c1_top_logo {
		display: none;
	}

	.c1_wrap2 {
		height: calc(100vh - 190px);
		height: calc(calc(var(--vh, 1vh) * 100) - 190px);
	}

	.c3_chat_wrap {
		height: calc(100vh - 188px);
		height: calc(calc(var(--vh, 1vh) * 100) - 188px);
	}

	.c2l_letter_filters_wrap2 {
		height: calc(100vh - 480px);
		height: calc(calc(var(--vh, 1vh) * 100) - 480px);
	}

	.c3l_men_list_wrap2 {
		height: calc(100vh - 80px);
		height: calc(calc(var(--vh, 1vh) * 100) - 80px);
	}

	.bookmarked .c2l_search_res_wrap {
		height: calc(100vh - 676px);
		height: calc(calc(var(--vh, 1vh) * 100) - 676px);
	}

	.c2l_search_res_wrap {
		height: calc(100vh - 574px);
		height: calc(calc(var(--vh, 1vh) * 100) - 574px);
	}

	.c3l_letters_list_wrap2 {
		height: calc(100vh - 188px);
		height: calc(calc(var(--vh, 1vh) * 100) - 188px);
	}

	.c3_head_btn.ring_not {
	}

	.c4_top_close {
		display: block;
	}

	.c3_head_btn.ring_not {
		margin: 0;
	}

	.mob_notif_fixed {
		display: block;
	}
}

@media screen and (max-width: 999px) {

	/*.c1_wrap2{
		height: calc(100vh - 92px);
		height: calc(calc(var(--vh, 1vh) * 100) - 92px);
	}*/
	.c1_logo {
		display: none;
	}

	.c2_list_wrap {
		height: calc(100vh - 188px);
		height: calc(calc(var(--vh, 1vh) * 100) - 188px);
	}

	.chat_wrap {
		/*height: calc(100vh - 80px);
		height: calc(calc(var(--vh, 1vh) * 100) - 80px);*/
		padding-left: 0;
	}

	.left_menu_chat_list {
		display: none;
	}

	.left_menu_chat_list svg {
		fill: #fff;
	}

	.column-1 .c1_menu_item span {
		max-width: 150px;
		visibility: visible;
	}

	.column-1 .c1_submenu_item_left {
		padding-left: 10px;
	}

	.column-1 .c1_submenu_item_left span {
		max-width: 100px;
		max-height: 20px;
		overflow: visible;
	}

	.column-1 .c1_menu_more_btn {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.c4_list_wrap_2 {
		padding-right: 4px;
	}

	.c2_item_text {
		width: auto;
	}
}

@media screen and (max-width: 899px) {
	.c2l_letter_filters_wrap2 {
		height: calc(100vh - 400px);
		height: calc(calc(var(--vh, 1vh) * 100) - 400px);
	}

	.c3_options {
		display: none;
	}

	.c3_chat_wrap {
		height: calc(100vh - 152px);
		height: calc(calc(var(--vh, 1vh) * 100) - 152px);
	}

	.left_menu_chat_list {
		margin-left: 20px;
	}

	.left_menu_chat_list svg {
		fill: #fff;
	}

	.mob_head_left {
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: -4px;
		-ms-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.left_menu_chat_list img {
		width: 28px;
		display: block;
	}

	.head_empty {
		width: 91px;
	}

	.c2_list_wrap {
		height: calc(100vh - 108px);
		height: calc(calc(var(--vh, 1vh) * 100) - 108px);
	}

	.left_menu_chat_list {
		display: block;
	}

	.left_menu_chat_list {
		display: block;
	}

	.c2l_search_res_wrap {
		height: calc(100vh - 494px);
		height: calc(calc(var(--vh, 1vh) * 100) - 494px);
	}

	.bookmarked .c2l_search_res_wrap {
		height: calc(100vh - 596px);
		height: calc(calc(var(--vh, 1vh) * 100) - 596px);
	}

	.c3l_letters_list_wrap2 {
		height: calc(100vh - 152px);
		height: calc(calc(var(--vh, 1vh) * 100) - 152px);
	}

	.popup_compose_head {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 789px) {
	.c4_minichat {
		right: 100%;
		z-index: 999;
	}

	.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video {
		justify-content: center;
	}

	.popup_write_letter_head {
		flex-wrap: wrap;
	}

	.popup_write_letter_fromto:first-child {
		margin-right: 0;
		margin-bottom: 10px;
	}

	.popup_write_letter_write_area {
		height: calc(100% - 135px);
	}
}

@media screen and (max-width: 599px) {
	.c3_head {
		height: auto;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 10px 15px;
	}

	.c3_options {
		height: auto;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 10px 15px;
		display: none;
	}

	.c3_write_msg {
		height: auto;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.c3_write_msg_left {
		width: 100%;
		margin-bottom: 20px;
	}

	.c3_textaera_wrap {
		width: calc(100% - 53px);
		-webkit-box-flex: 1;
		-ms-flex: auto;
		flex: auto;
		margin-bottom: 20px;
	}

	.c3_write_msg_middle {
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0;
	}

	.c3_write_msg_textareas textarea {
		height: 50px;
		background-color: #F7F8FD;
	}

	.chat_message_wrap.sticker .message_text img {
		width: 200px;
		height: 200px;
	}

	.c3_head_right {
	}

	.c3_smile_btn {
	}

	.c3_chat_wrap {
		height: calc(100vh - 134px);
		height: calc(calc(var(--vh, 1vh) * 100) - 134px);
	}

	.c3_chat {
		padding: 20px 9px 20px 20px;
	}

	.chat_message_wrap.right .message_content {
		margin-right: 0;
	}

	.message_reply_text {
		width: 90px;
	}

	.message_video {
		width: 150px;
	}

	.message_img {
		width: 100%;
		max-width: 283px;
		height: auto;
	}

	.c4_minichat {
		width: 100vw;
	}

	.c4_minichat .chat_message_wrap.sticker .message_text img {
		width: 150px;
		height: 150px;
	}

	.head_logo img:nth-child(1) {
		width: 40px;
	}

	.head_logo img:nth-child(2) {
		width: 40px;
	}

	.left_menu_chat_list svg {
		width: 24px;
		height: 24px;
	}

	.head_empty {
		width: 74px;
	}

	.c3_head_btn.ring_not img {
		width: 24px;
	}

	.c3_head_photo_wrap {
		padding-top: 0;
	}

	.c3_head_photo > img {
		width: 34px;
		height: 34px;
	}

	.chat_wrap {
		height: calc(100vh - 64px);
		height: calc(calc(var(--vh, 1vh) * 100) - 64px);
	}

	.c3_head_left_revers,
	.c3_head_left {
		width: 50%;
	}

	.c3_chat_scroll {
		height: calc(100% - 167px);
		margin-right: 8px;
	}

	.c3_head_name {
		font-size: 14px;
		margin-bottom: 0;
	}

	.c3_head_info {
		display: block;
	}

	.c3_head_left_revers .c3_head_info {
		text-align: right;
	}

	.c3_head_photo_wrap {
		width: 50px;
		height: 38px;
	}

	.c3_head_photo_bottom {
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.c3_head_photo_bottom img {
		margin-right: 0;
		margin-bottom: 3px;
	}

	.c3_head_place {
		margin: 0;
		font-size: 12px;
	}

	.c3_head_left_revers .c3_head_place {
		margin-right: 0;
		justify-content: flex-end;
	}

	.c3_head_photo:after {
		top: 20px;
		right: 3px;
	}

	.c3_head_photo:hover:after {
		top: 30px;
	}

	.c3l_men_list_item_main_top {
		display: block;
	}

	.c3l_men_list_item_users {
		margin-right: 0;
		margin-bottom: 8px;
	}

	.c3l_men_list_item {
		display: block;
		margin-bottom: 16px;
	}

	.c3l_men_list_item_options_wrap {
		width: 100%;
	}

	.c3l_men_list_item_options {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
	}

	.c3l_men_list_item_main {
		width: 100%;
	}

	.c3l_men_list_item_btn_wrap {
		margin: 5px;
	}

	.c3l_letters_list_wrap2 {
		height: calc(100vh - 128px);
		height: calc(calc(var(--vh, 1vh) * 100) - 128px);
		padding: 12px 16px 0;
	}

	.c3l_letters_list_write_wrap{
		padding: 14px;
		margin: 12px -16px 0;
		height: 197px;
	}

	.c3l_letters_list_box_wrap{
		height: calc(100% - 209px);
	}

	.c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn span {
		display: block;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		background: #fff;
		padding: 5px 10px;
		box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
		border-radius: 5px;
	}

	.c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn img {
		margin-left: 0;
	}

	.c3l_men_list_item_btn {
		border-radius: 5px 5px 0 0;
	}

	.c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn svg {
		margin-left: 2px;
	}

	.c3l_letters_list_write_bottom {
		justify-content: space-between;
		-ms-align-items: center;
		align-items: center;
		margin-top: 16px;
	}

	.c3l_letters_list_write_smile{
		margin-right: 13px;
	}

	.popup_write_letter .c3l_letters_list_write_smile{
		margin-right: 8px;
	}


	.c3l_letters_list_write_attach_btn{
		margin-right: 18px;
	}

	.popup_write_letter .c3l_letters_list_write_attach_btn{
		margin-right: 13px;
	}

	.popup_write_letter .c3_send_btn{
		margin-left: 0;
		width: 70px;
		font-size: 12px;
	}

	.popup_write_letter .c3_send_btn img{
		margin-right: 8px;
	}

	.c3l_letters_list_write_go_to_chat {
		width: 61px;
		white-space: nowrap;
		overflow: hidden;
	}

	.popup_write_letter_write_area .c3l_letters_list_write_go_to_chat {
		width: 49px;
	}

	.popup_write_letter_user{
		width: 200px;
	}

	.c3l_letters_list_write_go_to_chat img{
		margin: 0;
		width: 23px;
		margin-bottom: 4px;
	}

	.popup_write_letter{
		padding: 32px 10px 16px;
	}

	.c3l_letters_list_write_go_to_chat span{
		display: none;
	}

	.c3l_letters_list_write_bottom_right {
		justify-content: center;
	}

	.c3l_letters_list_write_top {
		height: calc(100% - 58px);
	}

	.c3l_letters_list_write_top .c3_write_msg_textareas textarea {
		height: 100%;
	}

	.c3_head_id {
		font-size: 10px;
		margin-right: 10px;
	}

	.c4_minichat .c3_chat_wrap {
		padding-right: 0;
	}

	.mob_notif_fixed {
		top: 10px;
	}

	.c3l_men_list_item_btn_wrap:hover .c3l_bookmark_ico {
		margin-left: 0;
	}

	.c3_write_msg_ico_item.c3_smile {
		margin-left: 13px;
	}

	.chat_message_wrap.right .message_avatar {
		display: none;
	}

	.make-emojis-large {
		font-size: 90px;
	}

	.chat_message_wrap.left .message_content {
		margin-right: 0;
	}

	.c3_head_id {
		font-size: 10px;
	}

	.close_one_popup{
		right: 5px;
		top: 5px;
	}

	.popup_compose_head {
		justify-content: center;
	}

	.popup_compose_head_right {
		margin-top: 16px;
	}

	.popup_bottom_upload_btn {
		left: 0;
		bottom: 0;
		width: 53px;
	}

	.popup_bottom_upload_btn_info{
		margin-top: 16px;
		margin-left: 40px;
	}

	.popup_bottom_upload_btn img{
		margin: 0;
	}

	.popup_bottom_upload_btn span{
		display: none;
	}

	.upload_popup_wrap{
		padding: 0;
	}

	.upload_popup_wrap .close_one_popup{
		top: 5px;
		right: 5px;
	}

	.upload_popup_tabs_content_middle.video,
	.upload_popup_tabs_content_middle.audio{
		margin-right: 0;
	}

	.popup_bottom_send_btn {
		right: 0;
		bottom: 0;
		width: 177px;
	}

	.popup_audio_item_check{
		margin-left: 12px;
	}

	.popup_audio_item_name{
		width: calc(100% - 30px);
		max-width: 150px;
		font-size: 12px;
	}

	.upload_popup_tabs{
		position: relative;
	}

	.react-tabs__tab-panel.react-tabs__tab-panel--selected {
		height: calc(100% - 110px);
	}

	.popup_compose_men_list_wrap2 {
		grid-template-rows: repeat(3, 104px);
	}

	.left_menu_btn span {
		width: 33px;
	}

	.popup_compose_letter {
		padding: 10px;
	}

	.popup_compose_men_list_wrap {
		padding-left: 10px;
		padding-right: 10px;
	}

	.popup_compose_men_list_wrap2 {
		grid-template-columns: repeat(auto-fill, 250px);
	}

	.popup_compose_man_item_label {
		width: 230px;
	}

	.popup_compose_man_item_photo img {
		width: 40px;
		height: 40px;
	}

	.popup_compose_man_item_name {
		font-size: 14px;
	}

	.close_one_popup img {
		width: 10px;
		height: 10px;
		display: block;
	}

	.chat_message_wrap.right .message_text {
		background-color: #B6D1FE;
	}

	.c3_write_msg_textareas_files {
		margin-top: 5px;
		border: none;
		padding: 5px 0;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item{
		padding: 3px 0 3px 3px;
		height: 40px;
	}

	.c3_write_msg_textareas_files .letters_attach_files_item.file_photo img{
		width: 64px;
		height: 40px;
	}

	.c3l_letters_list_write_top.with_files .c3_write_msg_textareas{
		height: calc(100% - 66px);
	}

	.c3_write_msg_textareas_files .letters_attach_files_item.file_photo{
		width: 100px;
	}

	.popup_write_letter_write_area {
		height: calc(100% - 190px);
	}

	.popup_write_letter .c3_write_msg_textareas_files {
		display: block;
	}


	.rhap_progress-section {
		width: calc(100% - 90px);
	}

	.message_audio_wrap {
		width: 200px;
	}

	.chat_message_wrap.right .message_audio_wrap {
		width: 232px;
	}

	.rhap_volume-controls {
		width: 36px;
		right: 0;
	}

	.rhap_volume-bar-area {
		display: none !important;
	}

	.rhap_volume-container:hover .rhap_volume-bar-area {
		display: block;
	}

	.c4_minichat .message_audio_wrap {
		width: 200px;
	}

	.c4_minichat .chat_message_wrap.right .message_audio_wrap {
		width: 232px;
	}
}

@media screen and (max-height: 740px) {
	.popup_compose_men_list_wrap2 {
		grid-template-rows: repeat(3, 104px);
	}
}

@media screen and (max-height: 610px) {
	.popup_compose_men_list_wrap2 {
		grid-template-rows: repeat(2, 104px);
	}
}

@media screen and (max-height: 500px) {
	.popup_compose_men_list_wrap2 {
		grid-template-rows: repeat(1, 104px);
	}
}
