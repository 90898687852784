@import "@sitestyles";

.clmn_2_personal {
  width: 370px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 1599px) {
    width: 300px;
  }

  @media screen and (max-width: 999px) {
    display: none;
    width: 100%;
    height: calc(100% - 60px);
    padding-bottom: 0;
  }
}

.personal_choose_girl {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .05);
  padding-bottom: 5px;
}

.personal_choose_girl_head {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: $color1;
}

.choose_girl_item {
  background-color: #FFFFFF;
  box-shadow: 0 0 10px #CFD7DF;
  border-radius: 8px;
  cursor: pointer;
}

.choose_girl_item__blue {
  background: #87abc6;

  span {
    color: white;
  }
}

:global(.choose_girl) .clmn_2_personal {
  display: flex;
}

.country_block {
  padding-top: 24px;
  box-sizing: border-box;

  &:first-child {
    padding-top: 16px;
  }
}

.country_title {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.01em;
  color: #444547;
}

.country_subtitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  letter-spacing: -0.01em;
  margin-bottom: 5px;
}

.female_title {
  color: #E235D1;
}

.male_title {
  color: #5163FF;
}

.country_content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
}
