.options_list {
  width: 300px;
  margin: 30px;
}

.shift_filter_options {
	margin: 30px;
	width: 200px;
}

.options_selectors_container {
	display: flex;
}
