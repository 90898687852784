 
   
  .letters_list_item_media_item{
    margin-right: 4px;
    cursor: pointer;
  }
  .letters_list_item_media_item > img{
    height: 53px;
    width: auto;
    border-radius: 5px;
    min-width: 32px;
    object-fit: cover;
    display: block;
  }

  .letters_list_item_media_item > video{
    height: 53px;
    width: auto;
    border-radius: 5px;
    min-width: 32px;
    object-fit: cover;
    display: block;
  }
  
  .letters_list_item_media_item.video{
    position: relative;
  }
  
  .letters_list_item_media_item.video:after{
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 5px rgba(47, 43, 99, 0.55);
    background-image: url(../../../img/v2/letters-media-play.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }