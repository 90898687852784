.clmn_sender{
  flex: 1;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sender_body{
  height: calc(100% - 58px);
  display: flex;
}

@media screen and (max-width: 599px){
  .sender_body{
	flex: 1;
	overflow: hidden;
  }
}
