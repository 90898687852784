@import "@sitestyles";

.media_list_wrap_slide {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 0;
  transition: 1s;
  overflow: hidden;
  z-index: 6;
}

.media_list_wrap_slide.open {
  max-height: 800px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}

.media_list_wrap {
  padding: 0 24px 14px 29px;
  background-color: #fff;
}

.media_list_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid #C2CFE0;
  margin-bottom: 15px;
}

.media_list_head_categories {
  display: flex;
  align-items: center;
}

.media_list_head_category_btn {
  font-family: "Roboto", sans-serif;
  height: 28px;
  padding: 0 12px;
  border-radius: 5px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5C5C5C;
  cursor: pointer;
  transition: .3s;
}

.media_list_head_category_btn:last-child {
  margin-right: 0;
}

.media_list_head_category_btn.active {
  background-color: #EFF5FE;
  box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
  font-weight: 500;
  color: #093A83;
  cursor: default;
}

.media_list_head_type {
  display: flex;
  align-items: center;
  border: 1px solid #E0EAF5;
  border-radius: 4px;
  position: relative;
}

.media_list_head_type:before {
  content: '';
  display: block;
  width: 46px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e0eaf5;
  border-radius: 4px;
  transition: .3s;
}

.media_list_head_type.paid:before {
  width: 56px;
  left: 47px;
}

.media_list_head_type_btn {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  transition: .3s;
  position: relative;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.media_list_head_type_btn:first-child {
  width: 46px;
}

.media_list_head_type:not(.paid) .media_list_head_type_btn:first-child {
  font-weight: 500;
  color: #333;
}

.media_list_head_type_btn:last-child {
  width: 56px;
}

.media_list_head_type.paid .media_list_head_type_btn:last-child {
  font-weight: 500;
  color: #333;
}

.media_list_scroll {
  max-height: 326px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
  margin-right: -13px;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
}

.media_list_scroll::-webkit-scrollbar {
  width: 3px;
  background: #F4F4F4;
  border-radius: 3px;
}

.media_list_scroll::-webkit-scrollbar-thumb {
  background: #ADBAFF;
  border-radius: 3px;
}

.media_list {
  display: flex;
  flex-wrap: wrap;
}

.media_list_month {
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  color: $color1;
  margin-bottom: 11px;
  clear: both;
}

.media_list_item + .media_list_month {
  margin-top: 30px;
}

.media_list_item {
  float: left;
  height: 140px;
  min-width: 98px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 40px;
}

.media_list_item video {
  height: 100%;
  border-radius: 8px;
  min-width: 140px;
  object-fit: cover;
  object-position: top;
}

.media_list_item.video > img {
  height: 100%;
  border-radius: 8px 8px 0 0;
  min-width: 100%;
  object-fit: cover;
  object-position: top;
}

.media_list_item.video > img.media_list_item_video_play {
  all: initial;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.media_list_item.audio {
  width: 340px;
  background-color: #EFF5FE;
  border: 1px solid rgba(0, 49, 123, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 18px 18px 18px;
}

.media_list_item_unpaid_wrap + .media_list_item_audio_content {
  //margin-top: auto;
}

.media_list_item_audio_text {
  width: 100%;
  margin-bottom: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $color1;
}

.media_list_item_img {
  height: 100%;
  width: auto;
  border-radius: 8px;
  min-width: 140px;
  object-fit: cover;
  object-position: top;
}

.media_list_item.unpaid .media_list_item_img {
  min-width: 99px;
  object-fit: cover;
  object-position: top;
}

.media_list_item_unpaid_wrap {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 3;
}

.media_list_item_unpaid_main {
  width: 87px;
  height: 26px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.media_list_item_unpaid_main img {
  margin-right: 7px;
}

.media_list_item_unpaid_tip {
  font-family: "Roboto", sans-serif;
  width: 186px;
  height: 67px;
  position: absolute;
  top: calc(50%);
  left: calc(100% + 8px);
  transform: translateY(-50%);
  background: rgba(255, 255, 255, .95);
  border: 1px solid #E9EBEE;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 150%;
  color: rgba(51, 51, 51, 0.8);
  opacity: 0;
  transition: .3s;
  z-index: 2;
  pointer-events: none;
}

.media_list_item_unpaid_tip.left {
  left: unset;
  right: calc(100% + 8px);
}

.media_list_item_unpaid_tip:before {
  content: '';
  display: block;
  width: 13px;
  height: 13px;
  background: linear-gradient(45deg, #E9EBEE 50%, transparent 50%);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 2px 0 2px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip.left:before {
  left: unset;
  right: 0;
  background: linear-gradient(45deg, transparent 50%, #E9EBEE 50%);
  transform: translate(50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background: linear-gradient(45deg, #fff 50%, transparent 50%);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 2px 0 1px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip.left:after {
  left: unset;
  right: 0;
  background: linear-gradient(45deg, transparent 50%, #fff 50%);
  transform: translate(50%, -50%) rotate(45deg);
}

.media_list_item_unpaid_tip span {
  font-weight: bold;
  display: block;
}

.media_list_head_mob_title {
  display: none;
}

.mob_media_paid_info {
  border-top: 1px solid rgba(194, 207, 224, 0.6);
  display: flex;
  align-items: center;
  padding-top: 14px;
  margin-top: 5px;
}

.mob_media_paid_info_img {
  margin-right: 6px;
}

.mob_media_paid_info_title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: $color1;
  margin-right: 4px;
}

.mob_media_paid_info_text {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
}

.mob_media_paid_info_text span:not(.mob_media_paid_info_text_mob) {
  font-weight: bold;
}

.media_list_item_content_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 10px;
  background-color: #F3F5FF;
  border-radius: 0 0 8px 8px;
  margin-top: -4px;
  cursor: context-menu;
}

.media_list_item_name {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: $color1;
}

@media screen and (max-width: 599px){
  .media_list_item{
	margin-right: 0;
  }

  .media_list_item.audio{
	width: 100%;
	padding: 50px 5px 5px;
	height: auto;
  }

  .media_list_item{
	//padding: 50px 5px 5px;
  }

  .media_list_item_audio_text{
	font-size: 12px;
	max-width: 150px;
  }

  .media_list_item.video{
	height: auto;
	width: 100%;
	float: none;
  }

  .media_list_item.video > img{
	width: 100%;
	height: auto;
	max-height: 200px;
	object-fit: contain;
  }
}
