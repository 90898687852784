@import "@sitestyles";

.stats_clmn_3{
  flex: 1;
  overflow: hidden;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stats_page_head{
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stats_page_head_left{
  display: flex;
  align-items: center;
}

.block_page_empty{
  margin: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $color1;
  width: max-content;
  text-align: center;
}

.block_page_empty img{
  margin-bottom: 35px;
}

.stats_page_show_btn{
  height: 38px;
  padding: 0 15px;
  background: $color5;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  margin-left: 20px;
  outline: none;
  cursor: pointer;
}

.back_to_main_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.stats_page_head_total_text{

}

.stats_page_table_wrap{
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.stats_page_table_wrap::-webkit-scrollbar{
  width: 10px;
  height: 7px;
  background: #ddd;
  border-radius: 10px;
}

.stats_page_table_wrap::-webkit-scrollbar-thumb{
  background: #357f89;
  border-radius: 10px;
}

.table{
  border-collapse: collapse;
}

.table .table_head_item{
  height: 50px;
  text-align: center;
  position: sticky;
  top: 1px;
  padding: 10px 15px;
  z-index: 2;
  //white-space: nowrap;
  box-sizing: border-box;
  border-collapse: separate;
  background-color: #1f4f74;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  min-width: 100px;
}

.table .table_head_item span{
  position: relative;
  display: inline-block;
}

.table .table_head_item:not(.active):hover{
  color: #ccc;
}

.table .table_head_item span:after{
  content: '▲';
  display: block;
  opacity: 0;
  position: absolute;
  left: calc(100% + 1px);
  top: calc(50% - 8px);
  transform: rotate(180deg);
}

.table .table_head_item:not(.active):hover span:after{
  transform: rotate(180deg);
}

.table .table_head_item.active span:after{
  opacity: 1;
}

.table .table_head_item.asc span:after{
  transform: rotate(0deg);
}

//.table .table_head_item.desc:hover:after{
//  transform: rotate(0);
//}

.table .table_head_item:hover span:after{
  opacity: 1;
}

.table .table_head_row{
  border-bottom: 1px solid;
  border-collapse: separate;
}

.table .table_head_row:nth-child(even){
  background-color: #eee;
}

.table .table_row{
  outline: none;
  border-bottom: 1px solid #ded;
}

.table .table_row:nth-child(even){
  background-color: #eee;
}

.table .table_col{
  font-family: "Roboto";
  height: 50px;
  text-align: center;
  padding: 10px;
  white-space: nowrap;
}

.table .table_col_total{
  background-color: #57b0ff9e;
}

.table .table_col_null{
	//background-color: #f3f1f1;
}

.table .table_col:first-child{
  color: #999;
  font-weight: normal;
}

.table .table_col:last-child{
  font-weight: 600;
}

.table thead{
  left: 0;
  width: 100%;
  height: 50px;
}

.table:not(.sender_table) tbody tr:last-child td{
  position: sticky;
  bottom: 0;
  background-color: #1f4f74;
  color: #fff;
}

.table:not(.sender_table) tbody tr:last-child td:first-child{
  color: #ffffff;
}

.gifts_table .table_col:last-child{
  min-width: 135px;
}

.gifts_table .table_col:nth-child(4){
  padding: 0 10px;
}

.gifts_table .table_col img{
  display: block;
  margin: auto;
}

//.table_head{
//  display: flex;
//  align-items: center;
//  position: sticky;
//  top: 0;
//  left: 0;
//  padding: 0 15px;
//  text-align: center;
//}

//.table_body{
//  position: relative;
//}
//
//.table_head_item{
//  width: 100px;
//  flex: none;
//}
//
//.table_row{
//  display: flex;
//  align-items: center;
//  height: 70px;
//  padding: 0 15px;
//  text-align: center;
//}

//.table_row:last-child{
  //position: fixed;
  //bottom: 0;
  //left: 0;
  //width: 100%;
  //background-color: #aaa;
//}

//.table_col{
//  width: 100px;
//  flex: none;
//}

.gifts_search_head{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.gifts_search_input_wrap{
  position: relative;
}

.gifts_search_input{
  font-family: "Montserrat",sans-serif;
  display: block;
  width: 194px;
  height: 26px;
  background: #fff;
  border: 1px solid #e9eef2;
  border-radius: 5px;
  padding: 0 30px 0 12px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -.02em;
  color: #333;
  outline: none;
}

.gifts_search_clear_btn{
  width: 26px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url(../../img/cross-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-size: 10px;
}

@media screen  and (max-width: 999px){
  .stats_page_head{
	flex-wrap: wrap;
  }

  .stats_page_head_total_text{
	margin: 15px 0 5px auto;
  }

  .stats_page_head_left{
	flex-wrap: wrap;
  }
}

@media screen  and (max-width: 699px){
  .stats_page_head.sender_stat .stats_page_show_btn{
	margin-left: 0;
  }

  .stats_page_head.sender_stat .stats_page_show_btn + .stats_page_show_btn{
	margin-right: auto;
	margin-left: 20px;
  }
}

@media screen  and (max-width: 599px){
  .stats_page_table_wrap::-webkit-scrollbar{
	width: 5px;
  }

  .stats_page_show_btn{
	margin-left: auto;
	margin-top: 10px;
  }

  .table .table_head_item{
	padding: 5px 15px;
	font-size: 12px;
  }

  .table .table_col{
	font-size: 12px;
	height: 40px;
  }
}
