@import "@sitestyles";

.clmn_1_mm_chat_wrap{
	position: relative;
	margin-bottom: 20px;
	z-index: 9;
}

.clmn_1_mm_chat_wrap.opened{
	width: 241px;
}

.clmn_1_mm_chat_wrap.opened .clmn_1_mm_chat_btn{
	border-radius: 5px 5px 0 0;
}

.clmn_1_mm_chat_wrap.opened .clmn_1_mm_chat_btn_arrow{
	transform: rotate(180deg);
}

.clmn_1_mm_chat_wrap.opened .clmn_1_mm_chat_list_wrap{
	display: block;
}
.clmn_1_mm_chat_btn{
	height: 67px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	position: relative;
	cursor: pointer;
}


.clmn_1_mm_chat_btn_arrow{
	position: absolute;
	right: 6px;
	bottom: 6px;
}
.clmn_1_mm_chat_list_wrap{
	display: none;
	width: 100%;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	z-index: 2;
	border-radius: 0 0 5px 5px;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.clmn_1_mm_chat_search{
	background-color: #ECF5F6;
	padding: 9px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	position: relative;
	margin-bottom: 8px;
}

.clmn_1_mm_chat_search_inp{
	display: block;
	width: 100%;
	height: 100%;
	background-color: #ECF5F6;
	flex: 1px;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: -0.02em;
	color: #333;
	padding-right: 34px;
}

.clmn_1_mm_chat_search_inp::placeholder{
	color: rgba(51, 51, 51, 0.3);
}

.clmn_1_mm_chat_search_tblr{
	/*position: absolute;*/
	/*right: 9px;*/
	/*top: 50%;*/
	/*transform: translateY(-50%);*/
	position: relative;
}

.clmn_1_mm_chat_search_tblr input{
	display: none;
}

.clmn_1_mm_chat_search_label{
	display: block;
	width: 24px;
	height: 12px;
	background-color: #CDD3DA;
	border-radius: 19px;
	position: relative;
	cursor: pointer;
}

.clmn_1_mm_chat_search_label:before{
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	background-color: #fff;
	border-radius: 100%;
	position: absolute;
	left: 1px;
	top: 1px;
	transition: .3s;
}

.clmn_1_mm_chat_search_tblr input:checked + .clmn_1_mm_chat_search_label{
	background-color: #fff;
}

.clmn_1_mm_chat_search_tblr input:checked + .clmn_1_mm_chat_search_label:before{
	background-color: #63B485;
	left: 13px;
}
.clmn_1_mm_chat_list{
	padding-left: 9px;
	padding-right: 5px;
	margin-right: 5px;
	max-height: 280px;
	overflow-y: auto;
}

.clmn_1_mm_chat_list::-webkit-scrollbar {
	width: 3px;
	background: #fff;
	border-radius: 3px;
}

.clmn_1_mm_chat_list::-webkit-scrollbar-thumb {
	background: #96B6CF;
	border-radius: 3px;
}

.clmn_1_mm_chat_list_item{
	cursor: pointer;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	margin-bottom: 8px;
}

.clmn_1_mm_chat_list_item:last-child{
	margin-bottom: 0;
}

.clmn_1_mm_chat_list_item_photo{
	margin-right: 10px;
	position: relative;
}

.clmn_1_mm_chat_list_item_photo:after{
	content: '';
	display: block;
	width: 6px;
	height: 6px;
	border: 1px solid #fff;
	border-radius: 100%;
	background-color: #B2B2B2;
	position: absolute;
	right: 1px;
	bottom: 1px;
}

.clmn_1_mm_chat_list_item_photo.online:after{
	background-color: #27AE60;
}

.clmn_1_mm_chat_list_item_photo img{
	width: 40px;
	height: 40px;
	object-fit: cover;
	display: block;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}

.clmn_1_mm_chat_list_item_middle{
	width: calc(100% - 76px);
}

.clmn_1_mm_chat_list_item_middle_top{
	display: flex;
	align-items: center;
	margin-bottom: 2px;
}

.clmn_1_mm_chat_list_item_name{
	font-weight: 600;
	color: $color3;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clmn_1_mm_chat_list_item_status_green{
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #357F89;
	margin-left: 6px;
}

.clmn_1_mm_chat_list_item_status_yellow{
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #F2C94C;
	margin-left: 4px;
}

.clmn_1_mm_chat_list_item_count{
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #87ABC6;
}

.clmn_1_mm_chat_list_item_count_chats{
	display: flex;
	align-items: center;
	margin-right: 15px;
}

.clmn_1_mm_chat_list_item_count_chats img{
	margin-right: 5px;
}

.clmn_1_mm_chat_list_item_count_letters{
	display: flex;
	align-items: center;
	margin-right: 15px;
}

.clmn_1_mm_chat_list_item_count_letters img{
	margin-right: 5px;
}

.clmn_1_mm_chat_list_item_count_likes{
	display: flex;
	align-items: center;
}

.clmn_1_mm_chat_list_item_count_likes img{
	margin-right: 5px;
}

.clmn_1_mm_chat_list_item_id{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.6);
}

.clmn_1_mm_chat_list_item_right{
	flex: none;
}

.clmn_1_mm_chat_list_item_input{
	display: none;
}

.clmn_1_mm_chat_list_item_label{
	display: block;
	width: 24px;
	height: 16px;
	border: 1px solid #B2B2B2;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
}

.clmn_1_mm_chat_list_item_label:before{
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
	border-radius: 100%;
	background-color: #B2B2B2;
	transition: left .3s;
}

.clmn_1_mm_chat_list_item_input:checked + .clmn_1_mm_chat_list_item_label{
	border-color: #27AE60;
}

.clmn_1_mm_chat_list_item_input:checked + .clmn_1_mm_chat_list_item_label:before{
	background-color: #27AE60;
	left: 10px;
}


.clmn_1_mm_chat_all_online{
	height: 29px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	margin: 8px 9px 0;
	padding-right: 6px;
	border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.clmn_1_mm_chat_all_online_value{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	letter-spacing: -0.02em;
	color: rgba(51, 51, 51, 0.3);
}

.clmn_1_mm_chat_offline_girls{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 8px 15px 0 9px;
	border-top: 1px solid rgba(51, 51, 51, 0.1);
	padding-top: 6px;
	padding-bottom: 8px;
}

.clmn_1_mm_chat_offline_girls_text{
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	letter-spacing: -0.02em;
	color: rgba(51, 51, 51, 0.3);
}

@media screen and (max-width: 999px) {
	.clmn_1_mm_chat_wrap{
		position: static;
	}

	.clmn_1_mm_chat_wrap.opened{
		width: 100%;
	}

	.clmn_1_mm_chat_wrap.opened .clmn_1_mm_chat_list_wrap{
		position: static;
	}

	.clmn_1_mm_chat_btn{
		border-radius: 10px;
		box-shadow: 0 3px 5px rgba(47, 43, 99, .16);
	}
}
