.select_wrap{
  position: relative;
  margin-right: 30px;
  z-index: 4;
}

.select_head{
  width: 200px;
  height: 38px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.select_wrap.open .select_head{
  border-bottom-color: transparent;
  border-radius: 5px 5px 0 0;
}

.select_input_text{
  font-family: "Roboto", sans-serif;
  display: block;
  flex: 1;
  height: 100%;
  padding: 0 10px;
  width: 0;
  outline: none;
  cursor: pointer;
  font-size: 14px;
}

.select_wrap.open .select_input_text{
  cursor: text;
}

.select_arrow,
.clear_input_btn{
  display: block;
  width: 30px;
  height: 30px;
  flex: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: .3s;
}

.select_arrow{
  background-image: url(../../../img/arrow-darkblue.svg);
}
.clear_input_btn{
  background-image: url(../../../img/c2-remove.svg);
  filter: brightness(0.5);
  background-size: 10px;
}

.select_wrap.open .select_arrow{
  transform: rotate(180deg);
}

.select_list_wrap{
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  border: 1px solid #000;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  padding: 0 5px 5px;
  z-index: 2;
}

.select_wrap.open .select_list_wrap{
  display: block;
}

.select_list{
  padding-top: 5px;
  border-top: 1px solid #aaa;
  overflow-y: auto;
  max-height: 400px;
}

.select_list_item input{
  display: none;
}

.select_list_item label{
  display: block;
  padding: 12px 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.select_list_item label:hover{
  background-color: #eee;
}

@media screen  and (max-width: 1399px){
  .select_head{
	width: 150px;
  }

  .select_arrow,
  .clear_input_btn{
	width: 25px;
  }
}

@media screen  and (max-width: 699px){
  .select_wrap{
	width: 100%;
	margin-bottom: 10px;
  }
}

@media screen  and (max-width: 399px){
  .select_wrap{
	margin-right: 0;
  }

  .select_head{
	width: 100%;
  }
}
