.bottom_nav{
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
}

.bottom_nav_item{
  cursor: pointer;
  margin: 0 40px;
}

.bottom_nav_item_ico{
  width: 21px;
  height: 21px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 2px;
}

.bottom_nav_item_ico.chat{
  background-image: url(../../../img/v2/mb-chat.svg);
}
.bottom_nav_item.active .bottom_nav_item_ico.chat{
  background-image: url(../../../img/v2/mb-chat-a.svg);
}

.bottom_nav_item_ico.letters{
  background-image: url(../../../img/v2/mb-letters.svg);
}
.bottom_nav_item.active .bottom_nav_item_ico.letters{
  background-image: url(../../../img/v2/mb-letters-a.svg);
}

.bottom_nav_item_ico.chance{
  background-image: url(../../../img/v2/mb-likeher.svg);
}
.bottom_nav_item.active .bottom_nav_item_ico.chance{
  background-image: url(../../../img/v2/mb-likeher-a.svg);
}

.bottom_nav_item_ico.maybe{
  background-image: url(../../../img/v2/mb-maybe.svg);
}
.bottom_nav_item.active .bottom_nav_item_ico.maybe{
  background-image: url(../../../img/v2/mb-maybe-a.svg);
}

.bottom_nav_item_ico.special{
  background-image: url(../../../img/v2/mb-special.svg);
}
.bottom_nav_item.active .bottom_nav_item_ico.special{
  background-image: url(../../../img/v2/mb-special-a.svg);
}

.bottom_nav_item_ico.menu{
  background-image: url(../../../img/v2/mb-menu.svg);
}
.bottom_nav_item.active .bottom_nav_item_ico.menu{
  background-image: url(../../../img/v2/mb-menu-a.svg);
}

.bottom_nav_item_text{
  font-weight: 600;
  font-size: 10px;
  color: #374E6C;
  color: rgba(51,51,51,.6);
}

.bottom_nav_item.active .bottom_nav_item_text{
  color: #1C4B72;
}

@media screen and (max-width: 999px){
  .bottom_nav{
	display: flex;
  }
}

@media screen and (max-width: 767px){
  .bottom_nav_item{
	margin: 0 10px;
  }
}

@media screen and (max-width: 599px){
  .bottom_nav_item{
	margin: 0;
  }

  .bottom_nav{
	padding: 0 15px;
	justify-content: space-between;
  }
}
