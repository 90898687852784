.clmn_1{
  padding: 0 5px 10px 10px;
  height: 100%;
  width: 201px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.clmn_1_logo{
  display: flex;
  padding: 26px 0;
}

.clmn_1_logo img{
  margin: auto;
  width: 98px;
}

@media screen and (max-width: 1399px){
  .clmn_1{
	position: absolute;
	left: 0;
	top: 0;
	width: 130px;
	transition: width .5s, box-shadow .2s;
	z-index: 5;
	background-color: #EEF1F5;
  }

  .clmn_1:hover{
	width: 201px;
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
  }

  .clmn_1_logo img{
	width: 80px;
  }
}

@media screen and (max-width: 1299px){
  .clmn_1_logo{
	display: none;
  }

  .clmn_1{
	padding-top: 10px;
  }
}

@media screen and (max-width: 999px){
  .clmn_1{
	display: none;
  }
}
