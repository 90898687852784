@import "@sitestyles";

.clmn_3 {
	flex: 1px;
	padding: 10px 5px;
}

.clmn_3_chat {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
}

.notification {
	position: absolute;
	top: 122px;
	left: 30px;
	background: #710909;
	padding: 20px 10px 10px;
	box-sizing: border-box;
	max-width: 300px;
	width: 100%;
	border-radius: 15px;
	color: white;
	text-align: center;
	animation: love 0.5s infinite;

	@keyframes love {
		from {background-color: red;}
		to {background-color: blue;}
	}

	span:first-child{
		font-weight: 600;
		display: block;
		margin-bottom: 20px;
	}
}

.notification_close{
	position: absolute;
	font-weight: 700;
	top: 10px;
	right: 10px;
	cursor: pointer;
}
