@import "@sitestyles";

.page_wrap{
	display: flex;
	flex-direction: column;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
}

.wchat_wrap{
	flex: 1;
	background-color: #EEF1F5;
	display: flex;
	position: relative;
	overflow: hidden;
}

@media screen and (max-width: 1399px){
	.wchat_wrap{
		padding-left: 130px;
	}
}

@media screen and (max-width: 999px){
	.wchat_wrap{
		padding-left: 0;
	}
}
