@import "@sitestyles";

.cl2h_tab_wide_filter_item_wrap{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.cl2h_tab_wide_filter_item{
	position: relative;
	width: 100%;
}

.main_registration_form_dob_row .cl2h_tab_wide_filter_item{
	width: 120px;
}

.main_registration_form_dob_row .cl2h_tab_wide_filter_item:nth-child(2){
	width: 144px;
}

.like_select{
	opacity: 0;
	position: fixed;
	left: -999999px;
	top: -99999px;
}

.like_select_head{
	height: 42px;
	line-height: 42px;
	font-size: 16px;
	background-color: #F4F4F4;
	border-radius: 4px;
	padding: 0 30px 0 22px;
	color: rgba(51, 51, 51, 0.6);
	font-weight: 500;
	position: relative;
	z-index: 2;
	cursor: pointer;
}

.like_select_head:after{
	content: '';
	display: block;
	width: 10px;
	height: 6px;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	background-image: url(/img/selecet-arrow.svg);
}

.open .like_select_head:after{
	transform: rotate(180deg);
	transition: .3s;
}

.like_select_head.selected{
	color: #00317B;
	font-weight: 500;
	background-color: #EFF5FE;
}

.cl2h_tab_wide_filter_item:not(.open) .like_select_head:hover{
	box-shadow: 0px 3px 3px rgba(28, 38, 119, 0.13);
}

.cl2h_tab_wide_filter_item.open{
	border-radius: 4px 4px 0 0;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	z-index: 4;
}

.like_select_list{
	display: none;
	background-color: #F4F4F4;
	border-radius: 0 0 4px 4px;
	margin-top: -10px;
	padding: 20px 0 10px;
	position: relative;
	z-index: 3;
	max-height: 360px;
	overflow: auto;
}
.like_select_list.open {
	display: block;
}

.like_select_head.selected + .like_select_list{
	background-color: #EFF5FE;
}

.like_select_list::-webkit-scrollbar{
	width: 3px;
	background: #fff;
	border-radius: 22px;
}

.like_select_list::-webkit-scrollbar-thumb {
  background: #ADBAFF;
  border-radius: 9px;
}

.like_select_label{
	display: block;
	height: 40px;
	line-height: 40px;
	padding: 0 12px;
	color: rgba(51, 51, 51, 0.9);
	font-weight: 500;
	margin: 0 10px;
	border-radius: 4px;
	cursor: pointer;
}

.cl2h_tab_wide_filter_row2_big .like_select_label{
	font-size: 18px;
	height: 60px;
	line-height: 60px;
}

.like_select:checked + .like_select_label{
	background-color: #E3ECFC;
}

.like_select_label:hover{
	background-color: #E3ECFC;
}
