.post_wrap {
  width: 280px;
}

.post_header {
  background: #f3f4fc;
  display: flex;
  align-items: center;
  gap: 8px;
}

.post_avatar_wrap {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #EEF1FE;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 820px) {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 3px;
    right: 1px;
    border: 1px solid #1F4F74;
    background-color: #B2B2B2;
    border-radius: 100%;
  }

  &.online:after {
    background-color: #27AE60;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    border-radius: 50%;
  }

  div {
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
  }
}

.post_profile_name {
  overflow: hidden;
  color: #2F2D48;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.post_image_wrap {
  position: relative;
  cursor: pointer;
  margin: 8px 0;
  width: 280px;
  height: 280px;

  &.carousel_icon:after {
    content: '';
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: url('../../../../../../../img/posts/icon_carousel.svg') center no-repeat;
    background-size: contain;
    position: absolute;
    top: 16px;
    right: 16px;

    @media screen and (max-width: 650px) {
      width: 20px;
      height: 20px;
      min-width: 20px;
      top: 8px;
      right: 8px;
    }
  }

  &.video_icon:after {
    content: '';
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: url('../../../../../../../img/posts/icon_video_post_preview.svg') center no-repeat;
    background-size: contain;
    position: absolute;
    top: 16px;
    right: 16px;

    @media screen and (max-width: 650px) {
      width: 20px;
      height: 20px;
      min-width: 20px;
      top: 8px;
      right: 8px;
    }
  }

  &.post_deleted {
    cursor: default;

    &:after {
      content: '';
      display: block;
      width: 120px;
      min-width: 120px;
      height: 120px;
      background: url('../../../../../../../img/posts/deleted_post_icon.svg') center no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 789px) {
        width: 80px;
        height: 80px;
        min-width: 80px;
      }
    }
  }

}

.post_image {
  border-radius: 4px;
}

.post_text {
  overflow: hidden;
  color: #7E7D8B;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
