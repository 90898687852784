.title_wrap{
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_wrap_2{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title{
  font-weight: bold;
  color: #535973;
}

.title_2{
  font-weight: 600;
  color: #535973;
}