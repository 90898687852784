@import "@sitestyles";

.block_page_wrap{
  display: flex;
  flex: 1;
  padding: 10px 5px;
}

.block_page_content{
  background-color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.block_page_content_head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block_page_content_head_left{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block_page_content_head_tab_btn{
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #1C4B72;
  cursor: pointer;
  color: #1C4B72;
  font-weight: 600;
  transition: .3s;
}

.block_page_content_head_tab_btn.active{
  background: $color5;
  cursor: default;
  color: #fff;
  border-color: transparent;
}

.block_page_content_head_tab_btn:hover{
  box-shadow: 0 4px 8px rgba(14, 4, 143, 0.24);
}

.block_page_content_head_tab_btn.active:hover{
  box-shadow: none;
}

.block_page_content_head_tab_btn + .block_page_content_head_tab_btn{
  margin-left: 15px;
}

.block_page_content_head_show_btn{
  padding: 0 15px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color5;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #fff;
  cursor: pointer;
  transition: .3s;
}

.block_page_content_head_show_btn:hover{
  box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
}

.clmn_2_chat_top_filters_search{
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}

.block_page_search_input{
  font-family: "Montserrat", sans-serif;
  display: block;
  width: 174px;
  height: 26px;
  background: #fff;
  border: 1px solid #357F89;
  border-radius: 5px;
  padding: 0 12px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: #333;
  outline: none
}

.block_page_search_input:focus{
  box-shadow: 0 4px 8px rgba(14, 4, 143, 0.24);
}

.clmn_2_chat_top_filters_search button{
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 1px;
  background-image: url(../../img/v2/c2-chat-search.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
}

.block_page_empty{
  margin: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $color1;
}

.block_page_empty img{
  margin-bottom: 35px;
}

@media screen and (max-width: 599px){
  .block_page_content_head_tab_btn,
  .block_page_content_head_show_btn{
	padding: 0 10px;
	height: 32px;
	font-size: 12px;
  }

  .clmn_2_chat_top_filters_search{
	margin-right: 10px;
  }

  .block_page_search_input{
	width: 100px;
  }

  .block_page_content_head_tab_btn + .block_page_content_head_tab_btn{
	margin-left: 5px;
  }
}
