@import "@sitestyles";

.message_gift {
  position: relative;
  border-radius: 10px;
  background-color: rgba(210, 223, 245, .9);
  width: 100%;
  max-width: 100%;
  min-height: 180px;
  padding: 32px 48px;
  margin-top: 120px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  &.recipient {
	align-items: flex-end;
	background-color: #f3f4fc;
  }

  @media screen and (max-width: 1599px) {
	padding: 16px 20px;
	min-height: 150px;
	margin-top: 80px;
  }

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
	margin-top: 100px;
	padding: 90px 24px 24px;
	height: fit-content;
	align-items: center;

	&.recipient {
	  align-items: center;
	}
  }

  @media screen and (max-width: 820px) {
	margin-top: 60px;
	padding: 80px 20px 20px;
	height: fit-content;
	min-height: unset;
	align-items: center;

	&.recipient {
	  align-items: center;
	}
  }
}

.message_gift_user_name {
  max-width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  margin-right: 6px;

  @media screen and (max-width: 1599px) {
	max-width: 200px;
  }

  @media screen and (max-width: 365px) {
	max-width: 160px;
  }
}

.message_gift_content {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1349px) {
	align-items: center;
  }

  @media screen and (max-width: 1139px) {
	align-items: baseline;
  }

  @media screen and (max-width: 820px) {
	align-items: center;
  }
}

.message_gift:not(.recipient) .message_gift_content {
  padding-right: 300px;

  @media screen and (max-width: 1359px) {
	padding-right: 0;
  }

  @media screen and (max-width: 1140px) {
	padding-right: 240px;
  }
  @media screen and (max-width: 820px) {
	padding-right: 0;
  }
}

.message_gift.recipient .message_gift_content {
  padding-left: 300px;

  @media screen and (max-width: 1359px) {
	padding-left: 0;
  }

  @media screen and (max-width: 1140px) {
	padding-left: 240px;
  }

  @media screen and (max-width: 820px) {
	padding-left: 0;
  }
}


.message_gift_name {
  color: $color1;
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
	font-size: 30px;
	margin-bottom: 10px;
  }

  @media screen and (max-width: 820px) {
	font-size: 20px;
	margin-bottom: 12px;
  }

  &:before {
	content: '';
	display: block;
	width: 48px;
	min-width: 48px;
	height: 48px;
	background: url('../../../../../../img/icon_virtual_gift.svg') center no-repeat;
	background-size: contain;
	margin-right: 16px;

	@media screen and (max-width: 820px) {
	  width: 32px;
	  min-width: 32px;
	  height: 32px;
	  margin-right: 12px;
	}
  }
}

.message_gift_text {
  margin: 0 0 0 46px;
  font-size: 16px;
  color: #474F5C;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 820px) {
	font-size: 14px;
	margin-left: 38px;
  }
}

.message_gift_image {
  position: absolute;
  right: 10px;
  bottom: -10px;
  width: 300px;
  height: auto;
  max-width: 300px;

  &.recipient {
	right: unset;
	left: 10px;
  }

  @media screen and (max-width: 1599px) {
	width: 200px;
	max-width: 200px;
	bottom: 30px;

	right: 0;

	&.recipient {
	  right: unset;
	  left: 0;
	}
  }

  @media screen and (max-width: 1359px) and (min-width: 1140px) {
	width: 240px;
	max-width: 240px;
	bottom: unset;
	right: 50%;
	transform: translateX(50%);
	top: -110px;

	&.recipient {
	  right: 50%;
	  left: unset;
	}
  }

  @media screen and (max-width: 820px) {
	width: 180px;
	max-width: 180px;
	bottom: unset;
	right: 50%;
	transform: translateX(50%);
	top: -80px;

	&.recipient {
	  right: 50%;
	  left: unset;
	}
  }
}

.message_gift_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.message_gift_image_wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #EEF1FE;
  z-index: 1;
  overflow: hidden;

  @media screen and (max-width: 820px) {
	width: 32px;
	min-width: 32px;
	height: 32px;
  }

  img {
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
	object-position: top center;
	border-radius: 50%;
  }

  div {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 16px;
  }

  &:nth-child(2) {
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 0;

	@media screen and (max-width: 820px) {
	  left: 22px;
	}
  }
}

.message_bottom {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -20px;
  right: 2px;

  &.recipient {
	right: unset;
	left: 2px;
  }
}

.message_date {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #BDBDBD;
}

.message_status {
  width: 19px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
}

.message_status.send {
  margin-left: 10px;
  background-image: url(../../../../../../img/send.svg);
}

.message_status.read {
  margin-left: 10px;
  background-image: url(../../../../../../img/readed.svg);
}
