@import "@sitestyles";

.clmn_2_chat_block_item {
	background-color: #fff;
	border-radius: 5px;
	border: 1px solid rgba(31, 79, 116, 0.1);
	/*padding-left: 5px;*/
	display: flex;
	align-items: center;
	margin-bottom: 6px;
	margin-right: 6px;
	position: relative;
	cursor: pointer;
}

.clmn_2_chat_block_item.in_active{
	background-color: $color6;
	border-color: rgba(113,165,172,.6);
}

.clmn_2_chat_block_item.active {
	border-color: transparent;
	background: $color4;
}

.clmn_2_chat_block_item:last-child {
	margin-bottom: 0;
}

.clmn_2_chat_block_item.unanswered {
	position: relative;
}

.clmn_2_chat_block_item.unanswered:before {
	content: '';
	display: block;
	width: 12px;
	height: calc(100% + 2px);
	position: absolute;
	top: -1px;
	right: -7px;
	background-color: #EB5757;
	border-radius: 0 5px 5px 0;
	z-index: -1;
}

.clmn_2_chat_block_item_delete{
	width: 10px;
	height: 10px;
	position: absolute;
	left: 25px;
	top: 4px;
	background-image: url(../../../../../img/chat_c2_delete.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	cursor: pointer;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_delete{
	background-image: url(../../../../../img/chat_c2_delete_a.svg);
}

.item_left_left{
	width: 22px;
	margin-right: 5px;
	display: flex;
	flex-direction: column;
	align-self: stretch;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0 20px 0;
	border-right: 1px solid rgba(113,165,172,.6);
}

.clmn_2_chat_block_item.active .item_left_left{
	border-color: #fff;
}

.item_left_left svg{
	width: 12px;
	/*height: 10px;*/
	fill: #444;
}

.clmn_2_chat_block_item.active .item_left_left svg{
	fill: #fff;
}

.item_left_left svg + svg{
	margin-top: 5px;
}

.clmn_2_chat_block_item_left {
	position: relative;
	margin: 10px 0;
	cursor: pointer;
}

.clmn_2_chat_block_item_left:after {
	content: '';
	display: block;
	width: 7px;
	height: 7px;
	position: absolute;
	bottom: 3px;
	right: 3px;
	border: 1px solid #fff;
	background-color: #B2B2B2;
	border-radius: 100%;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_left:after {
	border-color: #6E9DA9;
}

.clmn_2_chat_block_item_left.online:after {
	background-color: #27AE60;
}

.clmn_2_chat_block_item_left_photo {
	width: 56px;
	height: 56px;
	object-fit: cover;
	display: block;
	border-radius: 100%;
}

.clmn_2_chat_block_item_left_photo_fav {
	width: 18px;
	position: absolute;
	top: -4px;
	right: -1px;
}

.clmn_2_chat_block_item_middle {
	flex: 1px;
	margin: 10px 8px;
	cursor: pointer;
}

.clmn_2_chat_block_item_middle_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2px;
}


.clmn_2_chat_block_item_middle_name {
	font-weight: bold;
	font-size: 13px;
	color: $color3;
	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_middle_name {
	color: #fff;
}


.clmn_2_chat_block_item_middle_time {
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.8);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_middle_time {
	color: rgba(255, 255, 255, 0.8);
}



.clmn_2_chat_block_item_middle_text {
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	line-height: 140%;
	color: rgba(51, 51, 51, 0.7);
	max-height: 32px;
	overflow: hidden;
	cursor: pointer;
	-webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-weight: 400;
    width: 145px;
}
.clmn_2_chat_block_item_middle_text.from_man{
	color: rgb(158, 0, 3);
    font-weight: 900;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_middle_text {
	color: #fff;
}
/*.clmn_2_chat_block_item.active .clmn_2_chat_block_item_middle_text.from_man {*/
/*	color: rgb(158, 0, 3);*/
/*	font-weight: 900;*/
/*}*/

.clmn_2_chat_block_item_right {
	width: 56px;
	height: 76px;
	position: relative;
	-ms-align-self: stretch;
	align-self: stretch;
	margin-right: 10px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.clmn_2_chat_block_item_right:hover {
	margin-right: 0;
}

.clmn_2_chat_block_item_right:hover .clmn_2_chat_block_item_left_photo_fav{
	display: none;
}

.clmn_2_chat_block_item_right:hover .clmn_2_chat_block_item_profile_photo_wrap {
	margin-right: 12px;
}

.clmn_2_chat_block_item_right:hover .clmn_2_chat_block_item_profile {
	height: calc(100% + 2px);
	position: absolute;
	right: -1px;
	top: 50%;
	transform: translateY(-50%);
	width: max-content;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	background-color: #ECF5F6;
	border: 1px solid rgba(31, 79, 116, 0.1);
	border-radius: 5px;
	z-index: 2;
	padding: 10px;
	width: auto;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_right:hover .clmn_2_chat_block_item_profile_photo_wrap:after {
	border-color: #ECF5F6;
}

.clmn_2_chat_block_item_right:hover .clmn_2_chat_block_item_profile_inner {
	max-width: 150px;
	transition: .5s;
}
.clmn_2_chat_block_item_profile{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	width: 56px;
}


.clmn_2_chat_block_item_profile_photo_wrap{
	position: relative;
}


.clmn_2_chat_block_item_profile_photo_wrap:after{
	content: '';
	display: block;
	width: 7px;
	height: 7px;
	position: absolute;
	bottom: 3px;
	right: 3px;
	border: 1px solid #ECF5F6;
	background-color: #B2B2B2;
	border-radius: 100%;
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_profile_photo_wrap:after{
	border-color: #62839D;
}



.clmn_2_chat_block_item_profile_photo_wrap.online:after{
	background-color: #27AE60;
}
.clmn_2_chat_block_item_profile_photo{
	width: 56px;
	height: 56px;
	border-radius: 100%;
	object-fit: cover;
	display: block;
}.clmn_2_chat_block_item_profile_inner{
	 display: -webkit-inline-flex;
	 display: -moz-inline-flex;
	 display: -ms-inline-flex;
	 display: -o-inline-flex;
	 display: inline-flex;
	 max-width: 0;
	 overflow: hidden;
	 transition: 1s linear;
 }
.clmn_2_chat_block_item_profile_photo_fav{
	width: 18px;
	position: absolute;
	top: -4px;
	right: -1px;
}
.clmn_2_chat_block_item_profile_name{
	font-weight: 600;
	letter-spacing: 0.01em;
	color: $color3;
	white-space: nowrap;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clmn_2_chat_block_item_profile_id{
	font-family: "Roboto", sans-serif;
	font-size: 11px;
	color: rgba(51, 51, 51, 0.5);
	margin: 2px 0;
	white-space: nowrap;
}
.clmn_2_chat_block_item_profile_place{
	font-size: 10px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.5);
}

.clmn_2_chat_block_item_profile_place img{
	height: 9px;
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 4px;
}.clmn_2_chat_block_item_profile_right{
	 margin-left: 20px;
	 display: -webkit-flex;
	 display: -moz-flex;
	 display: -ms-flex;
	 display: -o-flex;
	 display: flex;
	 -webkit-flex-direction: column;
	 -moz-flex-direction: column;
	 -ms-flex-direction: column;
	 -o-flex-direction: column;
	 flex-direction: column;
	 justify-content: space-around;
	 -ms-align-items: center;
	 align-items: center;
	 -ms-align-self: stretch;
	 align-self: stretch;
 }

.clmn_2_chat_block_item_profile_switch_btn{
	cursor: pointer;
}

.clmn_2_chat_block_item_profile_switch_btn img{
	display: block;
}
.clmn_2_chat_block_item_profile_prof_btn{
	cursor: pointer;
}

.clmn_2_chat_block_item_profile_prof_btn img{
	display: block;
}




.clmn_2_chat_block_item_middle_last_online{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: #27AE60;
}

.clmn_2_chat_block_item_middle_last_online.week{
	color: #E99C2A;
}

.clmn_2_chat_block_item_middle_last_online.month{
	color: #EB5757;
}

.clmn_2_chat_block_item_pinned{
	width: 10px;
	height: 10px;
	position: absolute;
	left: 6px;
	bottom: 6px;
	background-image: url(../../../../../img/v2/c2-chat-pinned-grey.svg);
}

.clmn_2_chat_block_item.active .clmn_2_chat_block_item_pinned{
	background-image: url(../../../../../img/v2/c2-chat-pinned-white.svg);
}

@media screen and (max-width: 999px){
	.clmn_2_chat_block_item_profile_photo_wrap{
		margin-right: 12px;
	}
	.clmn_2_chat_block_item_right{
		margin-right: 0;
	}

	.clmn_2_chat_block_item_profile_inner{
		width: 150px;
		max-width: 150px;
		overflow: visible;
		height: 100%;
		justify-content: space-between;
	}

	.clmn_2_chat_block_item_profile{
		height: calc(100% + 2px);
		position: absolute;
		right: -1px;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		background-color: #ECF5F6;
		border: 1px solid rgba(31, 79, 116, 0.1);
		border-radius: 5px;
		z-index: 2;
		padding: 10px 5px;
		width: auto;
	}

	.clmn_2_chat_block_item_middle_time{
		margin-right: auto;
		margin-left: 5px;
	}
}

@media screen and (max-width: 599px){
	.clmn_2_chat_block_item_profile_inner{
		width: auto;
	}

	.clmn_2_chat_block_item_profile_middle{
		display: none;
	}

	.clmn_2_chat_block_item_profile_right{
		margin-left: 5px;
	}

	.clmn_2_chat_block_item_profile_photo_wrap{
		margin-right: 0;
	}
}
