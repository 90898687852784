@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import "@sitestyles";

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	font-family: 'Montserrat', sans-serif;
	/*font-family: 'Roboto', sans-serif;*/
	font-size: 14px;
	background-color: #fff;
	overflow: hidden;
}

button,
input {
	font-family: 'Montserrat', sans-serif;
	border: none;
	background-color: transparent;
}

a {
	color: inherit;
	text-decoration: none;
}


.overlay {
	display: none;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	background-color: rgba(235, 237, 250, .5);
	z-index: 6;
	position: absolute;
	left: 0;
	backdrop-filter: blur(4px);
	top: 0;
}

.overlay.dark {
	background-color: rgba(0, 0, 0, .5);
}

.overlay.active {
	display: block;
}


.column-1 {
	width: 314px;
	position: relative;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	-webkit-box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
}

.column-1.small {
	width: 141px;
}

.column-2 {
	width: 352px;
	background-color: #EBEDFA;
	min-height: 100%;
}

.column-2.letters {
	width: 290px;
}

.column-3 {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #fff;
	position: relative;
}
@import "@sitestyles";


.column-3.profile_man {
	background-color: #f4f4f4;
}

.column-3.empty {
	background-color: #FDFCF7;
}

.column-3.profile_flex{
	display: flex;
	flex-direction: column;
}

.column-4 {
	width: 352px;
	background-color: #EBEDFA;
	position: relative;
}

.column-3.empty:after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	background-image: url(/img/c3_empty.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	left: 0;
	top: 0;
	opacity: .56;
}

.ReactModal__Content.ReactModal__Content--after-open {
	max-width: calc(100% - 30px)!important;
	max-height: calc(100% - 30px)!important;
	border-radius: 0!important;
}

@media screen and (min-width: 1200px){
	.column_4_overlay{
		display: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: rgba(255,255,255,.5);
		pointer-events: none;
	}

	.c4_list_wrap_2:hover ~ .column_4_overlay{
		display: block;
	}

	.overlay.active ~ .chat_wrap .column_4_overlay{
		display: none !important;
	}
}

@media screen and (max-width: 1749px) {
	.column-4 {
		width: 260px;
	}

	.column-2 {
		width: 270px;
	}

	.column-2.letters {
		width: 237px;
	}

}

@media screen and (max-width: 1549px) {
	.column-1 {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 3;
		background-color: #fff;
	}
}

@media screen and (max-width: 1199px) {
	.column-4 {
		position: absolute;
		right: -260px;
		-webkit-transition: .3s;
		-o-transition: .3s;
		transition: .3s;
		z-index: 10;
		top: 0;
	}

	.column-4.active {
		right: 0;
	}

	.column-1 {
		opacity: 0;
		background-color: #fff;
		position: absolute;
		top: 0;
		left: -314px;
		z-index: 10;
		-webkit-transition: .5s;
		-o-transition: .5s;
		transition: .5s;
	}

	.column-1.active {
		top: 0;
		left: 0;
		opacity: 1;
	}

	.column-1.active .c1_close {
		display: block;
		visibility: visible;
	}

	.mob_head {
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.mob_head_left {
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.column-4.letters {
		position: absolute;
		right: -352px;
		-webkit-transition: .5s;
		-o-transition: .5s;
		transition: .5s;
		z-index: 10;
		top: 0;
	}

	.column-4.active {
		right: 0;
	}

}

@media screen and (max-width: 899px) {

	.column-2 {
		width: 352px;
		position: absolute;
		left: -352px;
		top: 0;
		z-index: 10;
		-webkit-transition: left .5s;
		-o-transition: left .5s;
		transition: left .5s;
	}

	.column-2.active {
		left: 0;
	}

	.column-2.active .c2_close {
		display: block;
		visibility: visible;
	}

}

@media screen and (max-width: 599px) {

	.column-3 {
		width: 100%;
	}

	.column-2 {
		width: 270px;
		left: -270px;
	}

	.column-1{
		width: 270px;
	}

	.column-3.empty:after{
		-webkit-background-size: 100%;
		background-size: 100%;
	}
}
