@import "@sitestyles";

.clmn_2_chat_loadmore_btn {
	background: $color4;
	height: 44px;
	display: flex;
	align-items: center;
	padding: 0 10px 0 24px;
	border-radius: 4px;
	cursor: pointer;
	color: #fff;
	font-size: 14px;
}

.clmn_2_chat_loadmore_btn span {
	margin-left: 10px;
}

.clmn_2_chat_loadmore_btn img {
	width: 15px;
	height: 15px;
}

.clmn_2_chat_block{
	background-color: #fff;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding-bottom: 6px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.clmn_2_chat_block.favorites{
	margin-bottom: 12px;
}

.clmn_2_chat_block.dialogues{
	flex: 1px;
}

.clmn_2_chat_block_head{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 11px;
	flex: none;
}

.clmn_2_chat_block_title{
	line-height: 34px;
	font-weight: bold;
	color: #535973;
	margin-bottom: 6px;
}

.clmn_2_chat_block_sorting{
	display: flex;
	align-items: center;
}

.clmn_2_chat_block_sorting_item{
	width: 22px;
	height: 22px;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 100%;
	cursor: pointer;
}

.clmn_2_chat_block_sorting_item.active{
	background-color: #87abc6;
}

.clmn_2_chat_block_sorting_item.up{
	background-image: url(../../../../img/sort-arrow-up.svg);
	margin-right: 5px;
}

.clmn_2_chat_block_sorting_item.down{
	background-image: url(../../../../img/sort-arrow-down.svg);
}

.clmn_2_chat_block_sorting_item.up.active{
	background-image: url(../../../../img/sort-arrow-up-white.svg);
}

.clmn_2_chat_block_sorting_item.down.active{
	background-image: url(../../../../img/sort-arrow-down-white.svg);
}

.clmn_2_chat_block_list{
	padding-left: 6px;
	padding-right: 3px;
	margin-right: 3px;
	overflow-y: auto;
	position: relative;
	z-index: 1;
	flex: 1;
}

.clmn_2_chat_block_list::-webkit-scrollbar {
	width: 3px;
	background: #fff;
	border-radius: 3px;
}

.clmn_2_chat_block_list::-webkit-scrollbar-thumb {
	background: #96B6CF;
	border-radius: 3px;
}

.clmn_2_chat_block.favorites .clmn_2_chat_block_list{
	max-height: 500px;
}

.clmn_2_chat_top_filters{
	height: 34px;
	background-color: #fff;
	display: flex;
	align-items: center;
	flex: none;
}

.clmn_2_chat_top_filters_type {
	display: flex;
	justify-content: space-evenly;
	margin-top: 10px;
}

.clmn_2_chat_top_filters_container {
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
	background-color: #fff;
	padding: 10px 10px;
	margin-bottom: 10px;
	border-radius: 4px;
}

.clmn_2_chat_top_filters_freeze_cube{
	cursor: pointer;
	margin-right: 16px;
}

.clmn_2_chat_top_filters_freeze_cube img{
	display: block;
}

.clmn_2_chat_top_filters_is_online input{
	display: none;
}

.clmn_2_chat_top_filters_is_online label{
	display: block;
	width: 35px;
	height: 16px;
	position: relative;
	background-color: #DADADA;
	border-radius: 19px;
	cursor: pointer;
}

.clmn_2_chat_top_filters_is_online input:checked + label{
	background-color: #ECF5F6;
}

.clmn_2_chat_top_filters_is_online label:after{
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background-color: #fff;
	position: absolute;
	left: 2px;
	top: 2px;
	transition: .3s;
}

.clmn_2_chat_top_filters_is_online input:checked + label:after{
	left: 21px;
	background-color: #27AE60;
}

.clmn_2_chat_top_filters_search{
	position: relative;
	margin-left: auto;
}

.clmn_2_chat_top_filters_search input{
	font-family: "Montserrat", sans-serif;
	display: block;
	width: 174px;
	height: 26px;
	background: #fff;
	border: 1px solid #E9EEF2;
	border-radius: 5px;
	padding: 0 38px 0 12px;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: -0.02em;
	color: #333;
	outline: none;
}

.clmn_2_chat_top_filters_search input::placeholder{
	color: rgba(51, 51, 51, 0.4);
}

.clmn_2_chat_top_filters_search button{
	width: 24px;
	height: 24px;
	position: absolute;
	right: 0;
	top: 1px;
	background-image: url(../../../../img/v2/c2-chat-search.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	outline: none;
}

.clmn_2_chat_folders{
	display: none;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
	padding: 8px 10px;
	border-radius: 4px;
}

.clmn_2_chat_folder_item{
	font-size: 12px;
	padding: 5px 6px;
	border-radius: 5px;
	color: #535973;
	border: 1px solid #535973;
	margin: 0 4px;
	font-weight: 500;
}

.clmn_2_chat_folder_item.active{
	border-color: transparent;
	background: linear-gradient(to right, #357F89, #1C4B72);
	color: #fff;
}

@media screen and (max-width: 999px){
	.clmn_2_chat_folders{
		display: flex;
	}
}

@media screen and (max-width: 599px){
	.clmn_2_chat_folders{
		display: flex;
		justify-content: space-evenly;
		padding-left: 0;
		padding-right: 0;
		flex: none;
	}

	.clmn_2_chat_folder_item{
		margin: 0;
		padding: 4px 6px;
	}

	.clmn_2_chat_folder_item span{
		display: none;
	}
}
