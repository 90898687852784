.crop_modal {
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 12px 24px;

  @media screen and (max-height: 700px) {
	height: 100%;
	overflow-y: auto;
  }
}
