@import "@sitestyles";

.block_modal {
  width: 500px;
  padding: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-weight: 600;
  }

  &__buttons_container {
    display: flex;
    gap: 50px;

    &__button_ok {
      display: block;
      max-width: calc(50% - 14px);
      flex: 1;
      height: 43px;
      line-height: 43px;
      margin: 20px auto 10px;
      width: 120px;
      border-radius: 4px;
      font-weight: 600;
      cursor: pointer;

      background: transparent;
      border: 1px solid #00317B;
      color: #00317B;


      &:hover{
        box-shadow: 0 4px 8px rgba(14, 4, 143, 0.24);
      }
    }


    &__button_cancel {
      display: block;
      max-width: calc(50% - 14px);
      flex: 1;
      height: 43px;
      line-height: 43px;
      margin: 20px auto 10px;
      background: linear-gradient(90.66deg, #00317B 0%, #720066 100%);
      border-radius: 4px;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

