@import "@sitestyles";


.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
	width: 500px;
	height: 300px;
}

.rh5v-DefaultPlayer_video{
	max-height: calc(100vh - 30px);
	max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
	width: auto;
	max-width: calc(100vw - 30px);
}

.rh5v-DefaultPlayer_component {
	font-family: 'Montserrat', sans-serif;
	background-color: transparent;
}

.rh5v-Overlay_icon {
	display: none;
}

.rh5v-Overlay_inner {
	background-color: transparent;
	width: 164px;
	height: 164px;
	background-image: url(/img/play-btn.svg);
	background-position: center;
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 0;
	transform: translateY(-50%) translateX(-50%);
}

.rh5v-PlayPause_icon {
	display: none;
}

.rh5v-PlayPause_button {
	background-image: url(/img/video-play-small.svg);
	background-repeat: no-repeat;
	-webkit-background-size: 26px 35px;
	background-size: 26px 35px;
	background-position: center;
}

.rh5v-PlayPause_button[aria-label="Pause video"] {
	background-image: url("/img/video-pause-small.svg");
}

.rh5v-PlayPause_component:hover {
	background-color: transparent;
}

.rh5v-PlayPause_component {
	position: absolute;
	left: 20px;
	bottom: 100%;
}

.rh5v-Seek_component {
	margin-left: 20px;
	margin-right: 25px;
}

.rh5v-DefaultPlayer_controls {
	background-color: transparent;
}

.rh5v-Seek_track {
	background-color: rgba(255, 255, 255, 0.6);
}

.rh5v-Seek_buffer {
	background-color: transparent;
}

.rh5v-Seek_fill {
	background-color: #00317B;
}

.rh5v-Time_component {
	position: absolute;
	bottom: 106%;
	right: 110px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	padding: 0;
}

.rh5v-Time_duration {
	color: rgba(255, 255, 255, 0.7);
}

.rh5v-Volume_component {
	position: absolute;
	right: 60px;
	bottom: 100%;
}

.rh5v-Volume_component:hover {
	background-color: transparent;
}

.rh5v-Volume_button {
	width: 40px;
	height: 40px;
	display: block;
}

.rh5v-Volume_icon {
	width: 40px;
	height: 40px;
}

.rh5v-Volume_slider {
	height: 70px;
	background-color: transparent;
}

.rh5v-Volume_track {
	width: 6px;
}

.rh5v-Fullscreen_component {
	position: absolute;
	right: 20px;
	bottom: 100%;
}

.rh5v-Fullscreen_component:hover {
	background-color: transparent;
}

.rh5v-Fullscreen_button {
	width: 40px;
	height: 40px;
	display: block;
}

.rh5v-Fullscreen_icon {
	width: 40px;
	height: 40px;
}

@media screen and (max-width: 1139px){
	.rh5v-DefaultPlayer_controls{
		display: none;
	}
}
