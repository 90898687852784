@import "@sitestyles";

.wm_main_wrap{
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	-moz-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	-o-flex-direction: row-reverse;
	flex-direction: row-reverse;
	background-color: #fff;
}

.wm_main_right{
	width: 46%;
	background-image: url(./img/login/wm-right-bg.svg);
	background-repeat: no-repeat;
	background-position: center;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.wm_main_left{
	width: 54%;
	background-image: url(./img/login/wm-left-bg.svg);
	background-repeat: no-repeat;
	background-position: right center;
	-webkit-background-size: cover;
	background-size: cover;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	padding-right: 150px;
}

.wm_main_left_logo{
	margin-left: auto;
	margin-top: 46px;
	margin-right: 63px;
	font-size: 48px;
	font-weight: 600;
}

.wm_main_form{
	display: block;
	width: 471px;
	max-width: 100%;
}

.wm_main_form_row{
	margin-bottom: 37px;
}

.wm_main_form_row span{
	display: block;
	font-size: 24px;
	color: #F4F4F4;
	margin-bottom: 17px;
}

.wm_main_form_row input{
	display: block;
	width: 100%;
	height: 55px;
	font-size: 24px;
	background-color: #DCEAFF;
	padding: 0 10px;
	border: none;
}

.wm_main_form_btn{
	display: block;
	width: 284px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	background-color: #fff;
	font-size: 24px;
	font-weight: 600;
	color: #14438A;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 70px auto 0;
	text-transform: uppercase;
}
.wm_main_form_btn:hover, .wm_main_form_btn:focus{
	background-color: #eaf2ff;
	box-shadow: 0 -4px 6px rgba(0,0,0,0.15) inset;



}

.wm_main_form_btn img{
	vertical-align: middle;
	margin-bottom: 4px;
	margin-right: 24px;
}
/**
MEDIA START
 */
@media screen and (max-width: 1899px){
	.wm_main_right{
		-webkit-background-size: 90%;
		background-size: 90%;
	}
}

@media screen and (max-width: 1299px){
	.wm_main_left{
		padding-right: 100px;
	}

	.wm_main_form{
		width: 320px;
	}

	.wm_main_form_row span{
		font-size: 20px;
		margin-bottom: 14px;
	}

	.wm_main_form_row input{
		height: 40px;
		font-size: 18px;
	}

	.wm_main_form_btn{
		width: 220px;
		height: 60px;
		line-height: 60px;
		font-size: 20px;
		margin-top: 50px;
	}

	.wm_main_form_btn img{
		width: 19px;
		margin-right: 17px;
		margin-bottom: 2px;
	}
}

@media screen and (max-width: 899px){
	.wm_main_wrap{
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		-o-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.wm_main_right{
		width: 100%;
		height: 45vh;
		height: calc(var(--vh, 1vh) * 45);
		-webkit-background-size: 90% 90%;
		background-size: 90% 90%;
	}

	.wm_main_left{
		width: 100%;
		height: 55vh;
		height: calc(var(--vh, 1vh) * 55);
		background-image: url(./img/login/wm-bottom-bg.svg);
		-webkit-background-size: cover;
		background-size: cover;
		background-position: center top;
		padding-right: 0;
		-ms-align-items: flex-end;
		align-items: flex-end;
		padding-bottom: 40px;
	}

	.wm_main_form{
		width: 240px;
	}

	.wm_main_form_row input{
		height: 30px;
		font-size: 14px;
	}

	.wm_main_form_row span{
		font-size: 14px;
		margin-bottom: 10px;
	}

	.wm_main_form_row{
		margin-bottom: 20px;
	}

	.wm_main_form_btn{
		width: 176px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
	}

	.wm_main_form_btn span{
		display: inline-block;
		margin-top: 2px;
	}

	.wm_main_form_btn img{
		margin-bottom: 4px;
	}

	.wm_main_left_logo{
		font-size: 18px;
		margin-top: 18px;
		margin-left: 24px;
		margin-right: 0;
	}
}
/**
MEDIA END
 */
