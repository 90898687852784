@import "@sitestyles";

.posts_page_wrap {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 0 10px 0 6px;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 999px) {
	flex-wrap: wrap;
  }
}

.posts_page {
  background: #fff;
  width: 100%;
}

.posts_page_choose_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  color: #1C4B72;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.48px;
}

//START HEADER ==================================================

.posts_page_header {
  height: 72px;
  border-bottom: 1px solid #EBEBEB;
  padding: 0 24px;
}

.posts_page_header_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.posts_page_header_list {
  display: flex;
  height: 100%;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.posts_page_header_item {
  padding: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;

  color: #474F5C;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
	color: #414141;
  }

  &.active {
	border-bottom: 2px solid #626262;
	font-weight: 700;
	color: #414141;
  }
}

.posts_page_btns_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.posts_page_create_btn {
  height: 48px;
  min-height: 48px;
  width: 200px;
  border-radius: 5px;
  background: linear-gradient(92deg, #357F89 0.83%, #1C4B72 99.53%);
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
	box-shadow: 0 4px 8px rgba(14, 4, 143, 0.24);
  }

  img {
	margin-right: 10px;
	max-height: 24px;
  }

  &.disabled {
	opacity: .5;
	pointer-events: none;
  }

  &.outline {
	background: transparent;
	color: #1C4B72;
	outline: 1px solid #1C4B72;
  }

  &.delete {
	color: #E00;
	outline: 1px solid #E00;
  }
}

//END HEADER ==================================================

//START BODY ==================================================

.posts_page_body {
  height: 100%;
}

.posts_page_body_inner {
  height: calc(100% - 24px);
  overflow-y: auto;
  padding: 24px 10px 34px 24px;

  &::-webkit-scrollbar {
	width: 4px;
	background: #fff;
	border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
	background: #96B6CF;
	border-radius: 4px;
  }
}

.posts_page_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  //grid-gap: 16px;
  column-gap: 16px;
  row-gap: 24px;
  justify-content: center;
  margin-bottom: 14px;
  padding-bottom: 24px;
  background: #fff;

  .posts_page_pagination_wrap {
	grid-column: span 4;
	margin: 10px auto;
  }

  @media screen and (max-width: 1600px) {
	grid-template-columns: repeat(3, 1fr);

	.posts_page_pagination_wrap {
	  grid-column: span 3;
	}
  }
}

.posts_page_grid_item {
  height: 450px;
  max-width: 326px;
  display: flex;
  flex-direction: column;
}

.posts_page_grid_img_wrap {
  position: relative;
  height: 326px;
  width: 100%;
  margin-bottom: 20px;

  img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top;
	cursor: pointer;
  }
}

.posts_page_icon_arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
  }

  &:hover {
	opacity: 0.9;
  }

  &:active {
	box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  }

  &.left {
	left: 12px;

	&:before {
	  background: url('../../img/posts/post_arrow_prev.svg') center no-repeat;
	  margin-right: 1px;
	}
  }

  &.right {
	right: 12px;

	&:before {
	  background: url('../../img/posts/post_arrow_next.svg') center no-repeat;
	  margin-left: 1px;
	}
  }
}

.icon_play {
  position: absolute;
  width: 58px;
  height: 58px;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  z-index: 2;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('../../img/c3-media-video-play.svg') center no-repeat;
    background-size: cover;
  }
}

.post_modal_view_img_wrap .icon_play {
  width: 88px;
  height: 88px;

  &:hover {
    opacity: 0.9;
  }
}

.posts_page_icon_dots_row {
  position: absolute;
  display: flex;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.posts_page_icon_dot {
  width: 8px;
  height: 8px;
  border-radius: 13px;
  background: rgba(255, 255, 255, 0.50);
  margin-right: 8px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);

  &.active {
	background: #fff;
  }

  &:last-child {
    margin-right: 0;
  }
}

.posts_page_grid_item_content {
  padding: 1px 1px 4px 1px;
}

.posts_page_grid_activity_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.posts_page_grid_activity_item_wrap {
  display: flex;
}

.posts_page_grid_activity_item {
  display: flex;
  align-items: center;
  color: #363636;
  font-size: 14px;
  font-weight: 500;

  &:first-child {
	margin-right: 16px;
  }

  img {
	margin-right: 8px;
  }

  span {
	color: #E00;
	font-weight: 600;
  }

  &.action {
	cursor: pointer;

	&:hover {
	  opacity: 0.9;
	}
  }
}

.posts_page_grid_item_text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  height: 43px;
  width: 100%;
  margin-bottom: 10px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.posts_page_grid_item_see_more {
  color: #1F4F74;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
	text-decoration: underline;
  }
}

.posts_page_grid_item_bottom_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.posts_page_grid_item_status {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 2px;

  &:before {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	margin-right: 8px;
  }

  &.status_1 {
	color: #EEBD26;

	&:before {
	  background: url('../../img/posts/status_in_progress.svg') center no-repeat;
	}
  }

  &.status_2 {
	color: #63B485;

	&:before {
	  background: url('../../img/posts/status_approved.svg') center no-repeat;
	}
  }

  &.status_3 {
	color: #EB5757;

	&:before {
	  background: url('../../img/posts/status_declined.svg') center no-repeat;
	}
  }

  &.status_4 {
	color: #C03439;

	&:before {
	  background: url('../../img/posts/status_rework.svg') center no-repeat;
	}
  }

  &.status_5 {
	color: #474F5C;

	&:before {
	  background: url('../../img/posts/status_draft.svg') center no-repeat;
	}
  }

  &.status_6 {
	color: #969696;

	&:before {
	  background: url('../../img/posts/status_hidden.svg') center no-repeat;
	}
  }
}

.posts_page_grid_item_data_wrap {
  display: flex;
  color: #404040;
  font-size: 14px;
  font-weight: 400;

  span:first-child {
	margin-right: 16px;
  }
}

//END BODY ====================================================

//START MODAL =================================================

.post_modal {
  position: relative;
  background: #fff;
  display: flex;
  width: 800px;
  height: max-content;
  flex-direction: column;
  border-radius: 5px;
  padding: 12px 24px 24px 24px;
  text-align: left;

  @media screen and (max-height: 750px) {
	height: 100%;
	overflow-y: auto;
  }
}

.post_modal_close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 99;

  &:hover {
	opacity: 0.7;
  }
}

.post_modal_header {
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.view {
	justify-content: flex-end;
  }
}

.post_modal_profile_box {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
	width: 40px;
	height: 40px;
	background: #eee;
	border-radius: 50%;
	margin-right: 8px;
  }
}

.post_modal_profile_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: #474F5C;
  font-size: 13px;
  font-weight: 600;

  span {
	color: #363636;
	font-size: 10px;
	font-weight: 500;
	line-height: 140%;
	margin-top: 4px;
  }
}

.post_modal_title {
  color: #2F2D48;
  font-size: 18px;
  font-weight: 600;
}

.post_modal_add_img_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;

  input[type=file] {
	display: none;
  }
}

.post_modal_img_counter {
  color: #2F2D48;
  font-size: 16px;
  font-weight: 600;

  span {
	margin-left: 10px;
  }
}

.post_modal_add_img_btn {
  color: #797979;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #A9A9A9;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	background: url('../../img/posts/plus_icon.svg') center no-repeat;
  }

  &:hover {
	box-shadow: 0 4px 8px rgba(238, 238, 238, 0.9);
  }

  &.disabled {
	opacity: .5;
	pointer-events: none;
  }
}

.post_modal_img_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
}

.post_modal_img_wrap {
  position: relative;
  width: 144px;
  height: 144px;
}

.post_modal_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 3px;
  outline: 1px solid #eee;
  z-index: 2;
}

.post_modal_img_delete_wrap {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.50);
  z-index: 3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
	width: 16px;
	height: 16px;
  }

  &:hover {
	background: #fff;
  }
}

.post_modal_img_empty {
  position: relative;
  height: 100%;
  border: 1px dashed #797979;
  border-radius: 3px;

  &:before {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	background: url('../../img/posts/plus_icon.svg') center no-repeat;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
  }
}

.post_modal_warning_text {
  width: 100%;
  color: #676E76;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  display: flex;
  align-items: center;

  &:before {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	background: url('../../img/posts/question_mark_circle.svg') center no-repeat;
  }
}

.post_modal_description_row {
  height: 40px;
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #2F2D48;
  font-size: 18px;
  font-weight: 600;

  span {
	color: #8A8A8A;
	font-size: 16px;
	font-weight: 500;
  }
}

.post_modal_textarea_wrap {
  position: relative;
  height: 160px;
  min-height: 120px;
  margin: 8px 0 40px 0;
}

.post_modal_textarea {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #CBCBCB;
  resize: none;
  padding: 10px 40px 10px 14px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;

  &::-webkit-scrollbar {
	width: 5px;
	background: rgba(203, 202, 211, 0.2);
	border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
	background: #939495;
	border-radius: 8px;
  }
}

.post_modal_create_another {
  margin-bottom: 40px;
  width: 100%;

  input[type="checkbox"] {
	display: none;
  }

  input[type="checkbox"] + label {
	display: flex;
	align-items: center;
	width: max-content;
	cursor: pointer;
	color: #2F2D48;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.32px;

	&:before {
	  content: '';
	  display: block;
	  margin-right: 8px;
	  background: #fff;
	  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 2px 5px 0 rgba(103, 110, 118, 0.08);
	  height: 17px;
	  width: 17px;
	  border-radius: 4px;
	}
  }

  input[type="checkbox"]:checked + label {
	&:before {
	  content: "\2713";
	  text-align: center;
	  font-size: 12px;
	  font-weight: 600;
	  color: #fff;
	  background: #1C4B72;
	  box-shadow: none;
	}
  }
}

//START View post modal

.post_modal_view {
  position: relative;
  background: #fff;
  display: flex;
  //width: 1048px;
  height: calc(100% - 40px);
  flex-direction: row;
  border-radius: 5px;
  text-align: left;
  overflow: hidden;

  .posts_page_grid_activity_item_wrap {
	margin-right: 20px;
  }

  .posts_page_icon_arrow {
	width: 40px;
	height: 40px;
  }

  @media screen and (max-height: 800px) {
	height: 100%
  }
}

.post_modal_left_wrap {
  padding: 16px 0 0 24px;
}

.post_modal_left {
  width: 648px;
  height: 100%;
  padding-right: 8px;
  margin-right: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
	width: 4px;
	background: rgba(203, 202, 211, 0.2);
	border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
	background: #CBCAD3;
	border-radius: 4px;
  }
}

.post_modal_right {
  width: 400px;
  height: 100%;
  border-left: 1px solid #E8E8E8;
  padding: 16px 16px 0 0;
}

.post_modal_left_body {
  margin-top: 16px;
  padding-bottom: 24px;
}

.post_modal_view_img_wrap {
  position: relative;
  height: 600px;
  width: 100%;
  margin: 0 auto 24px;

  img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top;
  }

  @media screen and (max-height: 800px) {
	height: 500px;
  }
}

.post_modal_view_img_counter {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 60px;
  background: rgba(51, 51, 51, 0.50);
  padding: 2px 14px;
  letter-spacing: 1px;
}

.post_modal_left_body_text {
  margin-bottom: 16px;
  color: #363636;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-height: 65px;
}

.post_modal_left_body_data {
  height: 40px;
  display: flex;
  align-items: center;
  color: #7E7D8B;
  font-size: 14px;
  font-weight: 400;

  span:first-child {
	margin-right: 16px;
  }
}

//START Moderation Response BLOCK ===================================
.mod_response {
  border-top: 1px solid #E8E8E8;
  margin-top: 10px;
  padding-top: 16px;
}

.mod_response_title {
  margin-bottom: 16px;
  font-size: 18px;
  color: #EB5757;
}

.mod_response_inner {
  margin-bottom: 16px;
}

.mod_response_data {
  font-size: 14px;
  font-weight: 400;
  color: #7E7D8B;
  margin-bottom: 10px;

  span:first-child {
	font-weight: 600;
  }

  span:last-child {
	margin-left: 16px;
  }
}

.mod_response_text {
  margin-bottom: 16px;
  color: #363636;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.mod_response_img_wrap {
  img {
	cursor: pointer;
	max-width: 100%;
  }
}
//END Moderation Response BLOCK =====================================

.post_modal_right_header {
  height: 140px;
}

.post_modal_comments_title {
  color: #2F2D48;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 16px 24px;
  height: 48px;
  display: flex;
  align-items: center;

  span {
	color: #363636;
	font-size: 16px;
	font-weight: 500;
  }
}

.post_modal_input_row {
  display: flex;
  align-items: center;
  margin: 0 0 8px 24px;

  &.disabled {
	opacity: .5;
	pointer-events: none;
  }
}

.post_modal_comments_textarea_wrap {
  position: relative;
  width: 100%;
  height: 48px;
}

.post_modal_comments_textarea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  background: #E8E8E8;
  color: #363636;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 44px 12px 16px;
  border-radius: 8px;
  min-height: 48px;
  height: 60px;
  max-height: 74px;
  width: 100%;
  resize: none;
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
  outline: none;

  &::placeholder {
	color: #7E7D8B;
  }

  &::-webkit-scrollbar {
	width: 5px;
	background: rgba(203, 202, 211, 0.2);
	border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
	background: #939495;
	border-radius: 8px;
  }
}

.post_modal_smile_icon {
  position: absolute;
  top: 12px;
  right: 14px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  z-index: 999;

  &:hover {
	opacity: 0.9;
  }
}

.post_modal_smiles_block {
  position: absolute;
  top: 78px;
  right: 0;
  left: 0;
  z-index: 9999;
  border-radius: 8px;
  background: #E8E8E8;
  border: 1px solid #939495;
  height: 400px;

  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  &::-webkit-scrollbar {
	width: 5px;
	background: rgba(203, 202, 211, 0.2);
	border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
	background: #939495;
	border-radius: 8px;
  }

  &.description {
    top: 40px;
    right: -22px;
    left: unset;
    max-height: 230px;
  }
}

.post_modal_comment_text_counter {
  position: absolute;
  right: 6px;
  top: -18px;
  font-size: 12px;
  color: #939495;
  z-index: 999;
}

.post_modal_send_icon {
  margin-left: 12px;
  cursor: pointer;

  &:hover {
	opacity: 0.9;
  }
}

.post_modal_comments_list {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 140px);
  width: 100%;

  &::-webkit-scrollbar {
	width: 4px;
	background: rgba(203, 202, 211, 0.2);
	border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
	background: #CBCAD3;
	border-radius: 4px;
  }
}

.post_modal_comment {
  position: relative;
  padding: 12px 24px;
  min-height: 105px;
  border-bottom: 1px solid #DBDEE1;
  display: flex;

  &:last-child {
	border-bottom: transparent;
  }

  &.need_answer {
	background: #b8edb8;
  }

  &.active {
	background: #DEE6F1;
  }

  &.reply {
	margin-top: 8px;
	padding: 0;

	.post_modal_comment_info {
	  max-width: 219px;
	}

	.post_modal_comment_text {
	  display: block;
	  -webkit-line-clamp: initial;
	  -webkit-box-orient: initial;
	}
  }
}

.deselect_comment_icon {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;

  &:hover {
	opacity: 0.7;
  }
}

.post_modal_comment_avatar_wrap {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 16px;

  img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	//object-position: top;
	border-radius: 50%;
  }
}

.post_modal_comment_info {
  flex: 1;
  max-width: 275px;
}

.post_modal_comment_username {
  color: #2F2D48;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  padding-right: 24px;
}

.post_modal_comment_text {
  color: #7E7D8B;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: auto;
  word-wrap: break-word;
  white-space: normal;

  &.opened {
	display: block;
	-webkit-line-clamp: initial;
	-webkit-box-orient: initial;
  }
}

.post_modal_comment_open_row {
  color: #5E5C75;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
	opacity: 0.9;
  }

  &:after {
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	margin-left: 8px;
	background: url('../../img/posts/arrow_icon.svg') center no-repeat;
  }

  &.active:after {
	transform: rotate(180deg);
  }

  span {
	letter-spacing: 1px;
  }
}

.post_modal_comment_info_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7E7D8B;
  margin: 8px 0;
}

.post_modal_comment_data {
  font-size: 14px;
  font-weight: 400;

  span:first-child {
	margin-right: 8px;
  }
}

.post_modal_comment_reply_title {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;

  &:hover {
	opacity: 0.9;
  }

  img {
	margin-right: 4px;
  }
}

.post_modal_comment_delete {
  cursor: pointer;
  width: 16px;
  height: 16px;

  &:hover {
	opacity: 0.9;
  }
}

//END View post modal


//END MODAL ===================================================
