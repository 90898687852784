@import "@sitestyles";

.clmn_1_profile_wrap{
	background-color: #fff;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 6px;
	position: relative;
	margin-bottom: 20px;
}

.clmn_1_profile_back_to_mm{
	position: absolute;
	right: 6px;
	top: 6px;
	cursor: pointer;
}

.clmn_1_profile_back_to_mm img{
	display: block;
}

.clmn_1_profile_top{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	margin-bottom: 9px;
}

.clmn_1_profile_photo{
	width: 56px;
	height: 56px;
	border-radius: 100%;
	margin-right: 6px;
	cursor: pointer;
}
.clmn_1_profile_name{
	font-weight: 600;
	color: $color3;
	margin-bottom: 2px;
}

.clmn_1_profile_id{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.7);
}
.clmn_1_profile_bottom{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
}

.clmn_1_profile_btn{
	width: 83px;
	height: 33px;
	border: 1px solid rgba(51, 51, 51, 0.1);
	border-radius: 5px;
}

.clmn_1_profile_btn{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 8px;
	cursor: pointer;
}

.clmn_1_profile_btn.sender{

}

.clmn_1_mm_chat_list_item_status_green{
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #357F89;
	margin-left: 6px;
}

.clmn_1_mm_chat_list_item_status_yellow{
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #F2C94C;
	margin-left: 4px;
}

.clmn_1_profile_btn.letters{
	width: 58px;
	padding: 0 6px;
}


.clmn_1_profile_btn_gifts_val{
	font-weight: 500;
	font-size: 12px;
	color: #204174;
	margin-left: auto;
}

.clmn_1_profile_btn_letters_val{
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	background-color: #EB5757;
	border-radius: 100%;
	color: #fff;
	font-weight: 500;
	font-size: 11px;
}

@media screen and (max-width: 1399px){
	.clmn_1_profile_top{
		flex-direction: column;
		text-align: center;
	}

	.clmn_1_profile_photo{
		margin-bottom: 5px;
	}

	.clmn_1_profile_btn{
		width: auto;
		flex: 1;
	}

	.clmn_1_profile_btn + .clmn_1_profile_btn{
		margin-left: 5px;
	}
}

@media screen and (max-width: 999px){
	.clmn_1_profile_wrap{
		display: flex;
		align-items: center;
		border-radius: 10px;
		box-shadow: 0 3px 5px rgba(47, 43, 99, .16);
		padding: 10px;
	}

	.clmn_1_profile_bottom{
		flex: 1;
		margin-left: 20px;
		margin-top: auto;
	}

	.clmn_1_profile_top{
		flex-direction: row;
		margin-bottom: 0;
		text-align: left;
	}

	.clmn_1_profile_back_to_mm{
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 3px;
		top: 3px;
	}

	.clmn_1_profile_back_to_mm img{
		width: 17px;
	}

	.clmn_1_profile_photo{
		margin-bottom: 0;
	}
}
