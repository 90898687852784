.mobile_menu_page{
  width: 100%;
  overflow-y: auto;
  margin-right: 5px;
}

.mobile_menu_page::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.mobile_menu_page::-webkit-scrollbar-thumb{
  background: #ADBAFF;
  border-radius: 3px;
}

.mobile_menu_list{
  width: 100%;
  padding: 20px 5px 20px 10px;
  max-width: 320px;
  margin: 0 auto;
}

.mobile_menu_item{
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 6px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 5px rgba(47, 43, 99, .16);
  border-radius: 10px;
  padding: 0 20px;
  font-weight: 600;
  color: #1f4f74;
}

.mobile_menu_item.disable{
  filter: grayscale(1);
  pointer-events: none;
  opacity: .5;
  box-shadow: none;
}

.mobile_menu_item img{
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 10px;
}
