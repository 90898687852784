@import "@sitestyles";

.overlay {
	display: none;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	background-color: rgba(235, 237, 250, .5);
	z-index: 6;
	position: absolute;
	left: 0;
	backdrop-filter: blur(4px);
	top: 0;
}

.overlay.dark {
	background-color: rgba(0, 0, 0, .5);
}

.overlay.active {
	display: block;
}

.blur {
	-webkit-filter: blur(2px);
	filter: blur(2px);
}

.chat_wrap {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

.c1_top {
	background-color: #EBEDFA;
	padding-bottom: 4px;
}

.c1_top_logo{
	height: 108px;
}

.c1_logo {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	height: 80px;
}

.c1_logo img:nth-child(2) {
	width: 60px;
	display: block;
	margin-left: 6px;
	transition: .5s;
}

.column-1.small .c1_logo img:nth-child(2) {
	width: 0px;
}

.c1_profile_wrap {
	background-color: #fff;
	padding: 16px 12px 12px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}

.c1_profile_photo {
	margin-right: 12px;
}

.c1_profile_photo img {
	width: 56px;
	height: 56px;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 100%;
}

.c1_profile_right a {
	display: block;
	width: 112px;
	height: 24px;
	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	padding-top: 3px;
	font-weight: 500;
	line-height: 17px;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	overflow: hidden;
}

.column-1.small .c1_profile_right a {
	width: 30px;
}

.c1_profile_right a span {
	width: 80px;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	display: block;
	white-space: nowrap;
}

.column-1.small .c1_profile_right a span {
	width: 0px;
	overflow: hidden;
}

.c1_profile_link {
	display: block;
	padding-left: 30px;
	color: #446077;
	border-color: #446077;
	background-image: url(../img/profile_ico.svg);
	background-repeat: no-repeat;
	background-position: 7px 4px;
	margin-bottom: 8px;
}

.c1_add_credits {
	display: block;
	padding-left: 27px;
	color: #fff;
	background-color: #E0162B;
	border-color: #E0162B;
	background-image: url(../img/get_credit_ico.svg);
	background-repeat: no-repeat;
	background-position: 6px 3px;
}

.c1_wrap2 {
	/*padding: 4px 4px 4px 0;*/
	height: calc(100vh - 370px);
	height: calc(calc(var(--vh, 1vh) * 100) - 370px);
}

.small .c1_top_wrap.active + .c1_wrap2 {
	height: calc(100vh - 422px);
	height: calc(calc(var(--vh, 1vh) * 100) - 422px);
}

.c1_wrap {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #fff;
}

.c1_wrap::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	border-radius: 5px;
	margin-right: 3px;
}

.c1_wrap::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 7px;
}

.c1_menu {
	/*margin-top: -4px;*/
}

.c1_menu_item {
	color: rgba(55, 78, 108, 0.56);
	font-size: 16px;
	font-weight: 500;
	display: none;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 0 16px 0 31px;
	letter-spacing: -0.02em;
	height: 71px;
}

.c1_menu_main_item.active:first-child ~ .c1_menu_item{
	display: flex;
}

.small .c1_menu_item {
	justify-content: space-between;
	padding: 10px;
}

.c1_menu_item.active {
	color: #00317B;
}

.c1_menu_item_left span {
	max-width: 150px;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	white-space: nowrap;
	display: block;
	text-transform: uppercase;
}

.c1_menu_item.active .c1_menu_item_left span {
	font-weight: 600;
}

.c1_menu_item_right {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	line-height: 1;
}

.c1_menu_item_right_unread {
	font-weight: 500;
	font-size: 24px;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: #8F9CAD;
	margin-right: 22px;
}

.small .c1_menu_item_right_unread {
	margin-right: 7px;
}

.c1_menu_item.active .c1_menu_item_right_unread {
	color: #00317B;
}

.c1_menu_item_count_big {
	background-color: #EBEDFA;
	padding: 2px 4px 3px;
	font-weight: 600;
	color: rgba(55, 78, 108, 0.56)
}

.small .c1_menu_item_count_big {
	width: 54px;
}

.c1_submenu_item_right_top {
	border-bottom: 1px solid #fff;
	padding-bottom: 3px;
	padding-top: 3px;
	margin-bottom: 5px;
	width: 60px;
	text-align: center;
}

.small .c1_submenu_item_right_top {
	width: 100%;
}

.c1_submenu_item_right_top span:before {
	content: '';
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 100%;
	background-color: #27AE60;
	vertical-align: middle;
	margin-right: 4px;
	margin-top: -2px;
}

.c1_submenu_item_right_bottom {
	text-align: center;
	width: 60px;
	margin-top: 4px;
}

.small .c1_submenu_item_right_bottom {
	width: 100%;
	text-align: center;
}

.column-1.small .c1_menu_item_left span {
	max-width: 0px;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	margin-top: 0;
}

.column-1.small .c1_menu_item {
	padding-left: 9px;
}

.c1_menu_item_left {
	/*display: flex;*/
}

.c1_menu_item svg {
	margin-right: 11px;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	stroke: rgba(61, 101, 153, 0.32);
	fill: transparent;
	stroke-width: 2px;
	margin-bottom: 4px;
}

.small .c1_menu_item svg {
	margin-bottom: 0;
}

.c1_menu_item.special svg {
	//stroke: unset;
	fill: rgba(61, 101, 153, 0.32);
}

.c1_menu_item.special.active svg {
	//stroke: unset;
	fill: #00317B;
}

.c1_menu_item.active svg {
	stroke: #00317B;
}

.c1_menu_bottom_wrap svg {
	//stroke: unset;
	fill: rgba(71, 79, 92, 0.8);
	margin-bottom: 0;
}

.c1_menu_bottom_wrap.special.active svg {
	//stroke: unset;
	fill: #00317B;
}

.c1_menu_more_btn.active svg {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.c1_menu_main_item {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 0 16px 0 22px;
	height: 71px;
	cursor: pointer;
}

.c1_menu_main_item_left {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c1_menu_main_item_left svg {
	margin-right: 12px;
	fill: rgba(71, 79, 92, 0.8);
}

.c1_menu_main_item_sender .c1_menu_main_item_left svg{
	stroke: rgba(71, 79, 92, 0.8);
	fill: transparent;
}

.c1_menu_main_item_sender.active .c1_menu_main_item_left svg {
	stroke: $color5;
	fill: transparent;
}

.small .c1_menu_main_item_left {
	width: 32px;
}

.small .c1_menu_main_item_left svg {
	margin: 0;
}

.small .gifts .c1_menu_main_item_left svg {
	margin-right: 0;
	margin-left: 3px;
}

.gifts .c1_menu_main_item_left svg {
	margin-right: 0;
	margin-left: 2px;
	margin-right: 12px;
}


.c1_menu_main_item_left span {
	font-weight: 600;
	font-size: 16px;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: rgba(55, 78, 108, 0.8);
	margin-top: 3px;
}

.c1_menu_main_item.active {
	background-color: #BACDE9;
}

.c1_menu_main_item.active .c1_menu_main_item_left svg {
	fill: $color5;
}

.c1_menu_main_item.active .c1_menu_main_item_left span {
	color: $color5;
}

.c1_menu_main_item_right {
	text-align: center;
	width: 68px;
	height: 26px;
	background-color: #EBEDFA;
	font-size: 16px;
	color: rgba(55, 78, 108, 0.56);
	letter-spacing: -0.02em;
	line-height: 28px;
	font-weight: 600;
	margin-left: auto;
}

.small .c1_menu_main_item_left span {
	max-width: 0;
	overflow: hidden;
}

.small .c1_menu_main_item {
	padding-left: 14px;
	padding-right: 10px;
	justify-content: center;
}

.small .c1_menu_main_item_right {
	width: 54px;
}


.c1_menu_bottom_wrap {
	background-color: #EBEDFA;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.c1_menu_bottom_wrap .c1_menu_item {
	padding: 20px 24px;
}

.c1_menu_bottom_wrap .c1_menu_item span {
	display: inline;
	vertical-align: middle;
	color: rgba(55, 78, 108, 0.8);
}

.c1_menu_bottom_wrap .c1_menu_item_left {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c1_menu_item_new {
	font-weight: 600;
	font-size: 16px;
	line-height: 100%;
	letter-spacing: -0.02em;
	color: #374E6C;
}


.c1_submenu_item {
	height: 60px;
	padding: 10px 12px 8px 18px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	cursor: pointer;
}

.c1_submenu_item.active {
	background-color: #446077;
	color: #fff;
}

.c1_submenu_item_left {
	color: rgba(55, 78, 108, 0.56);
	font-size: 16px;
	font-weight: 600;
}

.column-1.small .c1_submenu_item_left {
	padding-left: 10px;
}

.c1_submenu_item_left span {
	max-width: 100px;
	max-height: 20px;
	display: block;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	white-space: nowrap;
}

.column-1.small .c1_submenu_item_left span {
	max-width: 0px;
	max-height: 0px;
	overflow: hidden;
}

.c1_submenu_item.active .c1_submenu_item_left {
	color: #fff;
}

.c1_submenu_item_left svg {
	display: block;
	margin-bottom: 9px;
	position: relative;
	top: 3px;
	fill: #3D6599;
	stroke: #3D6599;
	opacity: .32;
}

.c1_submenu_item.active .c1_submenu_item_left svg {
	fill: #fff;
	stroke: #fff;
	opacity: 1;
}

.c1_submenu_item_right {
	font-size: 12px;
	font-weight: bold;
	color: #8694AB;
	line-height: 1;
	letter-spacing: -0.02em;
	text-align: center;
	width: 50px;
	height: 39px;
	background-color: #EBEDFA;
	border-radius: 4px;
	padding: 1px 4px 0;
}

.c1_menu_item_count {
	text-align: center;
	width: 68px;
	height: 26px;
	background-color: #EBEDFA;
	font-size: 16px;
	color: rgba(55, 78, 108, 0.56);
	letter-spacing: -0.02em;
	line-height: 28px;
	font-weight: 600;
	margin-left: auto;
}

.small .c1_menu_item_count {
	width: 54px;
}

.c1_menu_item_count span {
	line-height: 26px;
	position: relative;
}

.c1_menu_item_count span:before {
	content: '';
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 100%;
	background-color: #27AE60;
	vertical-align: middle;
	margin-right: 4px;
	margin-top: -2px;
}

.c1_menu_more_btn {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #6F0267;
	cursor: pointer;
	border: none;
}

.c1_menu_more_btn span {
	width: 52px;
}

.c1_menu_item.c1_menu_more_btn.active {
	color: #446077;
}

.c1_menu_more_btn img {
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
}

.c1_menu_more_btn.active img {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.c1_menu_more {
	display: none;
}

.c1_menu_hide_wrap {
	width: calc(100% - 8px);
	height: 72px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.c1_menu_hide {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 2px solid #720066;
	width: 44px;
	height: 33px;
	cursor: pointer;
}

.c1_menu_hide img {
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
}

.c1_menu_hide.small img {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}

.c2_top {
	height: 108px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 20px 26px;
	-ms-align-items: center;
	align-items: center;
}

.c2_top_online_text {
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: #374E6C;
	margin-right: 14px;
}

.c2_top_tumbler input {
	display: none;
}

.c2_top_tumbler label {
	display: block;
	position: relative;
	width: 36px;
	height: 18px;
	background-color: #CDD3DA;
	border-radius: 19px;
	margin-top: -1px;
}

.c2_top_tumbler label:before {
	content: '';
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 100%;
	background-color: #fff;
	position: absolute;
	left: 2px;
	top: 2px;
	-webkit-transition: left .3s;
	-o-transition: left .3s;
	transition: left .3s;
}

.c2_top_tumbler input:checked + label {
	background-color: #fff;
}

.c2_top_tumbler input:checked + label:before {
	left: 20px;
	background-color: #27AE60;
}

.c2_top_search {
	position: relative;
	width: 100%;
	margin-bottom: 10px;
}

.c2_top_search input, .login-input {
	width: 100%;
	height: 30px;
	background-color: #fff;
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: -0.02em;
	padding-left: 12px;
	padding-right: 30px;
	color: #374E6C;
}

.c2_top_search input::-webkit-input-placeholder {
	font-weight: 500;
	color: rgba(51, 51, 51, 0.3);
}

.c2_top_search input::placeholder,
.c2_top_search input::-webkit-input-placeholder {
	font-weight: 500;
	color: rgba(51, 51, 51, 0.3);
}

.c2_top_search button {
	height: 100%;
	width: 30px;
	position: absolute;
	right: 0;
	top: 0;
	line-height: 30px;
	cursor: pointer;
}

.c2_top_search button img {
	vertical-align: middle;
	margin-top: -2px;
}

.c2_list_wrap_2 {
	padding-right: 4px;
}

.c2_list_wrap {
	height: calc(100vh - 108px);
	height: calc(calc(var(--vh, 1vh) * 100) - 108px);
	overflow-y: auto;
}

.c2_list_wrap::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	margin-right: 3px;
}

.c2_list_wrap::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}

.c2_list {
	padding: 0 4px 0 4px;
}

.c2_item {
	position: relative;
	background-color: #fff;
	padding: 14px 0;
	margin-bottom: 8px;
	-webkit-box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	cursor: pointer;
}

.c2_item_status_date{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	position: absolute;
	right: 14px;
	bottom: 23px;
	color: #27AE60;
}

.c2_item_status_date.week{
	color: #FD9A04;
}

.c2_item_status_date.month{
	color: #EB5757;
}

.c2_item_top {
	margin: 0 14px;
	padding-bottom: 8px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.c2_item.current .c2_item_top {
	border-color: rgba(235, 237, 250, .32);
}

.c2_item_right {
	flex: 1;
}

.c2_item_photo_wrap {
	position: relative;
	margin-right: 16px;
	margin-left: 10px;
}

.c2_item_photo {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	display: block;
}

.c2_item_photo_delete {
	position: absolute;
	left: -12px;
	top: -5px;
	cursor: pointer;
}

.c2_item_photo_counter {
	display: block;
	width: 28px;
	height: 28px;
	position: absolute;
	left: -14px;
	top: -2px;
	background-color: #EB5757;
	border-radius: 4px;
	font-weight: 600;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #FFFFFF;
}

.c2_item_name {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: $color3;
	margin-bottom: 8px;
	max-width: 180px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.c2_item_text {
	font-size: 13px;
	line-height: 150%;
	color: #4F4F4F;
	font-family: "Roboto", sans-serif;
	height: 38px;
	overflow: hidden;
	word-break: break-all;
}

.c2_item_text.from_you {
	position: relative;
	padding-left: 30px;
}

.c2_item_text.from_you:before {
	content: 'You:';
	position: absolute;
	left: 0;
	color: #02317C;
}

.c2_item_bottom {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 14px;
	margin-top: 9px;
	font-family: "Roboto", sans-serif;
}

.c2_item_bottom_photo {
	width: 34px;
	height: 34px;
	display: block;
	border-radius: 100%;
	object-fit: cover;
	margin-right: 11px;
}

.c2_item_bottom_place_wrap {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-top: 2px;
}

.c2_item_bottom_name {
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: $color3;
	width: 100%;
	grid-area: c2_bottom_name;
}

.c2_item_bottom_place_wrap {
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.5);
}

.c2_item_bottom_id {
	position: relative;
	padding-right: 5px;
	grid-area: c2_bottom_id;
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.5);
	display: none;
}

.c4_item.message .c2_item_bottom_id,
.c4_item.message .c2_item_bottom_place{
	color: #BDBDBD;
}

.c4_item.message.read .c2_item_bottom_id,
.c4_item.message.read .c2_item_bottom_place{
	color: rgba(51, 51, 51, 0.5);
}

.c2_item_bottom_id:after {
	content: '';
	display: block;
	width: 2px;
	height: 2px;
	border-radius: 100%;
	background-color: rgba(51, 51, 51, 0.5);
	position: absolute;
	right: -3px;
	top: 6px;
}

.c2_item.current .c2_item_bottom_id:after {
	background-color: #BDBDBD;
}

.c2_item_bottom_place {
	letter-spacing: -0.03em;
	margin-left: 0px;
	grid-area: c2_bottom_place;
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.5);
}

.c2_item_bottom_place img{
	height: 8px;
	margin-right: 3px;
	vertical-align: middle;
	margin-bottom: 2px;
}

.c2_item .message_status {
	position: absolute;
	top: 16px;
	right: 65px;
}

.c2_item.current .c2_item_bottom_name {
	color: #EDEDED;
}

.c2_item.current .c2_item_bottom_id,
.c2_item.current .c2_item_bottom_place {
	color: #BDBDBD;
}

.c2_item.current .c2_item_bottom_place_wrap {
	color: #BDBDBD;
}

.c2_item_bottom_left {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c2_item_id {
	width: 100%;
	font-size: 10px;
	line-height: 12px;
	color: #BDBDBD;
	display: none;
}

.c2_item_time {
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.03em;
	/*text-transform: uppercase;*/
	color: #BDBDBD;
	-ms-align-self: flex-end;
	align-self: flex-end;
	position: absolute;
	top: 15px;
	right: 15px;
}

.c2_item_photo_fav {
	display: none;
	position: absolute;
	right: 1px;
	top: -2px;
}

.c2_item_photo_fav svg {
	stroke: white;
	fill: #F2C94C;
}

.c2_item.current .c2_item_photo_fav svg {
	stroke: #446077;
}

.c2_item_photo_wrap:after {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	background-color: #E0E0E0;
	border-radius: 100%;
	border: 2px solid #fff;
	position: absolute;
	right: 0;
	bottom: 0;
}

.c2_item.current .c2_item_photo_wrap:after {
	border-color: #446077;
}

.c2_item.online .c2_item_photo_wrap:after {
	background-color: #27AE60;
}

.c2_item.favorites .c2_item_photo_fav {
	display: block;
}

.c2_item.current {
	background-color: #446077;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.c2_item.current .c2_item_name,
.c2_item.current .c2_item_text {
	color: #fff;
}

.c2_item.current .c2_item_text:before {
	color: #fff;
}

.column-3.empty:after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	background-image: url(../img/c3_empty.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	left: 0;
	top: 0;
	opacity: .56;
}

.c4_top {
	height: 80px;
	font-weight: 600;
	font-size: 16px;
	line-height: 80px;
	text-align: center;
	text-transform: uppercase;
	color: #720066;
}

.c4_top.blur {
	-webkit-filter: blur(2px);
	filter: blur(2px);
}

.c4_list_wrap_2 {
	padding-right: 7px;
}

.c4_list_wrap {
	height: calc(100vh - 80px);
	height: calc(calc(var(--vh, 1vh) * 100) - 80px);
	overflow-y: auto;
}

.c4_list_wrap::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	border-radius: 5px;
	margin-right: 3px;
}

.c4_list_wrap::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 7px;
}

.c4_list {
	padding: 0 6px 60px 4px;
}

.c4_item {
	background-color: #fff;
	-webkit-box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	margin-bottom: 8px;
	-ms-align-items: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 16px 23px;
}

.c4_item:hover{
	z-index: 99;
}

.c4_item.clicked {
	position: relative;
	z-index: 7;
	border: 8px solid #fff;
	border-radius: 0;
}

.c4_item.message {
	background-color: #7878B4;
}

.c4_item.message.favorites {
	background-color: #6F0267;
}
.c4_item.message.read{
	background-color: #fff;
}

.c4_item.blur {
	-webkit-filter: blur(2px);
	filter: blur(2px);
}

.c4_item_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	width: 100%;
	padding-bottom: 8px;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.c4_item.message .c4_item_top {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.c4_item.message.read .c4_item_top {
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.c4_item .c2_item_bottom {
	width: 100%;
	padding: 0;
	margin-top: 8px;
}

.c4_item .c2_item_bottom_name {
	color: rgba(51, 51, 51, 0.8);
}

.c4_item .c2_item_bottom_place_wrap {
	color: rgba(51, 51, 51, 0.5);
}

.c4_item.message .c2_item_bottom_name {
	color: #F4F4F4;
}

.c4_item.message.read .c2_item_bottom_name {
	color: rgba(51, 51, 51, 0.8);
}

.c4_item.message .c2_item_bottom_place_wrap {
	color: #B2B2B2;
}

.c4_item.message .c2_item_bottom_id:after {
	background-color: #B2B2B2;
}

.c4_item .c2_item_bottom_id:after {
	right: -3px;
	background-color: #B2B2B2;
}

.c4_item .message_status {
	position: absolute;
	top: 12px;
	right: 40px;
}

.c4_item_right {
	flex: 1;
	position: relative;
}

.c4_item_photo_wrap {
	position: relative;
	margin-right: 17px;
}

.c4_item .c2_item_photo_fav {
	display: block;
	right: -4px;
}

.c4_item .c2_item_photo_fav svg {
	stroke: #fff;
}

.c4_item.favorites .c2_item_photo_fav svg {
	stroke: #6F0267;
}

.c4_item.favorites.liked .c2_item_photo_fav svg{
	stroke: #fff;
}

.c4_item_photo_wrap:after {
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	background-color: #E0E0E0;
	border-radius: 100%;
	border: 2px solid #fff;
	position: absolute;
	right: 0;
	bottom: 0px;
}

.online .c4_item_photo_wrap:after {
	background-color: #27AE60;
}

.message .c4_item_photo_wrap:after {
	border-color: #7878B4;
}

.message.favorites .c4_item_photo_wrap:after {
	border-color: #6F0267;
}

.message.read .c4_item_photo_wrap:after{
	border-color: #fff;
}

.c4_item_photo {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	display: block;
}

.c4_item_big_photo {
	display: none;
	position: fixed;
	width: 320px;
	height: 100%;
	padding: 16px 18px;
	background-color: #7878B4;
	border-radius: 8px 0px 0px 8px;
	z-index: 10;
}

.message.favorites .c4_item_big_photo {
	background-color: #6F0267;
}

.c4_item_big_photo img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.c4_item_name {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: $color3;
}

.message .c4_item_name {
	color: #fff;
}

.c4_item.message.read .c4_item_name{
	color: #333;
}

.c4_item_text {
	cursor: pointer;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 150%;
	color: #333333;
	margin-top: 10px;
	height: 38px;
	padding-right: 40px;
}

.message .c4_item_text {
	color: #fff;
	-webkit-line-clamp: 2;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	height: 38px;
	display: -webkit-box;
	width: 100%;
	font-weight: normal;
	padding-right: 40px;
	word-break: break-all;
}

.c4_item.message.read .c4_item_text{
	color: #333333;
}

.liked .c4_item_text {
	padding-left: 18px;
	position: relative;
}

.liked .c4_item_text:before {
	content: '';
	display: block;
	width: 12px;
	height: 11px;
	background-image: url(../img/c4-like.svg);
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	top: 3px;
}

.viewed .c4_item_text {
	padding-left: 20px;
	position: relative;
}

.viewed .c4_item_text:before {
	content: '';
	display: block;
	width: 14px;
	height: 10px;
	background-image: url(../img/c4-viwed.svg);
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	top: 4px;
}

.c4_item_clear {
	position: absolute;
	top: 7px;
	right: 7px;
	cursor: pointer;
	padding: 5px;
}

.c4_item_clear img {
	display: block;
}

.c4_item_time {
	position: absolute;
	right: 11px;
	bottom: 8px;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.03em;
	text-transform: uppercase;
	color: rgba(51, 51, 51, 0.6);
}

.message .c4_item_time {
	color: #fff;
}

.c4_id_small {
	display: none;
	font-size: 10px;
	line-height: 12px;
	color: #BDBDBD;
	margin-top: 10px;
}

.c4_item_reply {
	position: absolute;
	right: 0;
	top: 32px;
	cursor: pointer;
}

.c4_support {
	z-index: 999;
	position: absolute;
	bottom: 8px;
	right: -84px;
	background-color: #F7F8FD;
	width: 140px;
	height: 52px;
	-webkit-box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	border-radius: 5px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	cursor: pointer;
}

.c4_support:hover {
	right: 0;
}

.c4_support img {
	margin: 0 16px;
}

.c4_support span {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #720066;
}

.c3_head {
	height: 72px;
	background-color: #446077;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 24px 0 24px;
}

.c3_head_left {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.c3_head_photo_wrap {
	margin-right: 3px;
	display: block;
	height: 65px;
	width: 75px;
	padding-top: 10px;
	cursor: pointer;
}

.c3_head_left_revers .c3_head_photo_wrap {
	margin-right: 0;
	margin-left: 3px;
}

.c3_head_photo {
	position: relative;
}

.c3_head_photo:hover {
	background-color: #fff;
	position: relative;
	padding-bottom: 10px;
	margin-top: -10px;
	padding-top: 10px;
	-webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	z-index: 1;
}

.c3_head_photo_bottom {
	display: none;
	font-weight: 500;
	font-size: 11px;
	line-height: 118%;
	color: #02317C;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 12px;
}

.c3_head_photo:hover .c3_head_photo_bottom {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.c3_head_photo_bottom img {
	width: 16px;
	margin-right: 6px;
}

.c3_head_photo > img {
	width: 40px;
	height: 40px;
	display: block;
	border-radius: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	margin: auto;
}

.c3_head_photo:after {
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	background-color: #E0E0E0;
	border-radius: 100%;
	border: 2px solid #446077;
	position: absolute;
	right: 15px;
	top: 26px;
}

.c3_head_photo:hover:after {
	top: 36px;
	border-color: #fff;
}

.c3_head_photo_wrap.online .c3_head_photo:after {
	background-color: #27AE60;
}

.c3_head_info {
	display: grid;
	grid-template-areas: "name name" "id place";
}

.c3_head_left_revers .c3_head_info {
	grid-template-areas: "name name" "place id";
}

.c3_head_name {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.01em;
	color: #FFFFFF;
	margin-bottom: 4px;
	grid-area: name;
}

.liked .c3_head_name:after {
	content: '';
	display: inline-block;
	width: 11px;
	height: 9px;
	background-image: url(../img/c3-like.svg);
	margin-left: 11px;
	position: relative;
	top: -2px;
}

.c3_head_name_bottom {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.c3_head_id {
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	line-height: 12px;
	color: #BDBDBD;
	grid-area: id;
}

.c3_head_place {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 11px;
	grid-area: place;
}

.c3_head_place img {
	height: 12px;
	display: block;
	margin-right: 4px;
}

.c3_head_place span {
	line-height: 1;
	font-size: 12px;
	letter-spacing: -0.03em;
	color: #BDBDBD;
}

.c3_head_right {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.c3_head_btn {
	cursor: pointer;
	margin-left: 24px;
	background-repeat: no-repeat;
	background-position: center;
	width: 22px;
	height: 22px;
}

.c3_head_btn.favs {
	background-image: url(../img/c3-top-star.svg);
}

.c3_head_btn.favs.active {
	background-image: url(../img/c3-top-favs2.svg);
}

.c3_head_btn.letter {
	background-image: url(../img/c3-top-mail.svg);
}

.c3_head_btn.folder_1 {
	background-image: url(../img/c3-top-folder.svg);
}

.c3_head_btn.folder_1.active {
	background-image: url(../img/c3-top-folder-active.svg);
}

.c3_head_btn.folder_2 {
	background-image: url(../img/c3-top-folder-two.svg);
}

.c3_head_btn.folder_2.active {
	background-image: url(../img/c3-top-folder-two-active.svg);
}

.c3_head_btn.folder_3 {
	background-image: url(../img/c3-top-block2.svg);
}

.c3_head_btn.block2 {
	background-image: url(../img/block_list_active_blue_bg.svg);
}

.c3_head_btn.block {
	background-image: url(../img/c3-top-black.svg);
}

.c3_head_mob_show_btn {
	display: none;
}

.c3_head_btn:nth-child(2) {
	margin-left: 0;
}

.c3_head_btn.ring_not {
	padding: 7px;
	background-color: #fff;
	border-radius: 8px;
	position: relative;
	display: none;
	-webkit-box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
}

.c3_head_btn.ring_not img {
	width: 30px;
}

.c3_head_btn img {
	display: block;
}

.c3_head_btn.chat img{
	height: 100%;
}

.c3_head_btn.like {
	border: 1px solid #CDD3DA;
	border-radius: 5px;
	width: 27px;
	height: 27px;
	text-align: center;
	line-height: 32px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-right: 6px;
}

.c3_head_btn.wink {
	border: 1px solid #CDD3DA;
	border-radius: 5px;
	width: 27px;
	height: 27px;
	text-align: center;
	line-height: 32px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-right: 6px;
	margin-left: 0;
}

.c3_options {
	height: 36px;
	background-color: #fff;
	-webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0 36px;
	position: relative;
}

.c3_options_item {
	line-height: 1;
	font-weight: 500;
	font-size: 14px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	letter-spacing: 0.01em;
	color: $color3;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	cursor: pointer;
}

.c3_options_item img {
	display: block;
	margin-right: 18px;
	height: 16px;
}

.c3_options_item.webcam {
	position: relative;
	padding-right: 34px;
}

.c3_options_item.webcam:after {
	content: '';
	display: block;
	width: 12px;
	height: 8px;
	position: absolute;
	right: 0;
	top: calc(50% - 3px);
	background-image: url(../img/bottom-grey.svg);
	background-size: contain;
	background-repeat: no-repeat;
}

.c3_chat_wrap {
	height: calc(100vh - 108px);
	height: calc(calc(var(--vh, 1vh) * 100) - 108px);
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.c3_chat_scroll {
	height: calc(100vh - 306px);
	height: calc(calc(var(--vh, 1vh) * 100) - 306px);
	overflow-y: auto;
	margin-right: 10px;
}

.c3_chat_scroll.blocked {
	height: calc(100vh);
	height: calc(var(--vh, 1vh) * 100);
}

.c3_chat_wrap .c3_chat_scroll::-webkit-scrollbar {
	width: 3px;
	background: #F4F4F4;
	border-radius: 5px;
	margin-right: 3px;
}

.c3_chat_wrap .c3_chat_scroll::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 7px;
}

.c3_chat {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 100%;
	padding: 20px 26px;
}

.chat_message_wrap {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
}

.chat_message_wrap.right {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.chat_message {
	font-family: "Roboto", sans-serif;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: flex-end;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.message_avatar {
	margin-bottom: 15px;
}

.message_avatar img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.message_text {
	font-size: 16px;
	line-height: 140%;
	color: #333333;
	padding: 16px;
	margin-bottom: 5px;
	word-break: break-all;
}

.message_text audio,
.message_text video {
	max-width: 100% !important;
}

.message_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.message_date {
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.03em;
	/*text-transform: uppercase;*/
	color: #BDBDBD;
}

.chat_message_wrap.left .message_content {
	margin-left: 16px;
	margin-right: 56px;
}

.chat_message_wrap.left .message_text {
	background-color: #F3F4FC;
}

.chat_message_wrap.right .message_content {
	margin-left: 56px;
	margin-right: 16px;
}

.chat_message_wrap.right .message_text {
	background-color: rgba(210, 223, 245, 0.9);
}

.chat_message_wrap.sticker .message_text {
	background-color: transparent;
	padding: 0;
}

.chat_message_wrap.sticker .message_text img {
	width: 350px;
	height: 350px;
	-o-object-fit: contain;
	object-fit: contain;
}

.chat_message_wrap.right .wink.message_text,
.chat_message_wrap.left .wink.message_text {
	background-color: transparent;
	padding: 0;
}

.wink.message_text img {
}

.message_status {
	width: 19px;
	height: 12px;
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
}

.message_status.send {
	background-image: url(../img/send.svg);
}

.message_status.readed {
	background-image: url(../img/readed.svg);
}

.message_img {
	display: block;
	width: 200px;
	height: 200px;
	-o-object-fit: cover;
	object-fit: cover;
	margin-top: 16px;
}

.message_img:first-child {
	margin-top: 0;
}

.message_reply_text {
	font-style: italic;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	padding-left: 8px;
	border-left: 2px solid #C2CFE0;
	width: 200px;
	min-width: 100%;
	height: 26px;
	overflow: hidden;
	-ms-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-bottom: 10px;
}

.message_video_wrap span.need_pay {
	font-size: 11px;
	line-height: 130%;
	color: rgba(51, 51, 51, 0.3);
	margin-bottom: 7px;
	display: block;

}

.message_video {
	width: 200px;
	height: 113px;
	position: relative;
	cursor: pointer;
}

.message_video img {
	width: 100%;
	height: 100%;
	margin-top: 0;
	-o-object-fit: cover;
	object-fit: cover;
}

.message_video:after {
	content: '';
	display: block;
	width: 51px;
	height: 52px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: url(../img/play-btn.svg);
	-webkit-background-size: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
}

.message_video_status {
	margin-top: 6px;
}

.message_video_status.paid {
	font-weight: 500;
	font-size: 11px;
	line-height: 130%;
	color: #426076;
	text-align: right;
}

.message_video_status.viewed {
	text-align: right;
	font-size: 11px;
	line-height: 130%;
	color: rgba(51, 51, 51, 0.6);
	position: relative;
}

.message_video_status.viewed span:before {
	content: '';
	display: inline-block;
	width: 14px;
	height: 9px;
	background-image: url(../img/video-viewed.svg);
	background-repeat: no-repeat;
	margin-right: 8px;
	margin-bottom: -1px;
}

.chat_new_line_wrap {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
}

.chat_new_line {
	height: 1px;
	background-color: #C2CFE0;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.chat_new_text {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: rgba(235, 87, 87, 0.72);
	margin: 0 16px;
}

.c3_write_msg {
	height: 267px;
	background-color: #fff;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 24px;
	border-top: 2px solid #F3F4FC;
}

.c3_write_msg_left {
	background-color: #F7F8FD;
	width: calc(100% - 195px);
}

.c3_write_msg_change_col {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	width: 50%;
	padding-top: 15px;
	padding-bottom: 15px;
	height: 51px;
}

.c3_write_msg_change_col input[type=checkbox] {
	display: none;
}

.c3_write_msg_change_col label {
	display: block;
	width: 38px;
	height: 18px;
	border-radius: 19px;
	background-color: #CDD3DA;
	position: relative;
	margin-right: 26px;
}

.c3_write_msg_change_col input:checked + label {
	background-color: #fff;
}

.c3_write_msg_change_col label:after {
	content: '';
	display: block;
	width: 14px;
	height: 14px;
	background-color: #fff;
	position: absolute;
	left: 2px;
	top: 2px;
	border-radius: 100%;
	transition: .3s;
}

.c3_write_msg_change_col input:checked + label:after {
	left: 22px;
	background-color: #3664A1;
}

.c3_select_language {
	width: 168px;
	height: 36px;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: rgba(55, 78, 108, 0.56);
	padding: 7px 16px;
	background-color: #fff;
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	display: none;
}

.translate_from {
	display: none;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	text-transform: lowercase;
	color: #0B6BA7;
	margin-right: 8px;
}

.c3_write_msg_change_translate {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	margin: 0 17px;
	border-bottom: 2px solid #F3F4FC;
	padding-bottom: 15px;
}

.c3_write_msg_textareas {
	height: calc(100% - 68px);
}

.c3_write_msg_textareas textarea {
	font-family: "Roboto", sans-serif;
	background-color: #fff;
	border: none;
	width: 100%;
	height: 100%;
	resize: none;
	outline: none;
	padding: 10px 17px;
	font-size: 16px;
	color: #4F4F4F;
}

.c3_write_msg_textareas textarea::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	border-radius: 5px;
}

.c3_write_msg_textareas textarea::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 7px;
}

.column-3 .c3_write_msg_textareas textarea {
	background-color: transparent;
}

.c3_write_msg_textareas.active textarea {
	width: 50%;
}

.c3_write_msg_textareas.active {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;

}

.c3_write_msg_textareas textarea:nth-child(2) {
	display: none;
}

.c3_write_msg_textareas.active textarea:nth-child(2) {
	display: block;
}

.c3_write_msg_ico_item.c3_sticker {
	/*display: none;*/
	cursor: default;
}

.c3_write_msg_ico_item.c3_sticker .c3_smile_btn_wrap{
	cursor: pointer;
}

.c3_sticker_wrap{
	position: relative;
}

.rhap_volume-controls{
	width: max-content;
	right: 15px;
}

.rhap_button-clear{
	margin-right: 0;
}

.rhap_volume-container{
	flex: none;
	width: max-content;
}

.rhap_volume-container:hover .rhap_volume-bar-area{
	display: none;
}
.c3_sticker_wrap.active:before{
	content: '';
	width: 72px;
	height: calc(100% + 9px);
	position: absolute;
	right: -6px;
	top: -6px;
	background-color: #F2F2F2;
	z-index: 2;
	border-radius: 0 6px 6px 0;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3_sticker_wrap.active:after{
	content: '';
	width: 13px;
	height: 13px;
	position: absolute;
	left: -20px;
	bottom: calc(100% + 6px);
	/*background-image: url(../../img/sticker-corner.svg);*/
}

.c3_stickers_list_wrap{
	position: absolute;
	bottom: 2px;
	right: calc(100% + 0px);
	width: max-content;
	background-color: #F2F2F2;
	padding: 0 9px 6px 16px;
	z-index: 1;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 8px 8px 0 8px;
	text-align: center;
	display: none;
  overflow-y: hidden;
}

.c3_stickers_list_wrap.active{
	display: block;
}

.c3_stickers_list_rows_wrap{
	padding-right: 7px;
}

.c3_stickers_list_rows_wrap.hide{
	display: none;
}

.c3_stickers_list_open.active{
	display: block;

}

.c3_stickers_list_open{
	display: none;
  background: #f2f2f2;
  padding: 0 6px 6px;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

}


.c3_stickers_list_row + .c3_stickers_list_row{
	margin-top: 23px;
}



.c3_stickers_list_head{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	//padding-top: 16px;
}
.c3_stickers_list_head_title{
	font-weight: 600;
	color: #446077;
}

.c3_stickers_list_head_price{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.3);
}

.c3_stickers_list{
	display: flex;
	align-items: center;
}

.c3_stickers_list_item{
	width: 94px;
	height: 94px;
	//margin-right: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
}

.c3_stickers_list_item:hover{
	background-color: #D3DAFF;
}

.c3_stickers_list_item img{
	width: 90px;
	height: 90px;
	object-fit: contain;
	display: block;
}

.c3_stickers_list_open_head{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 42px;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	margin-right: 7px;
	padding-top: 3px;
}

.c3_stickers_list_open_back_btn{
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 11px;
	color: #6F0267;
	cursor: pointer;
}

.c3_stickers_list_open_back_btn img{
	margin-right: 9px;
}

.c3_stickers_list_open_title{
	font-weight: 600;
	font-size: 16px;
	color: #446077;
}

.c3_stickers_list_open_price{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	text-align: right;
	color: rgba(51, 51, 51, 0.3);
	width: 76px;
}

.c3_stickers_list_open_list{
	display: grid;
  	grid-template-columns: repeat(4, 1fr);
	align-items: center;
  	grid-column-gap: 6px;
  	grid-row-gap: 6px;
	//flex-wrap: wrap;
	margin-top: 10px;
	width: 100%;
	height: 294px;
	overflow-y: auto;
	margin-bottom: 12px;
}

.c3_stickers_list_open_list::-webkit-scrollbar {
	width: 2px;
	background: #fff;
	border-radius: 3px;
}

.c3_stickers_list_open_list::-webkit-scrollbar-thumb {
	background: #00317B;
	border-radius: 3px;
}

.c3_stickers_list_all_btn{
	width: 49px;
	height: 29px;
	line-height: 29px;
	border: 1px solid #446077;
	border-radius: 6px;
	font-weight: 600;
	font-size: 12px;
	color: #446077;
	margin-left: 2px;
	cursor: pointer;
}

.c3_write_msg_ico_item.c3_sticker.active{
	background-color: #F4F4F4;
}

.c3_write_msg_ico_item {
	height: 43px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	padding: 0 5px;
	position: relative;
	cursor: pointer;
}

.c3_write_msg_ico_item.active {
	background-color: #F4F4F4;
}

.c3_write_msg_middle {
	margin: 0 24px 0 30px;
}

.translate_title {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: #374E6C;
}

.c3_smiles_wrap,
.c3_sticker_wrap {
	display: none;
	position: absolute;
	right: 100%;
	bottom: 0%;
	padding: 10px;
	background-color: #F4F4F4;
}

.c3_smiles_wrap.active,
.c3_sticker_wrap.active {
	display: block;
}


.c3_sticker_window_wrap img {
	width: 345px;
}

.c3_header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1376px;
	width:100%;
	height: 100px;
	margin: auto;
	flex: none;
	padding: 0 24px;
}
.header-menu:first-child{
	/* margin-left:20px; */
}
.header-menu-element{
	/* margin-right: 1.85%; */
}
/* .c3_header div{
	margin-right:1.85%;
} */
.header-menu{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.header-menu button{
}
.header-menu-btn.active{
	background: #E3ECFC;
}
.header-menu__img{
	background: linear-gradient(90.13deg, #00317B 0%, #720066 100%);
	-webkit-background-clip: text;
	color: transparent;
	width: 36px;
}
.header-menu-element{
	margin-right: 16px;
}
.header-menu-element:last-child{
	margin-right: 0;
}
.header-menu-btn{
	background: #FFFFFF;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	height: 76px;
	padding: 0 15px;
	/* margin-right:30px; */
}
.header-menu-item{
	display:flex;
	justify-content: center;
	align-content: center;
	align-items: center;

}
.header-menu-text{
	font-family: Montserrat;
	font-weight: 600;
	font-size: 16px;
	line-height: 120%;
	background: linear-gradient(90.29deg, #00317B 0%, #720066 100%);
	-webkit-background-clip: text;
	color: transparent;
	margin-left: 17px;
}
.header-menu-btn.active .header-menu-text{
	color: #00317B;
}
.header-prof{
	font-size:16px;
	margin-left:11px;
	width:65px;
}
.header-sup{
	font-size:18px;
	margin-left:17px;
}
.header-gif{
	font-size:16px;
	margin-left:11px;
}

.c3ph_img{
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px;
}

.header-menu-text.text-nowrap{
	white-space: nowrap;
}

.main-content{
	background: #FFFFFF;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	min-height: 723px;
	margin-bottom: 24px;
}

.content-photo{
	width: 35%;
	max-width: 450px;
	height: 300px;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 5px;
	margin:24px 35px 24px 24px;
	background-position: center;
	flex: none;
}

.man_profile_photo{
	max-width: 350px;
	max-height: 350px;
	margin: 24px;
	display: block;
}

.content-info{
	padding-top: 24px;
	flex: 1;
	padding-right: 37px;
}
.content-info__credits{
	width: 216px;
	height: 109px;
	background: #EBEDFA;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.content-info-header{
	display:flex;
	justify-content: space-between;
	flex: 1;
}

.content-info-about{
	width: calc(100% - 48px);
	display: flex;
	padding: 24px 0;
	margin: 0 24px;
	border-top: 1px solid #e0e2eb;
	justify-content: space-between;
}

.m_profile_flex_wrap{
	display: flex;
}

.info__name{
	font-family: Montserrat;
	font-weight: 600;
	font-size: 36px;
	line-height: 140%;
	color: #00317B;
	padding-right: 10px;

	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}
.info-firstline{
	display: flex;
	align-items: center;
}
.info__id{
	font-family: Montserrat;
	display: block;
	font-size: 18px;
	line-height: 22px;
	margin-top: 16px;
	letter-spacing: 0.06em;

}
.credits{
	display:flex;
	margin:0px 34px 0px 0px;
}
.credits-name{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	/* or 28px */


	color: #333333;
}
.credits-count{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.06em;

	color: #720066;
}
.credits-link{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	/* or 22px */

	text-decoration-line: underline;

	/* 3DB6B6 */

	color: #00317B;
	/* margin-top:7px; */
}
.credits-link-item{
	margin-top: 7px;
}

.date-birthday{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	height: 11px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: #446077;
}
.date-birthday-till{
	height: 11px;
	color: #446077;
	padding-left: 5px;
	font-family: Montserrat;
	font-size: 16px;
	line-height: 22px;
}
.info-date{
	margin-top:25.5px;
	/* height: 11px; */
}
.info-place{
	display:flex;
	margin-top:25px;

}
.place-info{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	color: #446077;
}

.man_profile_email{
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.06em;
	color: #00317B;
	margin-top: 33px;
}

.man_profile_email span{
	font-weight: normal;
}

.place-flag{
	padding-right:9px;
	width:40px;
	height: auto;
}
.pointer-cursor{
	cursor: pointer;
}

.ui-disabled-icon {
	pointer-events: none;
	opacity: 0.6;
}

.main-content-about{
	background: #FFFFFF;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
}

.main-content-header-row-flex{
	display: flex;
	width: 100%;
	align-items: center;
	position: relative;
}

.main-content-header{
	display:flex;
	flex-wrap: wrap;
}

.list-header{
	height: 18px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	margin-bottom: 20px;
	color: #446077;
}
.list-item{
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	margin-bottom: 5px;
	color: #00317B;
	list-style: none;
}
.list-item-header{
	margin-right: 5px;
}
.list-item-value{
	color:#00317B;;
	font-weight: 600;
}
.list-item-value-danger{
	color:#EB5757;
	font-weight: 600;
}
.list{
	margin-left:20px;
}
.info-about-parts{
	display:flex;
	width: max-content;
}
.info-about-parts:last-child{
	/* margin-right:50px; */
}

.info-about-block{
}

.block-2{
	width: calc(100% / 3);
}
.social-info{
	width: calc(100% / 3);
}
.about-profile-text{
	display:flex;
	justify-content: space-between;
	margin-bottom: 65px;
}
.about-content-container{
	padding: 34px 24px 30px 24px;
}
.text-node{
	width: calc(50% - 17px);
}
.node-header{
	height: 22px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.06em;
	text-transform: uppercase;

	color: #446077;
}
.node-header:focus{
	color:red;
}
.node-header__section{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;

	color: #6F0267;
	margin-top: 24px;
	margin-bottom: 20px;
}

.node-about{
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	text-align: justify;

	color: #4F4F4F;
	margin-top: 18px;
}
.text-elem{
	display:flex;
	flex-direction:column;
	width: calc(100% / 3);
	padding-right: 20px;
}
.text-elem:first-child{
}
.text-elem:last-child{
}
.list-of-lists{
	display:flex;

	margin-bottom:50px;
}
.content-slider{
	overflow-x: auto;
	overflow-y:hidden;
	margin-top:28px;
	padding-bottom: 20px;
}
.content-slider::-webkit-scrollbar{
	background-color:blue;
	height: 5px;
	left: 1532px;
	top: 1955px;

	background: #E0E2EB;
	border-radius: 22px;
}
.content-slider::-webkit-scrollbar-thumb{
	background-color: blue;
	width:188px;
	background: #00317B;
	border-radius: 9px;

}
.slider-img{
	margin-right: 18px;
	cursor: pointer;
}
.scrollbar-item{
	height:300px;
	background: #C4C4C4;
	border-radius: 10px;
}
.header-label{
	margin-left: 24px;
	margin-top: 63px;
	display:flex;
	align-items: baseline;
	width:1308px;
	justify-content: space-between;
}
.node-link{
	width: 83px;
	height: 22px;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;

	color: #69095B;
}

.bot-slider{
	display:flex;
	width:100%;
	margin-right: 24px;
	margin-left: 24px;
}

.bot-slider-item{
	background: #FFFFFF;
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	margin-top: 23px;
	margin-right: 25px;
	padding:20px;
	z-index:1;
}
.bot-slider-item::after{
	content:'';
}

.slider-item__img{
	border-radius: 5px;
	z-index: 1;
}

.arrow-btn{
	display:flex;
	justify-content: center;
}

.arrow-btn-right{
	display:block;
}
.slider-btns{
	position: absolute;
	width:100%;
	top: 50%;
	transform: translateY(-50%);
	display:flex;
	justify-content: space-between;
}

.slider-btns button{
	width: 37px;
	height: 37px;
	line-height: 37px;
	cursor: pointer;
	outline: none;
	background-color: rgba(255,255,255,.4);
}

.slider-btns button:last-child{
	transform: rotate(180deg);
}

.slider-btns .arrow-btn{
	display: inline;
	vertical-align: middle;
	line-height: 1;
	outline: none !important;
}

.slider-item__icon{
	/* background:url(/img/c3/girl-slider.jpg) */
	width:40px;
	height:40px;
	border-radius:40px;
}
.slider-item-info{
	display:flex;
	margin-top: 10px;
}
.item-info-all{
	margin-left:11px;
}



.info-name{
	height:18px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;

	color: #333333;
}
.country-town{
	color: #999999;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	height:11px;
	color: #333333;
	margin-left:5.5px;
	opacity: 0.5;
}
.place-id{
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;

	color: #333333;

	opacity: 0.5;
	margin-right:12px;
}
.country-flag{
	width:16px;
	height:12px;
}
.info-about-place{
	display:flex;
	height:14px;
	margin-top:4px;
}
.bot-slider-btn{
	position: absolute;
	width:27.4%;
	justify-content: space-between;
	display:flex;
	z-index:5;
	/* background-color: blue; */
}
.item-btns{
	display:flex;
	justify-content: center;
	align-items: center;
	/* align-content: center; */
}
.left-btn-view{
	/* background: rgba(255, 255, 255, 0.6); */
	/* margin-left: 0; */
	transform: rotate(-180deg);
	display:inherit;

}
.right-btn-view{

	transform: rotate(-180deg);
	display:inherit;
	/* border-radius: 16px 0px 0px 16px; */

}

.slider-btn-right{
	background: #F0F9FF;
	width:35px;
	height:35px;
	border-radius: 16px 0px 0px 16px;
}
.slider-btn-left{
	background: rgba(255, 255, 255, 0.6);
	border-radius: 16px 0px 0px 16px;
	width:35px;
	height:35px;
	transform: rotate(-180deg);
}
.slider-btn{
	border-radius: 16px 0px 0px 16px;
}
.img-basket{
	width:38px;
	/*height:30px;*/
	margin-left:20px;
	margin-right: 12px;
}
.img-pencil{

}
/* .bot-platform{
	position: relative;
width: 423px;
height: 704px;
background-color: red;
z-index: 0;
margin-top: -550px;
margin-left: 50px;
} */
.img-requests{
	margin-left:20px;
	margin-right: 15px;
}
.img-gift{
	/* margin-right:18px; */
}

.c2_close{
	display: none;
	width: 30px;
	height: 30px;
	background-image: url(../img/cross_white.svg);
	-webkit-background-size: 26px 26px;
	background-size: 20px 20px;
	background-position: center;
	position: absolute;
	right: -40px;
	top: 10px;
	background-repeat: no-repeat;
	cursor: pointer;
	visibility: hidden;
}

.profile_flex main{
	flex: 1;
}

.profile_man main{
	padding-right: 11px;
	overflow-x: hidden;
}

.profile_man main .container{
	/*height: calc(100vh - 100px);
	height: calc(calc(var(--vh, 1vh) * 100) - 100px);*/
	height: 100%;
	overflow-y: auto;
	padding: 0 11px 0 24px;
}

.profile_man.notif main .container{
	height: calc(100vh - 117px);
	height: calc(calc(var(--vh, 1vh) * 100) - 117px);
}

.profile_man main .container::-webkit-scrollbar{
	width: 3px;
	background: #F7F8FD;
	border-radius: 20px;
}

.profile_man main .container::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 3px;
}

.mp_slider_list{
	/* width: 3000px; */
	display: flex;
}

.mp_slider_wrap{
	width: calc(100vw - 765px);
	position: relative;
}



/****************
end profile man
************** */


.c3_send_btn {
	width: 96px;
	height: 42px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #fff;
	background: $color5;
	margin-left: 2px;
	cursor: pointer;
}

.c3_send_btn img {
	display: block;
	margin-right: 15px;
}

.c4_minichat {
	position: absolute;
	top: 0;
	right: calc(100% - 6px);
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 440px;
	background-color: #fff;
	z-index: 15;
	display: none;
	-webkit-box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
	box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
}

.c4_minichat .c3_chat_wrap {
	height: calc(100vh - 80px);
	height: calc(calc(var(--vh, 1vh) * 100) - 80px);
}

.c4_minichat .c3_chat_scroll {
	height: calc(calc(var(--vh, 1vh) * 100) - 297px);
}

.c4_minichat .c3_chat_scroll::-webkit-scrollbar {
	width: 3px;
	background: #F4F4F4;
	border-radius: 5px;
	margin-right: 3px;
}

.c4_minichat .c3_chat_scroll::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 7px;
}

.close_minichat {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.c4_minichat .c3_head {
	padding-left: 30px;
}

.c4_minichat .chat_message_wrap.sticker .message_text img {
	width: 285px;
	height: 285px;
}

.c4_minichat .c3_write_msg {
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 24px 20px 21px;
	height: calc(100% - 636px);
	flex: 1;
}

.c4_minichat .c3_textaera_wrap {
	width: 100%;
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	margin-bottom: 24px;
}

.c4_minichat .c3_smile_btn {
	margin-left: 0;
	margin-right: 24px;
}


.c4_minichat .c3_textaera_wrap textarea {
	font-family: "Roboto", sans-serif;
	background-color: #F7F8FD;
	border: none;
	width: 100%;
	height: 108px;
	resize: none;
	outline: none;
	padding: 10px 17px;
	font-size: 16px;
	color: #4F4F4F;
}

.c4_minichat_buttons {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c4_minichat_button_item {
	margin-right: 23px;
	cursor: pointer;
}

.fancybox-content {
	padding: 24px;
}

.react-tabs {
	padding: 30px;
}

.upload_popup_wrap {
	display: flex;
	width: 100%;
	max-width: 100%;
	height: 682px;
	max-height: 100%;
}
.upload_popup_wrap_create {
    height: 100%;
    position: relative;
    padding: 20px;
}

.upload_popup_wrap_create {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
}
.upload_popup_tabs_ul {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid #C2CFE0;
}

.upload_popup_tabs_ul li {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	list-style-type: none;
	cursor: pointer;
	display: block;
	height: 35px;
	font-weight: 600 !important;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: rgba(55, 78, 108, 0.56);
	font-family: "Roboto", sans-serif;
	outline: none;
	border-bottom: 2px solid transparent;
}

.upload_popup_tabs_ul li a {
	display: block;
	height: 35px;
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: rgba(55, 78, 108, 0.56);
	font-family: "Roboto", sans-serif;
	outline: none;
	border-bottom: 2px solid transparent;
}

.upload_popup_tabs_ul li.ui-tabs-active {
	border-color: #720066;
	color: #720066;
}

.upload_popup_tabs_content_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: flex-end;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 18px;
	padding-bottom: 18px;
}

.upload_popup_tabs_content_top .cost {
	font-size: 11px;
	color: rgba(51, 51, 51, 0.3);
}

.upload_popup_tabs_content_top .sort {
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.01em;
	color: #02317C;
}

.upload_popup_tabs_content_top .sort img {
	vertical-align: middle;
	margin-right: 9px;
}

.upload_popup_tabs_content_middle {
	margin-bottom: 16px;
}

.upload_popup_tabs_content_middle.photo,
.upload_popup_tabs_content_middle.video {
	display: grid;
	grid-gap: 8px;
	max-height: calc(100% - 90px);
	overflow: auto;
}

.upload_popup_tabs_content_middle.photo::-webkit-scrollbar,
.upload_popup_tabs_content_middle.video::-webkit-scrollbar{
	width: 3px;
	background: #F7F8FD;
	margin-right: 3px;
}

.upload_popup_tabs_content_middle.photo::-webkit-scrollbar-thumb,
.upload_popup_tabs_content_middle.video::-webkit-scrollbar-thumb{
	background: rgba(2, 49, 124, 0.6);
}

.upload_popup_tabs_content_middle.photo {
	grid-template-columns: repeat(auto-fill, 120px);
	grid-template-rows: max-content;
}

.upload_popup_tabs_content_middle.video {
	grid-template-columns: repeat(auto-fill, 208px);
	margin-right: -8px;
	align-items: flex-start;
}


.upload_popup_tabs_content_item {
	background-color: #F3F5FF;
}

.upload_popup_tabs_content_audio_item {
	background-color: #F3F5FF;
	height: 114px;
	padding: 16px;
	margin-bottom: 8px;
}

.upload_popup_tabs_content_item_top input {
	/*display: block;*/
	display: none;
	position: relative;
	top: 19px;
	right: -101px;
	/*z-index: 999;*/
}

.upload_popup_tabs_content_item_top label {
	position: relative;
	display: block;
	cursor: pointer;
}

.upload_popup_tabs_content_item_top label:after {
	content: '';
	display: block;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 4px;
	right: 4px;
	background-color: #fff;
	border: 1px solid #CED6E0;
}

.upload_popup_tabs_content_item_top input:checked + label:after {
	background-image: url(../img/popup-checked.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #9C6EF7;
	border-color: #9C6EF7;
}

.upload_popup_tabs_content_item_top label img {
	display: block;
	-o-object-fit: cover;
	object-fit: cover;
	object-position: top;
}

.photo .upload_popup_tabs_content_item_top label img {
	width: 120px;
	height: 120px;
}

.video .upload_popup_tabs_content_item_top label img {

	width: 100%;
	/*height: 80px;*/

}

.upload_popup_tabs_content_item_top label video{
	width: 100%;
	height: auto;
	max-height: 240px;
}

.upload_popup_tabs_content_item_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px 10px;
}

.upload_popup_tabs_content_item_bottom img {
	display: block;
	cursor: pointer;
}

.upload_popup_tabs_content_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.popup_bottom_upload_btn {
	width: 144px;
	height: 42px;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: #446077;
	border: 1px solid #446077;
	box-sizing: border-box;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	background-color: #fff;
	z-index: 2;
	margin: 0;
}

.popup_bottom_upload_btn_wrap{
	display: flex;
}

.popup_bottom_upload_btn_info{
	width: 112px;
	height: 42px;
	background-color: #DFF1FF;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.01em;
	color: #446077;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 130px;
	margin-top: -6px;
	padding-left: 19px;
	padding-right: 8px;
	text-align: center;
	opacity: 0;
	position: absolute;
	bottom: 22px;
}

.popup_bottom_upload_btn:hover + .popup_bottom_upload_btn_info{
	opacity: 1;
}

.popup_bottom_upload_btn img {
	vertical-align: middle;
	display: inline-block;
	margin-top: -5px;
	margin-right: 13px;
}

.popup_bottom_send_btn {
	width: 167px;
	height: 42px;
	background: #02317C;
	-webkit-box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	color: #fff;
	line-height: 42px;
	text-align: center;
	cursor: pointer;
}

.popup_bottom_send_btn img {
	display: inline-block;
	margin-right: 14px;
	margin-bottom: -3px;
}

.popup_time {
	font-weight: 500;
	font-size: 11px;
	text-align: center;
	color: #A3B3C9;
}

.popup_audio_item_top {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 12px;
}

.popup_audio_item_play_btn {
	margin-right: 16px;
	cursor: pointer;
}

.popup_audio_item_info {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.popup_audio_item_date {
	font-size: 12px;
	letter-spacing: -0.03em;
	color: rgba(55, 78, 108, 0.32);
}

.popup_audio_item_time {
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #C2CFE0;
}

.popup_audio_item_name {
	font-weight: 600;
	font-size: 16px;
	width: calc(100% - 50px);
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: 0.01em;
	color: rgba(55, 78, 108, 0.56);
}

.popup_audio_item_graph img {
	width: 100%;
	height: 31px;
}

.popup_audio_item_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.popup_audio_item_check input {
	display: none;
}

.popup_audio_item_check label {
	display: block;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border: 1px solid #CED6E0;
	border-radius: 4px;
	cursor: pointer;
}

.popup_audio_item_check input:checked + label {
	background-image: url(../img/popup-checked.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #9C6EF7;
	border-color: #9C6EF7;
}

.playing.upload_popup_tabs_content_audio_item {
	background-color: #C6CCF0;
}

.playing .popup_audio_item_date {
	color: #fff;
}

.playing .popup_audio_item_name {
	color: $color3;
}

.playing .popup_audio_item_time {
	color: #fff;
}

.fancybox-is-open .fancybox-bg {
	background: rgba(55, 78, 108, 0.32);
}

.add_file_popup_wrap.fancybox-content {
	padding: 0;
	padding-bottom: 16px;
}

.popup_video.fancybox-content {
	padding: 0;
}

.popup_add_file_head {
	height: 44px;
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	color: #FFFFFF;
	background-color: #00317B;
	line-height: 43px;
	margin-bottom: 20px;
}

.popup_add_file_row {
	margin: 0 20px;
	margin-bottom: 9px;
}

.popup_add_file_row span {
	font-weight: bold;
	font-size: 14px;
	text-align: center;
	color: #00317B;
}

.popup_add_file_row input[type="text"] {
	display: block;
	width: 100%;
	border: 0.5px solid rgba(0, 0, 0, 0.4);
	height: 42px;
	outline: none;
	padding: 0 11px;
	margin-top: 6px;
	font-size: 12px;
}

.popup_add_file_row input[type="text"]::-webkit-input-placeholder {
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type="text"]::-moz-placeholder {
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type="text"]:-ms-input-placeholder {
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type="text"]::-ms-input-placeholder {
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type="text"]::placeholder {
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row.oversize {
	font-weight: 600;
	font-size: 12px;
	color: #333333;
	text-align: center;
	background-color: rgba(235, 87, 87, 0.72);
	line-height: 42px;
}

.popup_add_file_row.oversize span {
	font-weight: normal;
	color: #333;
}

.popup_add_file_row .popup_bottom_upload_btn {
	margin: 0 auto;
	margin-top: 20px;
	display: block;
}

.upload_btn input[type=file] {
	display: none;
}

.popup_add_file_row{
	padding: 10px;
}
.popup_add_file_row.warning {
	background: #c77878a3;
}

.popup_add_file_row.uploaded {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.popup_uploaded_info {
	margin-left: 25px;
	font-weight: 600;
	font-size: 14px;
	color: #6F0267;
}

.popup_uploaded_info span {
	color: rgba(51, 51, 51, 0.6);
	font-weight: normal;
	font-size: 12px;
}

.popup_add_file_upload_btn {
	margin: 0 auto;
	margin-top: 20px;
	background: #02317C;
	height: 42px;
	width: 116px;
	font-weight: bold;
	font-size: 14px;
	text-align: center;
	color: #FFFFFF;
	line-height: 42px;
	cursor: pointer;
}

.fancybox-close-small svg {
	fill: #fff;
	stroke: #fff;
	//stroke-width: #fff;
	color: #fff;
}

.popup_video {
	display: none;
	max-width: 100%;
	background-color: transparent;
}

.popup_video video {
	display: block;
	margin: auto;
	max-width: 100%;
}

.fancybox-bg {
	background-color: transparent;
}

.popup_complaint {
	width: 343px;
	padding: 0;
	border-radius: 8px;
	display: inline-block;
}

.reason_label {
	display: block;
	font-size: 14px;
	color: #00317B;
	margin-top: 6px;
	margin-bottom: 6px;
}

.reason_textarea {
	display: block;
	width: 100%;
	height: 188px;
	border: 0.5px solid rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	resize: none;
	padding: 10px;
	font-family: "Roboto", sans-serif;
}

.complain_file_inp {
	display: none;
}

.complain_file_label {
	display: block;
	width: 100%;
	height: 42px;
	font-size: 14px;
	line-height: 42px;
	text-align: center;
	color: #00317B;
	border: 0.5px dashed rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	cursor: pointer;
	margin-top: 9px;
}

.complain_file_label img {
	vertical-align: middle;
	margin-right: 5px;
}

.popup_complaint .popup_add_file_row {
	margin-bottom: 20px;
}

.send_complaint_btn {
	width: 116px;
	height: 42px;
	font-weight: bold;
	font-size: 14px;
	line-height: 42px;
	text-align: center;
	color: #FFFFFF;
	background: -webkit-gradient(linear, left top, right top, from(#00317B), to(#720066));
	background: -o-linear-gradient(left, #00317B 0%, #720066 100%);
	background: linear-gradient(90deg, #00317B 0%, #720066 100%);
	border-radius: 4px;
	margin-left: auto;
	cursor: pointer;
}

.c4_top_close {
	display: none;
	width: 30px;
	height: 30px;
	background-image: url(../img/cross.svg);
	-webkit-background-size: 26px 26px;
	background-size: 20px 20px;
	background-position: center;
	position: absolute;
	right: 10px;
	top: 10px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.mob_head {
	display: none;
	padding: 10px 15px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.head_logo {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.head_logo img:nth-child(2) {
	width: 60px;
	display: block;
	margin-left: 6px;
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
}

.left_menu_btn span {
	display: block;
	background-color: #00317B;
	width: 40px;
	height: 3px;
	margin-bottom: 6px;
	border-radius: 5px;
}

.left_menu_btn span:last-child {
	margin-bottom: 0;
}

.head_empty {
	width: 44px;
	height: 44px;
}

.c1_close {
	display: none;
	width: 30px;
	height: 30px;
	background-image: url(../img/cross_white.svg);
	-webkit-background-size: 26px 26px;
	background-size: 20px 20px;
	background-position: center;
	position: absolute;
	right: -40px;
	top: 10px;
	background-repeat: no-repeat;
	cursor: pointer;
	visibility: hidden;
}

.c2_close {
	display: none;
	width: 30px;
	height: 30px;
	background-image: url(../img/cross_white.svg);
	-webkit-background-size: 26px 26px;
	background-size: 20px 20px;
	background-position: center;
	position: absolute;
	right: -40px;
	top: 10px;
	background-repeat: no-repeat;
	cursor: pointer;
	visibility: hidden;
}

.c4_notes_wrap {
	z-index: 999;
	background-color: #F7F8FD;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.c4_notes_wrap.active {
	padding-bottom: 26px;
}

.c4_notes_head {
	background-color: #F7F8FD;
	padding: 0 25px 0 24px;
	height: 56px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
}

.c4_notes_head_title {
	font-weight: 600;
	font-size: 16px;
	align-items: center;
	letter-spacing: 0.01em;
	color: #446077;
	cursor: pointer;
}

.c4_notes_head_write_btn img {
	display: block;
	cursor: pointer;
}

.c4_notes_head_control {
	width: 96px;
	background-color: #fff;
	padding: 3px 8px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	visibility: hidden;
}

.c4_notes_wrap.active .c4_notes_head_control {
	visibility: visible;
}

.c4_notes_head_control svg {
	stroke: rgba(61, 101, 153, 0.32);
	stroke-width: 1.5px;
	cursor: pointer;
}

.c4_notes_head_control svg.active {
	stroke: #720066;
}

.close_notes {
	cursor: pointer;
}

.c4_notes_wrap2 {
	height: 0;
	padding-right: 10px;
	transition: height .5s;
}

.c4_notes_wrap.active .c4_notes_wrap2 {
	height: 629px;
	margin-top: 8px;
}


.c4_notes_wrap3::-webkit-scrollbar,
.c4_notes_wrap2::-webkit-scrollbar {
	width: 3px;
	height: 3px;
	background: #fff;
}

.c4_notes_wrap3::-webkit-scrollbar-thumb,
.c4_notes_wrap2::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}

.c4_notes_wrap3 {
	height: 100%;
	padding-right: 12px;
	padding-left: 24px;
}

.c4_notes_item {
	margin-bottom: 24px;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c4_notes_item_head {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	background-color: #EBEDFA;
	min-height: 36px;
	padding: 3px 16px 3px 12px;
}

.c4_notes_item_name {
	font-weight: 500;
	font-size: 16px;
	display: flex;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: #4F4F4F;
	padding-right: 10px;
}

.c4_notes_item_head_right {
	white-space: nowrap;
}

.c4_notes_item_head_right img {
	vertical-align: middle;
	cursor: pointer;
}

.c4_notes_item_delete {
	margin-left: 9px;
}

.c4_notes_item_text {
	background-color: #fff;
	padding: 12px;
}

.c4_notes_item_text::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	margin-right: 3px;
}

.c4_notes_item_text::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}

.c4_notes_item_text {
	height: calc(100% - 36px);
	overflow-y: auto;
}

.c4_notes_item_text p {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	line-height: 150%;
	color: #4F4F4F;
	white-space: pre-line;
}

.c4_notes_wrap2.horizontal {
	overflow-x: auto;
	width: 100%;
}

.c4_notes_wrap2.horizontal .c4_notes_wrap3 {
	overflow-x: auto;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.c4_notes_wrap2.horizontal .c4_notes_item {
	width: 155px;
	height: 603px;
	overflow: hidden;
	margin-right: 10px;
}

.c4_notes_wrap2.horizontal .c4_notes_item_name {
	font-size: 12px;
}

.c4_notes_wrap2.horizontal .c4_notes_item_edit {
	width: 14px;
}

.c4_notes_wrap2.horizontal .c4_notes_item_delete {
	width: 12px;
	margin-left: 5px;
}

.c4_notes_wrap2.horizontal .c4_notes_item_head {
	padding: 3px 9px;
}

.c4_notes_wrap2.full .c4_notes_wrap3 {
	overflow-y: auto;
	height: 100%;
}

.c4_notes_wrap2.full .c4_notes_item {
	height: 100%;
}

.c4_notes_wrap2.full .c4_notes_item:last-child {
	margin-bottom: 0;
}

.c4_notes_wrap2.vertical .c4_notes_wrap3 {
	overflow-y: auto;
}

.c4_notes_head_edit_right {
	display: flex;
	align-items: center;
}

.c4_notes_head_edit_right img {
	display: block;
	cursor: pointer;
}

.c4_notes_head_close_edit {
	margin-left: 15px;
}

.c4_notes_wrap2.vertical .c4_notes_item {
	height: 220px;
	margin-right: 10px;
}

.c4_create_note_wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	background-color: #F7F8FD;
	overflow: hidden;
	transition: height .5s;
}

.c4_create_note_wrap.active {
	height: 713px;
}

.c4_notes_create_title {
	font-weight: 600;
	font-size: 16px;
	align-items: center;
	letter-spacing: 0.01em;
	color: #446077;
}

.c4_create_note {
	padding: 0 24px;
}

.c4_create_note_input {
	display: block;
	width: 100%;
	height: 53px;
	background-color: #EBEDFA;
	padding: 0 13px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.02em;
	margin-bottom: 8px;
	outline: none;
}

.c4_create_note_input::placeholder {
	color: rgba(51, 51, 51, 0.5);
}

.c4_create_note_textarea {
	font-family: "Roboto", sans-serif;
	display: block;
	width: 100%;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	height: 489px;
	border: none;
	resize: none;
	padding: 11px;
	font-size: 16px;
	line-height: 150%;
	outline: none;
	margin-bottom: 22px;
}

.c4_create_note_textarea::placeholder {
	color: rgba(51, 51, 51, 0.5);
}

.c4_create_note_textarea::-webkit-scrollbar {
	width: 3px;
	height: 3px;
	background: #fff;
}

.c4_create_note_textarea::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}

.c4_create_note_save {
	width: 127px;
	height: 42px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #fff;
	background-color: #02317C;
	margin-left: auto;
	cursor: pointer;
}

.c4_create_note_save img {
	display: block;
	margin-right: 10px;
}

.c1_top_wrap {
	position: relative;
	height: 108px;
	background-color: #EBEDFA;
}

.small .c1_top_wrap.active {
	height: 160px;
}

.c1_top_visible {
	height: 100%;
	padding: 18px;
}

.c1_top_multi {
	height: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	border: 1px solid #446077;
	position: relative;
	cursor: pointer;
}

.c1_top_multi:after {
	content: '';
	display: block;
	width: 18px;
	height: 11px;
	background-image: url(../img/c1-multi-arrow-white.svg);
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.c1_top_hidden_list_wrap {
	width: 100%;
	height: 0;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #EBEDFA;
	padding: 16px 12px 16px 16px;
	visibility: hidden;
	transition: height .5s;
}

.small .c1_top_hidden_list_wrap {
	padding: 12px 5px 12px 10px;
}

.c1_top_hidden_list_wrap.active {
	height: 455px;
	visibility: visible;
}

.c1_top_hidden_list_wrap:after {
	content: '';
	width: calc(100% - 8px);
	height: 143px;
	position: absolute;
	bottom: 8px;
	left: 0;
	pointer-events: none;
	background: linear-gradient(180deg, rgba(235, 237, 250, 0) 0%, #EBEDFA 100%);
}

.c1_top_hidden_list_wrap2 {
	height: 100%;
	overflow-y: auto;
	padding-right: 4px;
}

.c1_top_hidden_list_wrap2::-webkit-scrollbar {
	width: 3px;
	height: 3px;
	background: #fff;
}

.c1_top_hidden_list_wrap2::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}


.c1_top_list_item {
	display: grid;
	grid-template-areas: "photo id" "photo name" "photo place";
	grid-template-columns: 66px 1fr;
	margin-bottom: 15px;
}

.small .active .c1_top_list_item {
	display: block;
}

.c1_top_visible.girl .c1_top_list_item {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.small .c1_top_visible.girl .c1_top_list_item {
	display: block;
}

.small .c1_top_list_item {
	grid-template-areas: "photo photo" "name name" "id place";
}

.c1_top_list_item_photo {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	object-fit: cover;
	margin-right: 10px;
	grid-area: photo;
}

.c1_top_list_item_id {
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.6);
	grid-area: id;
}


.small .c1_top_list_item_id {
	display: inline-block;
	position: relative;
	padding-right: 8px;
}

.small .c1_top_list_item_id:after {
	content: '';
	display: block;
	width: 2px;
	height: 2px;
	border-radius: 100%;
	background-color: rgba(51, 51, 51, 0.5);
	position: absolute;
	right: 2px;
	top: 6px;
}

.c1_top_list_item_name {
	font-weight: 600;
	font-size: 14px;
	align-items: center;
	color: $color3;
	margin: 6px 0;
	grid-area: name;
}

.c1_top_list_item_place {
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.6);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	grid-area: place;
}

.small .c1_top_list_item_place {
	display: inline-block;
}

.small .c1_top_list_item_place img {
	display: none;
}

.c1_top_list_item_place img {
	height: 12px;
	margin-right: 6px;
}

.c1_top_list_item_info {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c1_top_list_item_info .c1_top_list_item_id {
	margin-right: 7px;
}

.c1_top_list_item_info .c1_top_list_item_id:after {
	right: 0;
}

.c1_top_visible.girl {
	padding: 12px 18px 14px;
}

.small .c1_top_visible.girl {
	padding: 12px 9px;
}

.c1_top_visible.girl .c1_top_multi {
	height: 20px;
	margin-top: -3px;
}

.c1_top_visible.girl .c1_top_multi img {
	width: 13px;
}

.c1_top_visible.girl .c1_top_multi:after {
	display: none;
}

.c1_top_girl {
	position: relative;
	display: none;
}

.c1_top_visible.girl .c1_top_girl {
	display: block;
}

.c1_top_girl_arrow {
	height: 18px;
	padding: 5px;
	position: absolute;
	right: 0px;
	top: 20px;
	cursor: pointer;

}

.c1_top_girl_arrow img {
	display: block;
}

.c1_bottom_menu_wrap {
	height: 82px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	box-shadow: 0px -8px 16px rgba(221, 220, 236, 0.32);
	position: relative;
}

.c1_bottom_menu_title {
	font-weight: bold;
	font-size: 24px;
	color: $color3;
	cursor: pointer;
}

.c1_bottom_menu {
	position: absolute;
	left: 0;
	bottom: 100%;
	width: 100%;
	height: 0;
	background-color: #fff;
	transition: height .3s;
	overflow: hidden;
	box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1);
}

.c1_bottom_menu.active {
	/*height: 210px;*/
	height: fit-content;
}

.c1_bottom_menu_item {
	display: block;
	border-left: 4px solid transparent;
	padding-left: 30px;
	height: 42px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: rgba(55, 78, 108, 0.56);
	cursor: pointer;
}

.small .c1_bottom_menu_item {
	padding-left: 9px;
}

.c1_bottom_menu_item.active {
	border-color: #374E6C;
	color: #374E6C;
}

.c1_bottom_menu_item:hover {
	border-color: #374E6C;
	text-decoration: underline;
}

.c1_bottom_menu_item svg {
	margin-right: 11px;
}

.c1_bottom_menu_item img{
	margin-right: 11px;
	vertical-align: middle;
}

.c1_bottom_search svg {
	fill: #C0CDDE;
}

.c1_bottom_search.active svg {
	fill: #3D6599;
}

.c1_bottom_profile svg {
	fill: #C0CDDE;
	stroke: #C0CDDE;
	stroke-width: 0.2px;
}

.c1_bottom_profile.active svg {
	fill: #3D6599;
	stroke: #3D6599;
}

.c1_bottom_tutorial svg {
	fill: #C0CDDE;
}

.c1_bottom_tutorial.active svg {
	fill: #3D6599;
}

.c1_bottom_invite svg {
	fill: #C0CDDE;
	stroke: #C0CDDE;
	stroke-width: 0.2px;
}

.c1_bottom_invite.active svg {
	fill: #3D6599;
	stroke: #3D6599;
}

.c1_bottom_block svg {
	fill: #C0CDDE;
}

.c1_bottom_block.active svg {
	fill: #3D6599;
}


.c3_head_left_revers {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	-webkit-flex-direction: row-reverse;
	-moz-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	-o-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.c3_head_left_revers .c3_head_place {
	margin-left: 0;
	margin-right: 11px;
}

.c3_head_left_revers .c3_head_id {
	margin-bottom: -2px;
}

.c3_head_left_revers .c3_head_name {
	text-align: right;
}

.left_menu_chat_list {
	display: none;
}

.emoji-mart-title-label {
	display: none;
}

.c3l_letters_list_write_top .c3_write_msg_textareas {
	height: calc(100% - 68px);
}

.c1_menu_main_item .c1_menu_item_right {
	margin-left: auto;
	font-size: 16px;
}

.alice-carousel__stage {
	width: 150px !important;
}

.popup_bottom_send_btn {
	position: absolute;
	bottom: 22px;
	right: 22px;
}

.popup_bottom_upload_btn {
}

.popup_add_file_upload_btn {
	/*position: absolute;*/
	/*bottom: 22px;*/
	/*right: 22px;*/
}

.react-tabs__tab--selected {
	border-bottom: 2px solid #720066 !important;
}

.react-tabs__tab--selected span {
	border: #720066 !important;
	color: #720066 !important;
}

.upload_popup_tabs_content_middle.audio {
	height: 450px;
	max-height: calc(100% - 60px);
	overflow: auto;
	padding-right: 10px;
}

.upload_popup_tabs_content_middle.audio::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	margin-right: 3px;
}

.upload_popup_tabs_content_middle.audio::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}

.draggable {
	margin: 3px;
}

.dragged {
	opacity: 1;
}

.placeholder {
	opacity: 0 !important;
}

.upload_popup_tabs_content_item_bottom {
	cursor: pointer;
}

.c4_edit_note_wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	background-color: #F7F8FD;
	overflow: hidden;
	transition: height .5s;
}

.c4_edit_note_wrap.active {
	height: 713px;
}

.make-emojis-large {
	font-size: 130px;
	line-height: normal;
}


.alice-carousel__prev-btn,
.alice-carousel__next-btn {
	display: inline-block;
	box-sizing: border-box;
	width: 50%;
	padding: 15px 10px;
}

.alice-carousel__prev-btn [data-area]::after {
	position: relative;
	content: "<";
	font-size: 30px;
	text-transform: capitalize;
}

.alice-carousel__next-btn [data-area]::after {
	position: relative;
	content: ">";
	font-size: 30px;
	text-transform: capitalize;
}

.alice-carousel__prev-btn-wrapper {
	position: absolute;
	top: 8px;
	left: -20px;
}

.alice-carousel__next-btn-wrapper {
	position: absolute;
	top: 8px;
	right: -20px;
}

/*TODO - remove for letter slider*/
/*.c3l_men_list_item_main_bottom {*/
/*	overflow: unset !important;*/
/*}*/

.alice-carousel {
	height: 54px;
}

.alice-carousel__stage-item {
	width: auto !important;
}

.react-tabs {
	padding: 0;
}

.column-1.letters .c1_menu_item {
	display: none;
}

.c3l_men_list_item_from_info {
	flex: none;
}

.ReactModal__Content.ReactModal__Content--after-open {
	max-width: calc(100% - 30px) !important;
	max-height: calc(100% - 30px) !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}

.upload_popup_tabs {
	height: 100%;
}

.react-tabs {
	height: 100%;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
	height: calc(100% - 90px);
}

.upload_popup_tabs_content {
	height: 100%;
}

.upload_popup_tabs_content::-webkit-scrollbar {
	width: 3px;
	background: #F7F8FD;
	margin-right: 3px;
}

.upload_popup_tabs_content::-webkit-scrollbar-thumb {
	background: rgba(2, 49, 124, 0.6);
}

.c3l_bookmark_ico {
	width: 15px;
	height: 21px;
	background-image: url(../img/c3l-bookmark-empty.svg);
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
}

.c3l_men_list_item_btn_wrap:hover .c3l_bookmark_ico {
	margin-left: 10px;
}

.c3l_bookm_btn.active .c3l_bookmark_ico {
	background-image: url(../img/c3l-bookmark-full.svg);
}

.c3_select_language img {
	position: absolute;
	right: 10px;
	top: 13px;
}

.close_one_popup {
	position: absolute;
	right: 14px;
	top: 14px;
	padding: 5px;
	cursor: pointer;
}

.popup_compose_letter .close_one_popup {
	right: 2px;
	top: 2px;
}

.c2_item_bottom_info {
	display: grid;
	grid-template-areas: "c2_bottom_name" "c2_bottom_place";
	flex-wrap: wrap;
}

.c4_minichat .c3_write_msg_left {
	width: 100%;
}

.c4_minichat .c3_write_msg_middle {
	display: flex;
	margin: 0;
}

.c4_minichat .c3_write_msg_ico_item.c3_smile {
	margin-left: 13px;
}

.c4_minichat .c3_write_msg_left {
	height: calc(100% - 60px);
}

.c4_minichat .c3_write_msg_textareas textarea {
	background-color: #F7F8FD;
}


.c4_minichat .rhap_progress-section {
	width: calc(100% - 90px);
}

.c4_minichat .message_audio_wrap {
	width: 216px;
}

.c4_minichat .chat_message_wrap.right .message_audio_wrap {
	width: 266px;
}

.c4_minichat .rhap_volume-controls {
	width: 36px;
	right: 0;
}

.c4_minichat .rhap_volume-bar-area {
	width: 100px;
	position: relative;
	top: 5px;
}

.c4_minichat .rhap_volume-container:hover .rhap_volume-bar-area {
	display: block;
}



.c1_menu_main_item_gift svg {
	margin-left: 2px;
}


.c3l_letters_list_write_top.with_files {
	display: flex;
	flex-direction: column;
}

.c3l_letters_list_write_top.with_files .c3_write_msg_textareas {
	height: 100%;
}

.c3_write_msg_textareas_files {
	padding: 10px 0;
	border-top: 1px solid rgba(51, 51, 51, 0.1);
	margin: 10px 16px 0;
}

.c3_write_msg_textareas_files .letters_attach_files_item {
	padding: 10px 0 10px 10px;
	box-sizing: content-box;
	background-color: #F4F4F4;
	position: relative;
	display: flex;
	box-shadow: 0px 3px 3px rgba(14, 4, 143, 0.05);
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_photo {
	width: 123px;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_photo img {
  width: 85px;
  max-height: 100px;
  cursor: pointer;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_video {
  width: 133px;

  .file_video_inner_wrap {
	position: relative;
	cursor: pointer;

	&:after {
	  content: '';
	  display: block;
	  width: 29px;
	  height: 29px;
	  border-radius: 100%;
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%, -50%);
	  box-shadow: 0 1px 5px rgba(47, 43, 99, 0.55);
	  background-image: url(../img/v2/letters-media-play.svg);
	  background-size: contain;
	  background-repeat: no-repeat;
	  background-position: center;
	}
  }
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_video:after {
	left: calc(50% - 14px);
	pointer-events: none;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_video img {
  width: 95px;
  max-height: 100px;
  cursor: pointer;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_audio {
	background-color: #f4f4f4;
	padding-right: 0;
	padding-left: 10px;
}

.letters_attach_files_item_audio_control {
	display: flex;
	align-items: center;
}

img.letters_attach_files_item_audio_play {
	margin-right: 16px;
}

.letters_attach_files_item_audio_info {
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.6);
}

.letters_attach_files_item_audio_info span:first-child {
	margin-right: 10px;
}

.c3_write_msg_textareas_files_close {
	width: 38px;
	height: 100%;
	flex: none;
}

//.c3_write_msg_textareas_files_close:after {
//	content: "";
//	display: block;
//	width: 10px;
//	height: 10px;
//	position: absolute;
//	top: 50%;
//	transform: translateY(-50%);
//	right: 14px;
//	background-image: url(../img/c3l-remove-attach.svg);
//}

.popup_audio_item_check {
	margin-left: 32px;
}

.popup_audio_item_delete {
	cursor: pointer;
}

.c3_message_audio_title {
	font-size: 15px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.9);
	margin-bottom: 13px;
}

.popup_add_file_close{
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 5px;
	cursor: pointer;
}

.popup_add_file_close img{
	width: 12px;
	height: 12px;
}

.popup_add_file_row .popup_bottom_upload_btn{
}

.popup_add_file_row .popup_add_file_upload_btn{
	/*right: 50%;*/
	/*transform: translateX(50%);*/
}
.btn-collection{
	display: flex;
	flex-direction: column;
}
.btn-collection > div{
	margin: 3px;
}

.attach-btn-collection{
	display: flex;
	position: relative;
}
.attach-btn-collection > div {
	position: relative;
	margin: 2px;
	top: -7px;
	right: 0;
}

.pagination{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 100%;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	padding-bottom: 5px;
}

.pagination li{
	width: 41px;
	height: 41px;
	font-family: "Roboto", sans-serif;
	color: #00317B;
	text-align: center;
	list-style: none;
}

.pagination li.active{
	border-radius: 100%;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	color: #69095B;
	outline: none;
	font-size: 18px;
}

.pagination li a{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	outline: none;
}

.pagination li.previous,
.pagination li.next{
	width: 39px;
	height: 19px;
	border-radius: 5px;
	background-image: url(../img/c3wp-pagin-disable.svg);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	font-size: 0;
}

.pagination li.next{
	transform: rotate(180deg);
	margin-left: 5px;
}

.pagination li.previous:not(.disabled),
.pagination li.next:not(.disabled){
	background-color: #69095B;
	background-image: url(../img/c3wp-pagin-active.svg);
}

.pagination li.previous:not(.disabled):hover,
.pagination li.next:not(.disabled):hover{
	box-shadow: 0px 2px 6px rgba(14, 4, 143, 0.24);
}

.pagination li.previous:not(.disabled){
	transform: rotate(180deg);
}

.pagination li.next:not(.disabled){
	transform: unset;
}

.message_text.like > img{
	margin-right: 10px;
}

.ui-state-lock {
	opacity: 0.3;
	cursor: default !important;
	pointer-events: none;
}

.c3_header_status{
	width: 173px;
	height: 76px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(51, 51, 51, 0.2);
	border-radius: 10px;
}

.c3_header_status_text{
	font-weight: 500;
	color: #333;
}

.c3_header_status_date{
	font-weight: 600;
	font-size: 18px;
	color: #27AE60;
}
.c3_header_status_date.week{
	color: #FD9A04;
}
.c3_header_status_date.month{
	color: #EB5757;
}
.c3lh_your_likes_item_profile_online {
	color: #1C9D53;
	width: 100%;
	font-size: 12px;
}
.c3lh_your_likes_item_profile_online:before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #1C9D53;
	border-radius: 100%;
	margin-right: 5px;
	vertical-align: middle;
	margin-bottom: 2px;
}
.c3lh_your_likes_item_profile_offline {
	color: #858585;
	width: 100%;
	font-size: 12px;
}
.c3lh_your_likes_item_profile_offline:before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #858585;
	border-radius: 100%;
	margin-right: 5px;
	vertical-align: middle;
	margin-bottom: 2px;
}
.display_online_on_profile_page {
	text-align: right;
	font-size: 19px;
	width: auto;
	display: block;
	position: absolute;
	right: 10px;
	top: 10px;
}
.c3lh_search_item_profile_online_status {
	width: auto;
	float: right;
}






/************************
*************************
Profile media
 ***********************
 ***********************/

@media screen and (max-width: 1899px){
	.c3_header{
		max-height: none;
	}

	/*.header-menu-btn{
		width: 120px;
		min-width: 100px;
		height: 89px;
	}

	.header-menu-item-notif{
		width: 93px;
		padding-bottom: 3px;
		line-height: 13px;
	}

	.header-menu-item{
		flex-wrap: wrap;
	}

	.header-menu-text{
		text-align: center;
		margin-top: 6px;
		display: block;
		flex: none;
		margin-left: 0;
		font-size: 14px;
	}

	.header-sup{
		margin-left: 0;
		line-height: 36px;
	}

	.header-gif{
		margin-left: 0;
		padding: 0 15px;
	}

	.header-prof{
		width: auto;
		margin-left: 0;
	}

	.header-menu__img{
		max-width: 30px;
		max-height: 30px;
		margin: 0;
	}*/

	.header-menu-btn{
		/*width: 130px;*/
	}

	.profile_man main .container{
		/*height: calc(100vh - 114px);
		height: calc(calc(var(--vh, 1vh) * 100) - 114px);*/
	}

	.profile_man.notif main .container{
		height: calc(100vh - 142px);
		height: calc(calc(var(--vh, 1vh) * 100) - 142px);
	}

	.c3ph_img{
		width: 30px;
		height: 30px;
		background-size: 24px;
		flex: none;
	}

	.info__name{
		font-size: 28px;
	}

	.info__id{
		font-size: 16px;
	}
}

@media screen and (max-width: 1749px){
	.mp_slider_wrap{
		width: calc(100vw - 675px);
	}
}

@media screen and (max-width: 1699px){
	.content-photo{
		width: 350px;
		height: 583px;
	}

	.man_profile_photo{
		max-height: 250px;
	}
}

@media screen and (max-width: 1649px){
	.mp_slider_wrap{
		width: calc(100vw - 575px);
	}
}

@media screen and (max-width: 1549px){
	.info__name{
		font-size: 24px;
	}

	.man_profile_email{
		font-size: 16px;
	}

	.info__id{
		font-size: 14px;
	}

	.info-date{
		margin-top: 15px;
	}

	.date-birthday{
		font-size: 16px;
	}

	.info-place{
		margin-top: 15px;
		align-items: center;
	}

	.place-info{
		font-size: 16px;
	}

	.place-flag{
		width: 24px;
		padding-right: 0;
		margin-right: 9px;
	}

	.list-header{
		font-size: 18px;
		margin-bottom: 15px;
	}

	.list-item{
		font-size: 14px;
	}

	.header-menu-text{
		/*font-size: 12px;*/
	}

	.c3ph_img{
		width: 22px;
		height: 22px;
		background-size: contain;
	}

	.header-menu-btn{
		/*width: 120px;
		height: 52px;*/
	}

	.header-menu-element{
		margin-right: 8px;
	}
}

@media screen and (max-width: 1499px){
	.main-content{
		height: auto;
		min-height: unset;
	}

	.info__name{
		font-size: 24px;
	}

	.man_profile_email{
		font-size: 14px;
	}

	.place-info{
		font-size: 14px;
	}

	.info-date{
		margin-top: 6px;
	}

	.info-place{
		margin-top: 10px;
	}

	.date-birthday{
		font-size: 12px;
	}

	.credits-link-item{
		font-size: 12px;
		margin-top: 0;
	}

	.content-info__credits{
		width: 147px;
		height: 77px;
	}

	.credits-count{
		font-size: 20px;
		line-height: 1;
		margin-bottom: 5px;
		margin-top: 5px;
	}

	.credits-link{
		line-height: 1;
	}

	.credits-name{
		font-size: 12px;
		line-height: 1;
		margin-top: 5px;
	}

	.content-info__credits img{
		margin-right: -10px;
		margin-top: -7px;
	}

	.info-about-parts{
		display: block;
	}

	.info-about-block{
	}

	.social-info{
		max-width: unset;
	}

	.main-content-header{
		display: block;
	}

	.content-photo{
		background-position: center;
		background-color: transparent;
		height: 285px;
		float: left;
	}

	.list-of-lists{
		flex-wrap: wrap;
	}

	.text-elem{
		width: 50%;
	}

	.list-item{
		display: block;
	}

	.content-info{
		padding-bottom: 24px;
	}

	.mp_slider_wrap{
		width: calc(100vw - 501px);
	}

	.m_profile_flex_wrap{
		display: flex;
	}

	.block-2{
		flex: none;
	}

	.node-header{
		font-size: 18px;
	}

	.mp_slider_wrap{
		width: calc(100vw - 501px);
	}
}

@media screen and (max-width: 1399px){
	.content-photo{
		width: 270px;
	}

	.man_profile_photo{
		max-width: 270px;
		max-height: 320px;
	}
}

@media screen and (max-width: 1349px){
	.header-menu-btn{
		/*width: 104px;*/
	}
}

@media screen and (max-width: 1299px){
	.node-header{
		font-size: 22px;
	}

	.info__name{
		font-size: 18px;
	}

	.info__id{
		font-size: 12px;
	}

	.content-info-about{
		flex-wrap: wrap;
	}

	.info-about-parts{
		width: 50%;
	}

	.info-about-block.block-2{
		width: 50%;
	}

	.social-info{
		width: 100%;
		margin-top: 24px;
	}
}

@media screen and (max-width: 1199px){
	.mp_slider_wrap{
		width: calc(100vw - 100px);
	}

	.profile_man main .container{
		/*height: calc(100vh - 194px);
		height: calc(calc(var(--vh, 1vh) * 100) - 194px);*/
	}
}

@media screen and (max-width: 999px){
	.node-header{
		font-size: 16px;
		height: auto;
	}

	.node-about{
		font-size: 14px;
	}

	.mp_slider_wrap {
		width: calc(100vw - 100px);
	}

	.profile_man main .container{
		/*height: calc(100vh - 193px);
		height: calc(calc(var(--vh, 1vh) * 100) - 193px);*/
	}
}

@media screen and (max-width: 999px){
	.node-header{
		font-size: 16px;
		height: auto;
	}

	.node-about{
		font-size: 14px;
	}

	.mp_slider_wrap {
		width: calc(100vw - 100px);
	}

	.profile_man main .container{
		/*height: calc(100vh - 125px);
		height: calc(calc(var(--vh, 1vh) * 100) - 125px);*/
		padding-bottom: 16px;
		padding-top: 16px;
	}

	.need_confirm_email .profile_man main .container{
		/*height: calc(100vh - 125px);
		height: calc(calc(var(--vh, 1vh) * 100) - 125px);*/
	}

	.c3_header{
		display: none;
	}
}

@media screen and (max-width: 899px){

	.column-2.likeher{
		position: absolute;
	}

	.left_menu_chat_list img{
		display: block;
	}

	.search .left_menu_chat_list{
		display: none;
	}

	.profile_header_menu_close{
		display: block;
	}

	.c3_header{
		position: fixed;
		left: -320px;
		top: 0;
		width: 320px;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		display: block;
		/*background-color: rgba(55, 78, 108, 0.32);*/
		padding: 16px 8px 16px 0;
		z-index: 999;
		transition: .3s;
	}

	.c3_header.active{
		left: 0;
	}

	.header-menu{
		display: block;
		padding-top: 0;
		padding-bottom: 0;
	}

	.header-menu-btn{
		width: 209px;
		height: 54px;
	}

	.column-3 .header-menu-item-notif{
		width: 209px;
		padding-top: 40px;
		margin-top: -40px;
	}

	.header-menu-item{
		justify-content: flex-start;
		padding-left: 20px;
	}

	.header-menu-text{
		width: 100%;
		margin-top: 0;
		margin-left: 12px;
		flex: 1;
		padding: 0;
		text-align: left;
	}

	.c3ph_img{
		width: 25px;
		height: 25px;
		background-size: contain;
	}

	.header-menu-element{
		margin-bottom: 16px;
		margin-right: 0;
	}

	.header-menu-element:last-child{
		margin-bottom: 0;
	}

	.header-menu{
		padding-left: 16px;
		height: 100%;
		overflow: auto;
		padding-right: 8px;
	}

	.header-menu::-webkit-scrollbar{
		width: 3px;
		background: #F7F8FD;
		border-radius: 5px;
		margin-right: 3px;
	}

	.header-menu::-webkit-scrollbar-thumb {
		background: #00317B;
		border-radius: 7px;
	}

	.profile_man main .container{
		/*height: calc(100vh - 96px);
		height: calc(calc(var(--vh, 1vh) * 100) - 96px);*/
	}

	.column-3 main{
		padding-top: 16px;
	}
}

@media screen and (max-width: 789px){
	.popup_likeher_search_photos_list_wrap{
		display: none;
	}

	.popup_likeher_search_photos_big_photo img{
		max-width: calc(100vw - 40px);
	}

	.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video{
		justify-content: center;
	}

	.content-info-header{
		display: block;
		border: none;
	}

	.info-firstline{
		display: block;
		height: auto;
	}

	.info-firstline span{
		/*display: block;*/
	}

	.info__id{
		margin-left: 0;
		margin-top: 0;
		font-size: 12px;
	}

	.credits{
		margin-top: 10px;
	}

	.content-photo{
		background-position: top;
		height: 350px;
		width: 250px;
		float: left;
	}

	.content-info{
		padding-left: 15px;
	}

	.main-content-header{
		display: block;
	}

	.place-info{
		font-size: 12px;
	}

	.place-flag{
		width: 19px;
	}

	.info-date{
		margin-top: 0;
	}

	.info-place{
		margin-top: 0;
	}

	.content-info-about{
		padding-left: 0px;
	}

	.node-header__section{
		font-size: 14px;
		margin-bottom: 5px;
	}

	.list-header{
		font-size: 14px;
		margin-bottom: 5px;
	}

	.list-item{
		font-size: 12px;
		margin-bottom: 6px;
	}
}

@media screen and (max-width: 599px){

	.content-info__credits img{
		margin-right: 12px ;
		display: block;
		margin-top: 0;
	}

	.make-emojis-large{
		font-size: 90px;
	}

	.left_menu_btn span{
		width: 33px;
	}
	.close_one_popup img{
		width: 10px;
		height: 10px;
	}

	.profile_man main .container{
		/*height: calc(100vh - 64px);
		height: calc(calc(var(--vh, 1vh) * 100) - 64px);*/
	}

	.rhap_progress-section{
		width: calc(100% - 90px);
	}
	.message_audio_wrap{
		width: 200px;
	}

	.chat_message_wrap.right .message_audio_wrap{
	}

	.rhap_volume-controls{
		width: 36px;
		right: 0;
	}

	.rhap_volume-bar-area{
		display: none;
	}

	.rhap_volume-container:hover .rhap_volume-bar-area{
		display: none !important;
	}

	.c4_minichat .message_audio_wrap{
		width: 205px;
	}

	.c4_minichat .chat_message_wrap.right .message_audio_wrap{
		width: 232px;
	}

	.upload_popup_tabs{
		padding: 20px 10px;
	}

	.react-tabs{
		padding: 0;
		position: relative;
	}

	.upload_popup_tabs_content_top .cost{
		width: 150px;
	}

	.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video{
		margin-right: 0;
	}

	.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video{
		height: calc(100% - 64px);
	}

	.upload_popup_tabs_content_middle.audio{
		margin-right: 0;
		height: calc(100% - 64px);
	}

	.popup_audio_item_check{
		margin-left: 12px;
	}

	.popup_audio_item_name{
		max-width: calc(100% - 30px);
	}

	.text-elem{
		width: 100%;
	}

	.about-profile-text{
		display: block;
		margin-bottom: 40px;
	}

	.node-about{
		margin-bottom: 0;
		margin-top: 8px;
	}

	.text-node{
		margin-right: 0;
		margin-bottom: 36px;
		width: 100%;
	}

	.node-header__section{
		margin-top: 0;
	}

	.profile_man main{
		padding-right: 0px;
	}

	.profile_man main .container{
		padding-left: 0px;
		padding-right: 0;
		//margin-right: 8;
	}

	.main-content{
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		margin-bottom: 0;
		box-shadow: none;
	}

	.date-birthday-till{
		font-size: 12px;
	}

	.main-content-about{
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		box-shadow: none;
	}

	.about-content-container{
		padding-top: 0;
		padding-right: 10px;
		padding-left: 15px;
	}

	.content-photo{
		margin-left: 15px;
		margin-right: 15px;
		width: calc(100% - 30px);
		float: none;
		background-size: contain;
		background-position: center;
		margin: 0 15px 24px;
		height: 250px;
		max-width: unset;
	}

	.info-place{
		align-items: flex-start;
	}

	.place-info{
		line-height: 1;
	}

	.content-info{
		padding-right: 10px;
		padding-left: 15px;
		padding-top: 0;
	}

	.content-info__credits{
		width: 100%;
	}

	.mp_slider_wrap {
		width: calc(100vw - 30px);
	}

	.scrollbar-item{
		height: 150px;
		object-fit: contain;
	}

	.profile_page_main_div{
		background-color: #fff;
	}

	.info-firstline span{
		margin-bottom: 6px;
	}

	.info-date{
		margin-bottom: 11px;
	}

	.credits{
		margin-top: 15px;
		width: 100%;
	}

	.main-content-header-row-flex{
		display: block;
		padding-left: 16px;
	}

	.content-info-about{
		margin: 0 16px;
		width: calc(100% - 32px);
	}

	.info-about-parts{
		width: 100%;
	}

	.info-about-block.block-2{
		width: 100%;
		margin-top: 16px;
	}

	.man_profile_photo{
		width: 95%;
		height: auto;
		margin: 0 auto;
	}

	.man_profile_photo_wrap{
		padding: 16px 0;
		padding-right: 10px;
	}

	.display_online_on_profile_page{
		position: static;
		text-align: left;
		margin-top: 10px;
		font-size: 12px;
	}

	.social-info{
		margin-top: 16px;
	}

	.pagination li{
		width: 34px;
		height: 34px;
	}

	.pagination li.previous,
	.pagination li.next{
		width: 30px;
	}

	.pagination li.break-me{
		width: max-content;
	}
}

