@import "@sitestyles";

.disabled{
	opacity: 0.4;
	pointer-events: none;
}
.padding_right_10_px{
	padding-right: 10px;
}
.profile_link{
	cursor: pointer;
	text-decoration: underline;
	color: $color1;
	font-weight: bold;
}
