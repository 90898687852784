@import "@sitestyles";

.clmn_3_chat_message_hide{
	display: none;
}

.clmn_3_chat_message {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-bottom: 17px;
}

.clmn_3_chat_message.left{
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.clmn_3_chat_message_avatar_wrap{
	margin-top: auto;
	margin-bottom: 19px;
}

.clmn_3_chat_message.right .clmn_3_chat_message_avatar_wrap{
	margin-left: 10px;
}

.clmn_3_chat_message.left .clmn_3_chat_message_avatar_wrap{
	margin-right: 10px;
}

.clmn_3_chat_message_avatar{
	display: block;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	object-fit: cover;
}

.clmn_3_chat_message:last-child {
	margin-bottom: 0;
}

.clmn_3_chat_message.right {
	justify-content: flex-end;
}

.message_audio_wrap {
    width: 300px;
}

.message_text {
    font-size: 16px;
    line-height: 140%;
    color: #333;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 5px;
    word-break: break-word;
}

.clmn_3_chat_message.left .message_text {
    background-color: #f3f4fc;
    border-radius: 8px 8px 8px 0;
}

.clmn_3_chat_message.right .message_text {
    background-color: rgba(210,223,245,.9);
    border-radius: 8px 8px 0 8px;
}

.clmn_3_chat_message_content {

}

.clmn_3_chat_message_content .message_text.like img {
	margin-right: 10px;
}

.clmn_3_chat_message_content.sticker .message_text {
    background-color: transparent;
    padding: 0;
}

.clmn_3_chat_message_content.sticker .message_text img {
    width: 285px;
    height: 285px;
    object-fit: contain;
}

.clmn_3_chat_message_text {
	font-family: "Roboto", sans-serif;
	padding: 16px;
	font-size: 16px;
	line-height: 140%;
	color: #333333;
}

.clmn_3_chat_message.right .clmn_3_chat_message_text {
	background-color: #E0EDEF;
	border-radius: 8px 8px 0px 8px;
}

.clmn_3_chat_message.left .clmn_3_chat_message_text {
	background-color: #E7EFFB;
	border-radius: 8px 8px 8px 0px;
}


.clmn_3_chat_message_info {
	margin-top: 5px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.clmn_3_chat_message_time {
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	letter-spacing: -0.03em;
	color: #BDBDBD;
}

.c3_message_audio_title {
	font-size: 14px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.9);
	margin-bottom: 10px;
}

.message_status {
	width: 12px;
	height: 9px;
	background-size: contain;
	background-repeat: no-repeat;
}

.message_hide {
	margin-left: 3px;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	letter-spacing: -0.03em;
	color: #27AE60;
	cursor: pointer;
}

.message_video {
    max-width: 200px;
    position: relative;
    cursor: pointer;
	z-index: 1;
}

.message_video video{
	width: 100%;
}

.message_video img {
	width: 100%;
	height: 100%;
	margin-top: 0;
	object-fit: cover;
}

.message_video:after {
    z-index: 5;
    content: "";
    display: block;
    width: 51px;
    height: 52px;
    position: absolute;
    top: calc(50% - 26px);
    left: calc(50% - 26px);
    background-image: url(../../../../../img/play-btn.svg);
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.clmn_2_chat_block_item_middle_time .message_status {
	margin-right: 6px;
}

.message_status.send {
	background-image: url(../../../../../img/v2/send.svg);
}

.message_status.readed {
	background-image: url(../../../../../img/v2/readed.svg);
	width: 14px;
}

@media screen and (max-width: 599px){
	.message_text{
		padding: 10px;
		font-size: 14px;
	}

	.clmn_3_chat_message_avatar{
		width: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 409px){
	.message_audio_wrap{
		width: 180px;
	}
}
