.block_page_content_table{
  flex: 1 1;
  margin: 10px -6px 0 0;
  padding-right: 4px;
  overflow-y: auto;
}

.block_page_content_table::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.block_page_content_table::-webkit-scrollbar-thumb{
  background: #96B6CF;
  border-radius: 3px;
}

.block_page_content_table_list{
  display: block;
  height: 100%;

  .item{
	display: flex;
	// margin-bottom: 10px;
	background: #fff;
	border: 1px solid #E9EEF2;
	box-sizing: border-box;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	width: 100%;
	position: relative;
	padding: 7px 10px;
	margin-bottom: 10px;

	.top{
	  display: flex;
	  align-items: center;
	  padding: 15px 18px;
	  width: 100%;

	  .left{
		width: 300px;
		display: flex;

		.operator_info{
		  font-weight: 700;
		  margin-bottom: 5px;
		}
	  }
	;

	  .middle{
		flex: 1 1;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		// padding-top: 33px;
		padding-bottom: 7px;
	  }
	;

	  .right{
		width: 229px;
		display: flex;
		justify-content: flex-end;
	  }
	}

	.item_info{
	  width: 100%;
	}

	.item_info_row{
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  padding: 10px;
	  width: 100%;
	  border-radius: 3px;

	  &:nth-child(odd){
		background-color: #e6eff0;
	  }

	  > div{
		flex: 1;
		font-size: 13px;

		&:last-child{
		  width: max-content;
		  flex: none;
		  img{
			display: block;
			width: 20px;
		  }
		}
	  }

	  .item_info_mans_close{
		cursor: pointer;
	  }
	}

	.item_info_row + .item_info_row{
	  margin-top: 5px;
	}

	.item_name{
	  margin-bottom: 5px;
	  font-weight: 600;
	}
  }
}

.item_photo{
  position: relative;
  margin-right: 15px;
  align-self: flex-start;

  img{
	width: 60px;
	height: 60px;
	border-radius: 100%;
	object-fit: cover;
	display: block;
  }
}


@media screen and (max-width: 599px){
  .block_page_content_table_list{
	.item{
	  display: block;
	  .item_photo{
		float: left;
		margin-bottom: 5px;
	  }
	  .item_name{
		margin-top: 24px;
	  }
	  .item_info_row > div{
		&:nth-child(3){
		  flex: none;
		  width: 40px;
		}
		&:last-child{
		  margin-left: 5px;
		}
	  }
	}
  }
}
