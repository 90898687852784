@import "@sitestyles";

.clmn_1_menu_list{
  -webkit-flex: 1px;
  -moz-flex: 1px;
  -ms-flex: 1px;
  -o-flex: 1px;
  flex: 1px;
  overflow-y: auto;
}

.clmn_1_menu_list::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.clmn_1_menu_list::-webkit-scrollbar-thumb{
  background: #96B6CF;
  border-radius: 3px;
}

.clmn_1_menu_item{
  height: 52px;
  padding: 0 9px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.clmn_1_menu_item.active{
  background-color: $color1;
  border-color: $color1;
}

.clmn_1_menu_item.disabled {
	opacity: .7;
	cursor: not-allowed;
}

.clmn_1_menu_item:last-child{
  margin-bottom: 0;
}

.clmn_1_menu_item_left{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.clmn_1_menu_item_img{
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 11px;
}

.clmn_1_menu_item.chat .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-chat.svg);
}

.clmn_1_menu_item.chat.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-chat-a.svg);
}

.clmn_1_menu_item.long .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-long.svg);
}

.clmn_1_menu_item.long.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-long-a.svg);
}

.clmn_1_menu_item.unanswered .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-unansw.svg);
}

.clmn_1_menu_item.unanswered.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-unansw-a.svg);
}

.clmn_1_menu_item.favorites .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-favs.svg);
}

.clmn_1_menu_item.favorites.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-favs-a.svg);
}

.clmn_1_menu_item.dialogues .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-dialogs.svg);
}

.clmn_1_menu_item.dialogues.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-dialogs-a.svg);
}

.clmn_1_menu_item.add_favs .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-add-favs.svg);
}

.clmn_1_menu_item.add_favs.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-add-favs-a.svg);
}

.clmn_1_menu_item.matches .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-matches.svg);
}

.clmn_1_menu_item.personal .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-personal.svg);
}

.clmn_1_menu_item.personal.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-personal.svg);
}

.clmn_1_menu_item.matches.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-matches-a.svg);
}

.clmn_1_menu_item.looking .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-looking.svg);
}

.clmn_1_menu_item.looking.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-looking-a.svg);
}

.clmn_1_menu_item.reconn .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-reconn.svg);
}

.clmn_1_menu_item.reconn.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-reconn-a.svg);
}

.clmn_1_menu_item.next_time .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-next-time.svg);
}

.clmn_1_menu_item.next_time.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-next-time-a.svg);
}

.clmn_1_menu_item.new_men .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-new-men.svg);
}

.clmn_1_menu_item.new_men.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-new-men-a.svg);
}

.clmn_1_menu_item.maybe .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-maybe.svg);
}

.clmn_1_menu_item.maybe.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-maybe-a.svg);
}

.clmn_1_menu_item.special .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-special.svg);
}

.clmn_1_menu_item.special.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-special-a.svg);
}

.clmn_1_menu_item.letters .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-letters.svg);
}

.clmn_1_menu_item.letters.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-letters-a.svg);
}

.clmn_1_menu_item.posts .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-posts.svg);
}

.clmn_1_menu_item.posts.active .clmn_1_menu_item_img{
  background-image: url(../../../img/v2/c1-menu/c1-posts-a.svg);
}

.clmn_1_menu_item_text{
  font-weight: 500;
  letter-spacing: -0.02em;
  color: rgba(83, 89, 115, 0.7);
}

.clmn_1_menu_item.active .clmn_1_menu_item_text{
  font-weight: 600;
  color: #fff;
}

.clmn_1_menu_item_val{
  font-weight: 500;
  color: $color1;
}

.clmn_1_menu_item.active .clmn_1_menu_item_val{
  color: #fff;
  font-size: 16px;
}

.clmn_1_menu_item_val.red{
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #EB5757;
}

.clmn_1_menu_item.active .clmn_1_menu_item_val.red{
  font-weight: 500;
}

.clmn_1_menu_item_val.green{
  color: #63B485;
  position: relative;
  padding-left: 9px;
}

.clmn_1_menu_item_val.green:before{
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #63B485;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.clmn_1_menu_item_val.bold{
  font-size: 16px;
  font-weight: bold;
}

.clmn_1_menu_item.add_favs .clmn_1_menu_item_text{
  font-size: 13px;
}

.clmn_1_matches_folders{
  margin: 20px 0;
}

.clmn_1_letters_folders{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #fff;
}

@media screen and (max-width: 1399px){

  .clmn_1_menu_item_img{
	margin-right: 0;
	transition: .5s;
  }

  :global(.clmn_1:hover) .clmn_1_menu_item_img{
	margin-right: 10px;
  }

  .clmn_1_menu_item_left{
	margin-left: auto;
	margin-right: auto;
	transition: .5s;
  }

  :global(.clmn_1:hover) .clmn_1_menu_item_left{
	margin: 0;
  }

  .clmn_1_menu_item_val{
	margin-right: auto;
  }

  .clmn_1_menu_item_text{
	width: 0;
	overflow: hidden;
	transition: .5s;
	white-space: nowrap;
  }

  :global(.clmn_1:hover) .clmn_1_menu_item_text{
	width: 95px;
  }

  .clmn_1_menu_list{
	padding-right: 3px;
  }
}
