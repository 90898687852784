@import "@sitestyles";

.clmn_4{
	width: 302px;
	padding: 10px 10px 10px 5px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	height: 100%;
}

.clmn_4_head{
	height: 54px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 18px;
	color: $color1;
}

.clmn_4_head img{
	margin-right: 13px;
}

.close_clmn_4{
	display: none;
	width: 30px;
	height: 30px;
	background-image: url(../../img/v2/cross_white.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 20px;
	position: absolute;
	right: calc(100% + 10px);
	top: 10px;
	cursor: pointer;
}

@media screen and (max-width: 1299px){
	.clmn_4{
		position: fixed;
		right: -302px;
		top: 0;
		background-color: #EEF1F5;
		transition: right .3s;
	}

	.clmn_4.active{
		right: 0;
		z-index: 7;
	}

	.clmn_4.active .close_clmn_4{
		display: block;
	}
}

@media screen and (max-width: 349px){
	.clmn_4{
		width: 270px;
	}
}
