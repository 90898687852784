@import "@sitestyles";

.edit_block{
	background-color: $color1;
	border-radius: 4px;
	position: relative;
	z-index: 2;
}

.head{
	width: 100%;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title{
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: #fff;
	flex: 1;
	margin-right: 8px;
	height: 100%;
	line-height: 40px;
	padding-left: 13px;
	cursor: pointer;
}

.close_btn{
	width: 38px;
	height: 38px;
	background-image: url(../../../../img/v2/notes-close-btn.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	margin-right: 3px;
}

.body{
	padding: 0 13px 13px;
}

.input_title{
	width: 100%;
	height: 42px;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 6px;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: #333;
	padding: 0 13px;
	margin-bottom: 7px;
	outline: none;
}

.input_title::placeholder{
	color: rgba(51, 51, 51, 0.8);
}

.input_text_wrap {
  position: relative;
  margin-bottom: 8px;

  span {
	font-size: 12px;
	letter-spacing: -0.01em;
	color: #205376;
	position: absolute;
	bottom: 10px;
	right: 10px;
  }
}

.input_text{
	font-family: "Montserrat", sans-serif;
	width: 100%;
	height: 380px;
	resize: none;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 6px;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.01em;
	color: #333;
	padding: 10px;
	outline: none;
	border: none;
}

.input_text::-webkit-scrollbar {
	width: 4px;
	background: #fff;
	border-radius: 10px;
}

.input_text::-webkit-scrollbar-thumb {
	background: #6a96b8;
	border-radius: 10px;
}

.save_btn{
	width: 121px;
	height: 41px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: $color1;
	background-color: #fff;
	border-radius: 6px;
	margin-left: auto;
	cursor: pointer;
}

.save_btn img{
	margin-right: 11px;
}
