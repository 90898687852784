@import "@sitestyles";

.c4_mute_btn{
	cursor: pointer;
}

.c4_item_text_like_title,
.c4_item_text_letter_title,
.c4_item_text_viwed_title{
	position: relative;
	padding-left: 20px;
	margin-bottom: 4px;
}

.c4_item_text_like_title:before,
.c4_item_text_letter_title:before,
.c4_item_text_viwed_title:before{
	content: '';
	display: block;
	width: 15px;
	height: 12px;
	position: absolute;
	left: 0;
	top: 1px;
	background-repeat: no-repeat;
}

.c4_item_text_like_title:before{
	background-image: url(../../../img/c4-like.svg);
}

.c4_item_text_letter_title:before{
	background-image: url(../../../img/c4-letter.svg);
}

.c4_item_text_viwed_title:before{
	background-image: url(../../../img/c4-viwed.svg);
}

.clmn_4_block {
	background-color: #fff;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	padding-bottom: 9px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
}

.clmn_4_block.paid {
	flex: 1px;
}

.clmn_4_block_head {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	height: 34px;
	padding: 0 9px;
}

.clmn_4_block_head_title {
	font-weight: bold;
	color: #535973;
}

.clmn_4_block_sorting{
	display: flex;
	align-items: center;
}

.clmn_4_block_sorting_item{
	width: 22px;
	height: 22px;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 100%;
	cursor: pointer;
}

.clmn_4_block_sorting_item.active{
	background-color: $color1;
}

.clmn_4_block_sorting_item.up{
	background-image: url(../../../img/sort-arrow-up.svg);
	margin-right: 5px;
}

.clmn_4_block_sorting_item.down{
	background-image: url(../../../img/sort-arrow-down.svg);
}

.clmn_4_block_sorting_item.up.active{
	background-image: url(../../../img/sort-arrow-up-white.svg);
}

.clmn_4_block_sorting_item.down.active{
	background-image: url(../../../img/sort-arrow-down-white.svg);
}

.clmn_4_block_head_clear {
	font-size: 12px;
	font-weight: 600;
	background: $color1;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	cursor: pointer;
}

.clmn_4_block_list {
	-webkit-flex: 1px;
	-moz-flex: 1px;
	-ms-flex: 1px;
	-o-flex: 1px;
	flex: 1px;
	overflow-y: auto;
	padding: 0 3px 0 9px;
	margin-right: 3px;
}

.clmn_4_block_list::-webkit-scrollbar {
	width: 3px;
	background: #fff;
	border-radius: 3px;
}

.clmn_4_block_list::-webkit-scrollbar-thumb {
	background: #96B6CF;
	border-radius: 3px;
}
.clmn_4_block_paid_item{
	cursor: pointer;
}
.clmn_4_block_paid_item:last-child{
	margin-bottom: 0;
}

.clmn_4_block_paid_item_man_photo_wrap{
	position: relative;
}

.clmn_4_block_paid_item_man_photo_wrap:after{
	content: '';
	display: block;
	width: 7px;
	height: 7px;
	position: absolute;
	bottom: 3px;
	right: 3px;
	border: 1px solid #fff;
	background-color: #B2B2B2;
	border-radius: 100%;
}

.clmn_4_block_paid_item_man_photo_wrap.online:after{
	background-color: #27AE60;
}

.clmn_4_block_paid_item_man_photo{
	width: 56px;
	height: 56px;
	border-radius: 100%;
	display: block;
	object-fit: cover;
}

.clmn_4_block_paid_item_man_photo_fav{
	width: 18px;
	position: absolute;
	top: -4px;
	right: -1px;
}

.clmn_4_block_paid_item_middle{
	flex: 1px;
	margin: 0 8px;
	overflow: hidden;
}

.clmn_4_block_paid_item_name{
	font-size: 13px;
	font-weight: bold;
	color: $color3;
	margin-bottom: 3px;

	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.clmn_4_block_paid_item_write_name{
	display: flex;
	align-items: center;
	margin-bottom: 3px;
	font-weight: bold;
	font-size: 14px;
	color: $color1;
}

.clmn_4_block_paid_item_write_name span{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.clmn_4_block_paid_item_write_name img{
	flex: none;
	margin-right: 8px;
}

.clmn_4_block_paid_item_write_text{
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 14px;
	color: rgba(51, 51, 51, 0.7);
	margin-top: 4px;
}

.clmn_4_block_paid_item_content{
	position: relative;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	line-height: 1.4;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	color: rgba(51, 51, 51, 0.7);
	word-break: break-all;
	cursor: pointer;
}

.clmn_4_block_paid_item_content span{
	-webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
    display: -webkit-box;
    /* width: 130px; */
    font-weight: 400;
}

.clmn_4_block_paid_item_content > img{
	height: 33px;
	width: 50px;
	object-fit: cover;
	margin-left: 8px;
	border-radius: 4px;
	display: block;
}

.clmn_4_block_paid_item_content_video{
	position: relative;
	margin-left: 6px;
}

.clmn_4_block_paid_item_content_video:after{
	content: '';
	display: block;
	width: 34px;
	height: 34px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-image: url(../../../img/v2/play-video-btn.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.clmn_4_block_paid_item_content_video_img{
	display: block;
	height: 33px;
	width: 62px;
	border-radius: 4px;
	object-fit: cover;
}

.clmn_4_block_paid_item_content_audio{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.clmn_4_block_paid_item_content_audio img:last-child{
	-webkit-flex: 1px;
	-moz-flex: 1px;
	-ms-flex: 1px;
	-o-flex: 1px;
	flex: 1px;
	margin-left: 6px;
}

.clmn_4_block_paid_item_woman_photo_wrap{
	position: relative;
	margin-bottom: 11px;
}

.clmn_4_block_paid_item_woman_photo_wrap:after{
	content: '';
	display: block;
	width: 5px;
	height: 5px;
	position: absolute;
	bottom: 1px;
	right: 1px;
	border: 1px solid #fff;
	background-color: #B2B2B2;
	border-radius: 100%;
}

.clmn_4_block_paid_item_woman_photo_wrap.online:after{
	background-color: #27AE60;
}

.clmn_4_block_paid_item_woman_photo{
	width: 38px;
	height: 38px;
	border-radius: 100%;
	display: block;
	object-fit: cover;
}

.clmn_4_block_paid_item_woman_photo_fav{
	width: 16px;
	position: absolute;
	top: -5px;
	right: -3px;
}

.clmn_4_block_paid_item_time{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.7);
}
.clmn_4_block_paid_item{
	border-radius: 5px;
	border: 1px solid rgba(113,165,172,0.6);
	padding: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}
.clmn_4_block_paid_item.write{
	border: 2px solid $color1;
	background-image: url(../../../img/v2/c4_write_attention_bg.svg);
	background-repeat: no-repeat;
	background-position: right bottom;
}
.user_avatar {
	display: inline-block;
	width: 56px;
	height: 56px;
	line-height: 56px;
	padding-top: 0px;
	border-radius: 60px;
	text-align: center;
	color: white;
	font-size: 21px;
}



@media screen and (max-width: 1749px){
	.user_avatar{
		width: 50px;
		height: 50px;
		line-height: 50px;
	}
}
