@import "@sitestyles";

.clmn_3_personal {
  flex: 1 1;
  padding: 10px 5px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 999px) {
	height: calc(100% - 60px);
	padding-bottom: 0;
  }
}

.connect_top_row {
  display: flex;
  margin-bottom: 10px;
  height: 50%;

  @media screen and (max-width: 999px) {
	display: none;
	flex-direction: column;
	height: 100%;
	margin-bottom: 0;
  }
}

.choose_man_wrap {
  height: 100%;
  flex: 1;
  margin-right: 10px;

  @media screen and (max-width: 999px) {
	height: auto;
	margin-right: 0;
	margin-bottom: 10px;
  }
}

.choose_man_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px #CFD7DF;
  border-radius: 8px;
  padding-right: 24px;
  cursor: pointer;

  & + .choose_man_item {
	margin-top: 10px;
  }

  &.active {
	background-color: $color1;

	span {
	  color: #fff;
	}

	.buttons .btn svg {
	  fill: #fff;
	}
  }

  .buttons {
	display: flex;
	align-items: center;

	.btn {
	  & + .btn {
		margin-left: 16px;
	  }

	  svg {
		fill: #2A3B53;
		display: block;
	  }
	}
  }
}

.lets_connect_wrap {
  width: 396px;
  height: 100%;

  @media screen and (max-width: 1599px) {
	width: 320px;
  }

  @media screen and (max-width: 999px) {
	width: 100%;
	height: auto;
	flex: 1;
  }
}

.empty_img {
  max-width: 80%;
  max-height: 80%;
  object-fit: cover;
  margin: auto;
  display: block;
}

.connect_users_row {
  display: flex;
  align-items: center;
  padding: 0 40px;

  @media screen and (max-width: 1599px) {
	padding: 0 20px;
  }

  @media screen and (max-width: 999px) {
	justify-content: center;
  }
}

.connect_wrap {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 3px;
		background: #fff;
		border-radius: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background: #96B6CF;
		border-radius: 3px;
	}

  @media screen and (max-width: 999px) {
	padding-top: 5px;
  }
}

.connect_user_plus {
  margin: 0 auto 30px;

  img {
	display: block;

	@media screen and (max-width: 1599px) {
	  width: 28px;
	}

	@media screen and (max-width: 999px) {
	  width: 22px;
	}
  }

  @media screen and (max-width: 999px) {
	margin-left: 10px;
	margin-right: 10px;
  }
}

.connect_user_item {

  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.75238px;
  color: #464F5C;
  max-width: 120px;
  flex: 1;
  position: relative;

  div {
	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
  }

  @media screen and (max-width: 1599px) {
	font-size: 16px;
  }

  @media screen and (max-width: 999px) {
	font-size: 12px;
  }
}

.connect_user_item_photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 8px;

  @media screen and (max-width: 1599px) {
	width: 80px;
	height: 80px;
	margin-bottom: 4px;
  }

  @media screen and (max-width: 999px) {
	width: 60px;
	height: 60px;
  }
}

.connect_user_item_cross {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -5px;
  right: 18px;
  background: white;
  border-radius: 100%;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.connect_users_less_row {
  border: 1px solid lightgrey;
  padding: 20px 5px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 30px 10px 20px;
  position: relative;

  img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
  }
}

.connect_users_less_text {
  position: absolute;
  top: -15px;
  right: 0;
  background: #1F4F74;
  color: white;
  padding: 5px;
  border-radius: 4px;
}

.connect_users_less_list {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
}

.connect_users_less_list::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.connect_users_less_list::-webkit-scrollbar-track {
  width: 0;
  background: transparent;
}

.connect_users_less_list::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: none;
}

.empty_partner_text {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: #CFD3DA;
  text-align: center;
  margin: auto;

  @media screen and (max-width: 1599px) {
	font-size: 20px;
  }
}

.connect_buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 40px;
  margin: auto 0;

  & > * {
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #979797;
	border-radius: 8px;
	color: $color1;
	cursor: pointer;

	&.active {
	  background-color: $color1;
	  border-color: $color1;
	  color: #fff;

	  svg {
		fill: #fff;
	  }
	}

	svg {
	  fill: #2A3B53;
	}
  }

  .chat_btn {
	flex: 1;

	& + .chat_btn {
	  margin-left: 10px;
	}

	svg {


	  @media screen and (max-width: 999px) {
		width: 20px;
		height: 20px;
	  }
	}

	@media screen and (max-width: 999px) {
	  height: 34px;
	}
  }

  .email_btn {
	flex: 1;
  }

  .connect_btn {
	width: 100%;
	margin-top: 10px;
	flex: none;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: -0.02em;

	@media screen and (max-width: 999px) {
	  height: 34px;
	  margin-top: 5px;
	  font-size: 12px;
	}
  }

}

.connect_wait_list {
  flex: 1;

  @media screen and (max-width: 999px) {
	display: none;
  }
}

.wait_list_item {
  display: flex;
  align-items: center;

  & + .wait_list_item {
	margin-top: 10px;
  }

  & > * {
	height: 61px;
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	box-shadow: 0 0 10px #CFD7DF;
	border-radius: 8px;

	@media screen and (max-width: 1599px) {
	  height: 50px;
	}

	@media screen and (max-width: 999px) {
	  height: auto;
	  min-height: 50px;
	  padding-top: 7px;
	  padding-bottom: 7px;
	}
  }
}

.wait_list_item_blocked {
  background: gray;
  color: white;
  border-radius: 30px;
  padding: 3px 10px;
  font-size: 10px;
  font-style: italic;
  text-align: right;
  position: absolute;
  right: 10px;
  top: 17px;
}

.wait_list_item_users {
  padding: 0 20px;
  flex: 1;
  position: relative;

  & > * {
	display: flex;
	align-items: center;
  }

  .users_photos {
	margin-right: 16px;

	img {
	  width: 50px;
	  height: 50px;
	  object-fit: cover;
	  border: 1px solid #fff;
	  border-radius: 50px;
	  position: relative;
	  display: block;

	  &:nth-child(1) {
		z-index: 2;
	  }

	  &:nth-child(2) {
		margin-left: -12px;
		z-index: 1;
	  }

	  @media screen and (max-width: 1599px) {
		width: 38px;
		height: 38px;
	  }
	}

	@media screen and (max-width: 1599px) {
	  margin-right: 12px;
	}
  }

    .creation_date {
      font-weight: 500;
      font-size: 18px;
      color: #464F5C;
      width: 26%;

      p {
        height: 23px;
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 999px) {
          max-width: 250px;
        }

        @media screen and (max-width: 695px) {
          max-width: 150px;
        }

        @media screen and (max-width: 500px) {
          max-width: 85px;
        }

        @media screen and (max-width: 370px) {
          max-width: 65px;
        }
      }

      @media screen and (max-width: 1599px) {
        font-size: 14px;
      }

      @media screen and (max-width: 999px) {
        font-size: 12px;
      }
    }

	.users_names {
		font-weight: 500;
		font-size: 18px;
		color: #464F5C;
		width: 100%;

		span {
			margin: 0 6px;
			color: #979797;
			font-size: 24px;

			@media screen and (max-width: 1599px) {
				font-size: 20px;
			}
		}

		p {
			height: 23px;
			white-space: nowrap;
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;

			@media screen and (max-width: 999px) {
				max-width: 250px;
			}

			@media screen and (max-width: 695px) {
				max-width: 150px;
			}

			@media screen and (max-width: 500px) {
				max-width: 85px;
			}

			@media screen and (max-width: 370px) {
				max-width: 65px;
			}
		}

		@media screen and (max-width: 1599px) {
			font-size: 14px;
		}

		@media screen and (max-width: 999px) {
			font-size: 12px;
		}
	}

  @media screen and (max-width: 999px) {
	padding: 7px;
  }
}

.wait_list_item_button {
  cursor: pointer;
  width: 179px;
  justify-content: center;
  margin-left: 10px;

  svg {
	fill: #2A3B53;

	@media screen and (max-width: 999px) {
	  width: 20px;
	  height: 20px;
	}
  }

  @media screen and (max-width: 999px) {
	width: 50px;
  }
}

.wait_list_item_button_disabled{
  opacity: .4;
  cursor: not-allowed;
}


@media screen and (max-width: 999px) {
  :global(.choose_girl) .clmn_3_personal {
	display: none;
  }

  :global(.choose_man) .connect_top_row {
	display: flex;
  }

  :global(.wait_list) .connect_wait_list {
	display: flex;
  }
}



.country_subtitle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 2px;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 14.63px;
	letter-spacing: -0.01em;
	margin-bottom: 5px;
}

.female_title {
	color: #E235D1;
}

.male_title {
	color: #5163FF;
}

.country_content {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 16px;
}

