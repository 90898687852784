@import "@sitestyles";

/*POPUP PHOTO GALLERY*/

.popup_likeher_search_photos{
	display: block;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(55, 78, 108, 0.32);
	z-index: 999;
}

.popup_likeher_search_photos_content{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	height: 100%;
}

.popup_likeher_search_photos_big_photo{
	position: relative;
}

.popup_likeher_search_photos_big_photo img{
	/*max-width: calc(100vw - 220px);*/
	max-height: 95vh;
	max-height: calc(var(--vh, 1vh) * 95);
	border-radius: 10px;
	display: block;
}

.popup_likeher_search_photos_big_photo.unpaid{
	background-color: #aaa;
	border-radius: 10px;
}

.popup_likeher_search_photos_big_photo.unpaid img{
	filter: blur(35px);
}

.popup_likeher_photo_unpaid_warning{
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 20px 0 30px;
	background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
	border-radius: 10px 10px 0px 0px;
	font-weight: 500;
	font-size: 18px;
	color: #333333;
	text-align: center;
	z-index: 1;
}

.popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning{
	display: block;
}

.popup_likeher_photo_unpaid_warning span{
	display: block;
	font-weight: 600;
	font-size: 24px;
	color: #6F0267;
}

.popup_likeher_search_photos_list_wrap{
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding: 20px 10px 20px 0;
	padding-right: 10px;
	background: rgba(55, 78, 108, 0.32);
	margin-left: 22px;
}

.popup_likeher_search_photos_list{
	height: 100%;
	overflow-y: auto;
	padding: 0 14px 0 24px;
}

.popup_likeher_search_photos_list::-webkit-scrollbar{
	width: 3px;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
}

.popup_likeher_search_photos_list::-webkit-scrollbar-thumb {
	background: #00317B;
	border-radius: 7px;
}

.popup_likeher_search_photos_list_item{
	margin-bottom: 16px;
}

.popup_likeher_search_photos_list_item img{
	width: 110px;
	height: 183px;
	object-fit: cover;
	display: block;
	border-radius: 10px;
	cursor: pointer;
}

.popup_likeher_search_photos_list_item.unpaid{
	background-color: #aaa;
	border-radius: 10px;
}

.popup_likeher_search_photos_list_item.unpaid img{
	filter: blur(25px);
}

.popup_likeher_search_photos_list_item:last-child{
	margin-bottom: 0;
}

.popup_likeher_search_photos_prev{
	position: absolute;
	left: 0;
	top: 50%;
	background: #F0F9FF;
	border-radius: 0 40px 40px 0;
	width: 77px;
	height: 77px;
	line-height: 77px;
	text-align: center;
	cursor: pointer;
}

.popup_likeher_search_photos_next{
	position: absolute;
	right: 0;
	top: 50%;
	background: #F0F9FF;
	border-radius: 40px 0px 0px 40px;
	width: 77px;
	height: 77px;
	line-height: 77px;
	text-align: center;
	cursor: pointer;
}

.popup_likeher_search_photos_next img{
	line-height: 1;
	vertical-align: middle;
	width: 22px;
}

.popup_likeher_search_photos_prev img{
	line-height: 1;
	vertical-align: middle;
	transform: rotate(180deg);
	width: 22px;
}

.popup_likeher_search_photos_close{
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 10px;
	cursor: pointer;
}

.popup_likeher_search_photos_close img{
	width: 24px;
	display: block;
}

@media screen and (max-width: 599px){
	.popup_likeher_search_photos_next, .popup_likeher_search_photos_prev {
		width: 65px;
		height: 45px;
		line-height: 45px;
		bottom: 24px;
		top: unset;
		background-color: rgba(255,255,255,.7);
	}

	.popup_likeher_search_photos_next img, .popup_likeher_search_photos_prev img {
		width: 15px;
	}
}
