@import "@sitestyles";

.clmn_3_chat_list_wrap {
	flex: 1px;
	background-color: #fff;
	border-radius: 0 0 5px 5px;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	padding: 6px 0;
	overflow: hidden;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
}

.clmn_3_chat_scroll {
	height: calc(100% - 18px);
	overflow-y: auto;
	padding: 0 12px 0 20px;
	margin-right: 5px;
}

.clmn_3_chat_scroll::-webkit-scrollbar {
	width: 3px;
	background: #fff;
	border-radius: 3px;
}

.clmn_3_chat_scroll::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 3px;
}


.clmn_3_chat_list {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 100%;
}

.clmn_3_chat_typing{
	font-family: "Roboto", sans-serif;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	margin-top: auto;
}

.chat_typing_left{
	display: flex;
	align-items: center;
	letter-spacing: -0.01em;
	color: $color1;
}

.chat_typing_right{
	text-align: right;
	letter-spacing: -0.01em;
	color: rgba(51, 51, 51, 0.7);
}

.chat_typing_left img{
	margin-right: 14px;
}

.clmn_3_chat_typing span span{
	font-weight: 500;
}

.chat_was_online{
	font-size: 12px;
	color: #27AE60;
}

.chat_was_online.week{
	color: #E99C2A;
}

.chat_was_online.month{
	color: #EB5757;
}

.wrapper_main_loader{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50%;
}

.main_loader{
	left: unset;
	top: unset;
}
