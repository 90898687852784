@import "@sitestyles";

.upload_popup_tabs_content_item_top{
	display: flex;
	justify-content: center;
	align-items: center;
}
.upload_popup_tabs_content_item.active{
	display: flex;
	justify-content: center;
	height: 100%;
}
.upload_popup_tabs_content_item.hidden{
	display: none;
}
.upload_popup_tabs_content_item_top > img {
	padding-top: 30px;
	padding-bottom: 30px;
}
