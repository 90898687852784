@import "@sitestyles";

.upload_popup_wrap{
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: auto;
  height: 682px;
  max-height: 100%;
  position: relative;
}

.attach_new_temp{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #999999;
}

.attach_new_popup_wrap{
  display: flex;
  width: 848px;
  max-width: 100%;
  position: relative;
}

.attach_new_popup_left{
  width: 168px;
  margin: 10px 0;
  background-color: #f4f4f4;
  box-shadow: -5px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  flex: none;
}

.attach_new_popup_ul li{
  font-family: "Roboto", sans-serif;
  list-style: none;
  height: 57px;
  cursor: pointer;
}

.attach_new_popup_ul li.active{
  color: #720066;
  position: relative;
  background: url(../../img/attach-li-bottom.svg) right 71px,
  url(../../img/attach-li-top.svg) right -20px;
}

.attach_new_popup_ul li.active:first-child{
  margin-top: -6px;
  margin-bottom: 6px;
}

.attach_new_popup_ul li.active:before{
  content: "";
  display: block;
  width: calc(100% + 9px);
  height: calc(100% + 8px);
  position: absolute;
  left: -9px;
  top: -4px;
  background-color: #ffffff;
  box-shadow: -6px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px 0px 0px 10px;
}

.attach_new_popup_ul li.active:after{
  content: "";
  display: block;
  width: 12px;
  height: calc(100% + 8px);
  position: absolute;
  right: -10px;
  top: -4px;
  background-color: #ffffff;
  pointer-events: none;
}

.attach_new_popup_li_wrap{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-align-items: center;
  align-items: center;
  padding-left: 32px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $color1;
  position: relative;
}

li.active .attach_new_popup_li_wrap{
  color: #720066;
}

li.active .attach_new_popup_li_wrap:after{
  content: "";
  width: 21px;
  height: calc(100% + 48px);
  position: absolute;
  right: -1px;
  top: -24px;
  background: url(../../img/attach-li-top.svg) right top,
  url(../../img/attach-li-bottom.svg) right bottom;
  background-repeat: no-repeat;
  pointer-events: none;
}

li.active:first-child .attach_new_popup_li_wrap:after{
  background: url(../../img/attach-li-bottom.svg) right bottom;
  background-repeat: no-repeat;
}

.attach_new_popup_ul li span{
  position: relative;
}

.attach_new_popup_li_wrap div{
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
  position: relative;
  width: 26px;
}

.attach_new_popup_li_photo{
  width: 25px;
  height: 25px;
  background-image: url(../../img/attach-photo-ico.svg);
}

li.active .attach_new_popup_li_photo{
  background-image: url(../../img/attach-photo-ico-a.svg);
}

.attach_new_popup_li_video{
  width: 26px;
  height: 16px;
  background-image: url(../../img/attach-video-ico.svg);
}

li.active .attach_new_popup_li_video{
  background-image: url(../../img/attach-video-ico-a.svg);
}

.attach_new_popup_li_audio{
  width: 24px;
  height: 24px;
  background-image: url(../../img/attach-audio-ico.svg);
}

li.active .attach_new_popup_li_audio{
  background-image: url(../../img/attach-audio-ico-a.svg);
}

.attach_new_popup_folder {
  border-top: 1px dashed $color1;
  border-bottom: 1px dashed $color1;
  margin: 25px 0;
  padding: 10px 0;
  max-height: 240px;
  overflow: auto;

  &::-webkit-scrollbar{
	width: 4px;
	background: #fff;
	border-radius: 22px;
  }

  &::-webkit-scrollbar-thumb {
	background: $color1;
	border-radius: 9px;
  }
}

.attach_new_popup_folder_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.attach_new_popup_folder_item {
  position: relative;
  width: 100%;
  min-height: 28px;
  color: $color1;
  font-weight: 500;
  font-size: 14px;
  list-style: none;
  margin-top: 6px;
  padding: 4px 25px 4px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  word-break: break-all;

  &:first-child {
	padding: 4px 10px;
	font-weight: 600;
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
  }

  &:hover {
	opacity: 0.8;
  }

  &.active {
	background: #fff;
	border-radius: 4px;
	border: 1px solid #eee;
  }
}

.attach_new_popup_folder_delete {
  position: absolute;
  transform: translateY(-50%);
  right: 2px;
  top: 50%;
}

.attach_new_popup_drag_drop{
  width: 132px;
  height: 123px;
  border: 2px dashed $color1;
  border-radius: 5px;
  margin: auto auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.attach_new_popup_drag_drop.accept{
  border: 2px dashed $color1;
}

.attach_new_popup_drag_drop.reject{
  border: 2px dashed red;
}

.attach_new_popup_drag_drop div{
  text-align: center;
  margin-bottom: 10px;
  color: $color1;
  font-size: 12px;
}

.attach_new_popup_right{
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px 8px 8px 10px;
}

.attach_new_popup_info_text{
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: $color1;
}

.attach_new_popup_tab_wrap{
  height: 100%;
}

.attach_new_popup_tab_content{
  height: 100%;
  padding: 23px 10px 23px 23px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.attach_new_popup_tab_content_top{
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  padding-right: 14px;
  align-items: center;
}

.attach_new_popup_tab_content_top_text{
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: rgba(51, 51, 51, 0.3);
  flex: none;
}

.attach_new_popup_tab_content_top_sort{
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: $color1;
}

.attach_new_popup_tab_top_warning {
  background: #ff00001c;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 16px;
}

.attach_new_popup_tab_content_top_sort img{
  width: 16px;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 9px;
}

.attach_new_popup_tab_content_midddle{
  flex: 1px;
  overflow-y: auto;
  padding-right: 8px;
}

.attach_new_popup_tab_content_midddle::-webkit-scrollbar{
  width: 3px;
  background: #f4f4f4;
  border-radius: 5px;
}

.attach_new_popup_tab_content_midddle::-webkit-scrollbar-thumb{
  background: $color1;
  border-radius: 7px;
}

.attach_new_popup_tab_content_bottom{
  margin-top: 20px;
  flex: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  padding-right: 15px;
}

.attach_new_popup_upload_btn{
  width: 144px;
  height: 42px;
  line-height: 40px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid $color1;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $color1;
  cursor: pointer;
}

.attach_new_popup_upload_btn:hover{
  box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
}

.attach_new_popup_upload_btn img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 13px;
}

.attach_new_popup_folder_btn_wrap {
  display: flex;
  justify-content: space-between;
}

.attach_new_popup_folder_btn {
  width: max-content;
  padding: 0 14px;
  margin: 0 6px;
  height: 42px;
  line-height: 40px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid $color1;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: $color1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
	margin-right: 10px;
	font-size: 26px;
  }

  &:hover{
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
  }

  &.delete {
	color: #d32f2f;
	border: 1px solid #d32f2f;
  }
}

.attach_new_popup_send_btn{
  width: 203px;
  height: 42px;
  line-height: 42px;
  background: $color5;
  box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.01em;
  cursor: pointer;

  &.disabled {
    background: #b0b0b0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
  }
}

.attach_new_popup_send_btn:hover{
  box-shadow: 0px 6px 12px rgba(84, 0, 114, 0.24);
}

.attach_new_popup_send_btn img{
  vertical-align: middle;
  margin-bottom: 6px;
  margin-right: 14px;
}

.attach_new_popup_close{
  position: absolute;
  right: 2px;
  top: 2px;
  padding: 5px;
  cursor: pointer;
}

.attach_new_popup_close img{
  display: block;
}

.attach_loader_gif{
  width: 60px;
}

.upload_popup_tabs_content_audio_item{
  background-color: #f3f5ff;
  padding: 16px;
  margin-bottom: 8px;
}

.upload_popup_tabs_content_audio_item.selected{
  background-color: rgba(156, 110, 247, 0.5);
}

.upload_popup_tabs_content_audio_item.selected .popup_audio_item_name{
  color: #333;
}

.popup_audio_item_check input{
  display: none;
}

.popup_audio_item_check label {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #ced6e0;
  border-radius: 4px;
  cursor: pointer;
}

.popup_audio_item_check input:checked+label {
  background-image: url(../../img/popup-checked.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #9c6ef7;
  border-color: #9c6ef7;
}

.popup_audio_item_top{
  margin-bottom: 12px;
  display: flex;
}

.popup_audio_item_bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup_audio_item_name{
  font-weight: 600;
  font-size: 16px;
  width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: .01em;
  color: rgba(55,78,108,.56);
}

.upload_popup_tabs_content_middle.video{
  grid-template-columns: repeat(3, 1fr);
}

.upload_popup_tabs_content_middle.photo{
  grid-template-columns: repeat(4, 1fr);
}

.upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video {
  display: grid;
  grid-gap: 8px;
  overflow: auto;
}

.upload_popup_tabs_content_item {
  background-color: #f3f5ff;
  display: flex;
  flex-direction: column;
}

.upload_popup_tabs_content_item.selected{
  background-color: rgba(156, 110, 247, 0.5);
}

.upload_popup_tabs_content_item.selected .popup_time{
  color: #333;
}

.upload_popup_tabs_content_item_top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

}

.upload_popup_tabs_content_item_top input {
  display: none;
  position: relative;
  top: 19px;
  right: -101px;
}

.upload_popup_tabs_content_item_top label {
  position: relative;
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.upload_popup_tabs_content_item_top label img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  display: block;
  object-fit: cover;
  object-position: top;
}

.upload_popup_tabs_content_item_top label:after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #fff;
  border: 1px solid #ced6e0;
}

.upload_popup_tabs_content_item_top input:checked+label:after {
  background-image: url(../../img/popup-checked.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #9c6ef7;
  border-color: #9c6ef7;
}

.upload_popup_tabs_content_item_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
}

.upload_popup_tabs_content_item_bottom img {
  display: block;
  cursor: pointer;
}

.popup_time {
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: #a3b3c9;
}

@media screen and (max-width: 899px){
  .upload_popup_tabs_content_middle.video{
	grid-template-columns: repeat(2, 1fr);
  }

  .upload_popup_tabs_content_middle.photo{
	grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 599px){
  .attach_new_popup_tab_content_midddle{
	padding-right: 3px;
  }

  .attach_new_popup_wrap{
	width: 281px;
  }

  .attach_new_popup_left{
	width: 64px;
  }

  .attach_new_popup_li_wrap{
	padding-left: 0;
	justify-content: center;
  }

  .attach_new_popup_li_wrap span{
	display: none;
  }

  .attach_new_popup_li_wrap div{
	margin-right: 0;
  }

  .attach_new_popup_drag_drop{
	display: none;
  }

  .attach_new_popup_info_text{
	display: none;
  }

  .attach_new_popup_upload_btn{
	width: 51px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
  }

  .attach_new_popup_upload_btn span{
	display: none;
  }

  .attach_new_popup_upload_btn img{
	margin-right: 0;
	margin-bottom: 0;
  }

  .attach_new_popup_send_btn{
	width: 103px;
  }

  .attach_new_popup_send_btn span span{
	display: none;
  }

  .attach_new_popup_tab_content_top_text{
	width: 100px;
  }

  .attach_new_popup_tab_content_top{
	margin-bottom: 11px;
  }

  .attach_new_popup_tab_content{
	padding: 11px 5px 17px 10px;
  }

  .attach_new_popup_tab_content_bottom{
	margin-top: 11px;
  }

  .upload_popup_tabs_content_middle.video{
	grid-template-columns: repeat(1, 1fr);
  }

  .upload_popup_tabs_content_middle.photo{
	grid-template-columns: repeat(2, 1fr);
  }
}


.add_file_popup_wrap {
  width: 500px;
  max-width: 100%;
  background-color: #fff;
  position: relative;
  display: inline-block;
}

.popup_add_file_head {
  height: 44px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: $color1;
  line-height: 43px;
  margin-bottom: 20px;
}
.popup_add_file_body{
  overflow-y: auto;
  height: 250px;
}
.popup_add_file_row {
  margin: 0 20px 9px;
}

.popup_add_file_row.uploaded {
  display: flex;
  align-items: center;
}

.popup_add_file_row span {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: $color1;
}

.popup_uploaded_info {
  margin-left: 25px;
  font-weight: 600;
  font-size: 14px;
  color: #6f0267;
}

.popup_uploaded_info span {
  color: rgba(51,51,51,.6);
  font-weight: 400;
  font-size: 12px;
}

.popup_add_file_row .popup_add_file_upload_btn {
  //right: 50%;
  //transform: translateX(50%);
}

.popup_add_file_upload_btn {
  //position: absolute;
  //bottom: 22px;
  //right: 22px;
}
.popup_add_file_upload_btn {
  margin: 20px auto 0;
  background: #02317c;
  height: 42px;
  width: 116px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 42px;
  cursor: pointer;
}

.popup_add_file_close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  cursor: pointer;
}

.popup_add_file_close img {
  width: 12px;
  height: 12px;
}
