@import "@sitestyles";

.popup_error_wrap{
	//position: relative;
	width: 417px;
	max-width: 100%;
	height: 200px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	/*box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);*/
	border-radius: 8px;
	background-color: #fff;
	text-align: center;
}

.popup_error {
  width: 100%;
  padding: 0 10px;
}

.popup_error_title{
	font-weight: 600;
	font-size: 20px;
	color: #00317B;
	margin-bottom: 14px;
}

.popup_error_text{
	font-family: "Roboto", sans-serif;
	line-height: 21px;
	color: rgba(51, 51, 51, 0.8);
	margin-bottom: 18px;
}

.popup_error_btn_wrap {
  display: flex;
  justify-content: space-around;
}

.popup_error_btn {
  display: block;
  max-width: calc(50% - 14px);
  flex: 1;
  height: 43px;
  line-height: 43px;
  margin: 20px auto 10px;
  background: linear-gradient(90.66deg, #00317B 0%, #720066 100%);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;

  &.transparent {
	background: transparent;
	border: 1px solid #00317B;
	color: #00317B;
  }

  &:hover{
	box-shadow: 0 4px 8px rgba(14, 4, 143, 0.24);
  }
}

.popup_error_btn img{
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 11px;
}

.popup_error_close {
  position: absolute;
  right: 8px;
  top: 7px;
  padding: 5px;
  cursor: pointer;
}
