@import "@sitestyles";

.media_new_popup_left{
  width: 168px;
  margin: 10px 0;
  background-color: #f4f4f4;
  box-shadow: -5px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px 0px 0px 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  flex: none;
}

.media_new_popup_ul li{
  font-family: "Roboto", sans-serif;
  list-style: none;
  height: 57px;
  cursor: pointer;
}

.media_new_popup_ul li.active{
  color: #720066;
  position: relative;
  background: url(../../img/attach-li-bottom.svg) right 71px,
  url(../../img/attach-li-top.svg) right -20px;
}

.media_new_popup_ul li.active:first-child{
  margin-top: -6px;
  margin-bottom: 6px;
}

.media_new_popup_ul li.active:before{
  content: "";
  display: block;
  width: calc(100% + 9px);
  height: calc(100% + 8px);
  position: absolute;
  left: -9px;
  top: -4px;
  background-color: #ffffff;
  box-shadow: -6px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px 0px 0px 10px;
}

.media_new_popup_ul li.active:after{
  content: "";
  display: block;
  width: 12px;
  height: calc(100% + 8px);
  position: absolute;
  right: -10px;
  top: -4px;
  background-color: #ffffff;
  pointer-events: none;
}

.media_new_popup_li_wrap{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-align-items: center;
  align-items: center;
  padding-left: 32px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $color1;
  position: relative;
}

li.active .media_new_popup_li_wrap{
  color: $color1;
}

li.active .media_new_popup_li_wrap:after{
  content: "";
  width: 21px;
  height: calc(100% + 48px);
  position: absolute;
  right: -1px;
  top: -24px;
  background: url(../../img/attach-li-top.svg) right top,
  url(../../img/attach-li-bottom.svg) right bottom;
  background-repeat: no-repeat;
  pointer-events: none;
}

li.active:first-child .media_new_popup_li_wrap:after{
  background: url(../../img/attach-li-bottom.svg) right bottom;
  background-repeat: no-repeat;
}

.media_new_popup_ul li span{
  position: relative;
}

.media_new_popup_li_wrap div{
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
  position: relative;
  width: 26px;
}

.media_new_popup_li_icon_man{
  width: 24px;
  height: 24px;
  background-image: url(../../img/icon-man.svg);
}

li.active .media_new_popup_li_icon_man{
  background-image: url(../../img/icon-man-a.svg);
}

.media_new_popup_li_icon_woman{
  width: 24px;
  height: 24px;
  background-image: url(../../img/icon-woman.svg);
}

li.active .media_new_popup_li_icon_woman{
  background-image: url(../../img/icon-woman-a.svg);
}

.media_new_popup_right{
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 10px 8px 8px 10px;
}

.media_new_popup_tab_wrap{
  height: 100%;
}

.media_new_popup_tab_content{
  height: 100%;
  padding: 23px 10px 23px 23px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.media_new_popup_tab_content_top{
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  padding-right: 14px;
  align-items: center;
}

.media_new_popup_tab_content_top_ul{
  display: flex;
  flex-wrap: wrap;
}

.media_new_popup_tab_content_top_ul li{
  font-family: "Roboto", sans-serif;
  list-style: none;
  height: 42px;
  margin: 0 20px;
  cursor: pointer;
  transition: background-color .3s;
}

.media_new_popup_tab_content_top_inner_li{
  text-transform: none;
  font-size: 16px;
  margin: 0 auto;
  padding: 8px 14px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 6px;
  color: $color1;
  transition: border .3s, background-color .3s;
}

li.active .media_new_popup_tab_content_top_inner_li{
  border: 2px solid rgb(169, 201, 205);
  background-color: rgb(236, 245, 246);
  color: $color1;
  font-weight: 500;
}

.media_new_popup_tab_content_midddle{
  flex: 1px;
  overflow-y: auto;
  padding-right: 8px;
}

.media_new_popup_tab_content_midddle::-webkit-scrollbar{
  width: 3px;
  background: #f4f4f4;
  border-radius: 5px;
}

.media_new_popup_tab_content_midddle::-webkit-scrollbar-thumb{
  background: $color1;
  border-radius: 7px;
}

.media_new_popup_close{
  position: absolute;
  right: 2px;
  top: 2px;
  padding: 5px;
  cursor: pointer;
}

.media_new_popup_close img{
  display: block;
}

.popup_attach_photo_click_img{
  max-width: 100%;
  max-height: calc(100vh - 30px);
  max-height: calc(var(--vh, 1vh)*100 - 30px);
  object-fit: contain;
  vertical-align: middle;
  border-radius: 10px;
}

.popup_attach_photo_click_img.audio{
	width: 300px;
}

.pap-btn-close{
  position: absolute;
  right: 5px;
  top: 5px;
}

.pap-btn-close img{
  width: 25px;
}

@media screen and (max-width: 599px){
  .media_new_popup_left{
	width: 60px;
  }

  .media_new_popup_li_wrap{
	padding-left: 5px;
	font-size: 14px;
  }

  .media_new_popup_li_icon_man{
	width: 20px;
	height: 20px;
	background-size: contain;
  }

  .media_new_popup_li_wrap div{
	width: 20px;
	margin-right: 8px;
  }

  .media_new_popup_ul li span{
	width: 12px;
	overflow: hidden;
  }

  .media_new_popup_tab_content_top_inner_li{
	height: 32px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
  }

  .media_new_popup_tab_content_top_ul li{
	height: auto;
	margin: 0;
  }

  .rhap_volume-controls{
	display: none;
  }

  .media_new_popup_tab_content{
	padding-left: 10px;
	padding-right: 3px;
  }
}
