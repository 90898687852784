@import "@sitestyles";

.stats_autoselect{
  display: block;
}

.stats_autoselect.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding: 0;
  width: 200px;
  height: 38px;
}
