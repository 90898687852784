.mob_head{
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}

.head_logo img{
  height: 50px;
}

.head_notify_btn{
  width: 65px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BACDE9;
  border-radius: 8px 0 0 8px;
  box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
  cursor: pointer;
  position: relative;
}

.head_notify_btn img{
  width: 30px;
}

.left_menu_empty{
  width: 42px;
}

.left_menu_chat_list{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #1C4B72;
  text-align: center;
  font-weight: 600;
  width: 42px;
}

.left_menu_chat_list img{
  width: 26px;
  margin-bottom: 5px;
}

.left_menu_chat_list.filters img{
  width: 20px;
}

.left_menu_chat_list.sender img{
  width: 18px;
}

@media screen and (max-width: 1299px){
  .mob_head{
	display: flex;
  }
}

@media screen and (max-width: 999px){
  .left_menu_chat_list{
	display: flex;
  }
}

@media screen and (max-width: 599px){
  .mob_head{
	height: 50px;
  }

  .head_logo img{
	height: 40px;
  }

  .head_notify_btn{
	width: 55px;
	height: 40px;
	box-shadow: 0 2px 4px rgba(14, 4, 143, 0.24);
  }

  .head_notify_btn img{
	width: 25px;
  }

  .left_menu_chat_list{
	line-height: 1.2;
  }

  .left_menu_chat_list.sender img{
	width: 14px;
  }
}
