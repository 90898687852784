@import "@sitestyles";

.emoji-mart{
    background: transparent;
    border: none;
}

.emoji-mart-bar,
.emoji-mart-search,
.emoji-mart-category-label{
    display: none;
}

.emoji-mart-scroll{
    height: max-content;
    overflow-y: visible;
}

.emoji-mart .emoji-mart-emoji{
    outline: none;
}

.emoji-mart-category-list li:hover{
    background-color: #d3daff;
    border-radius: 100%;
}

.emoji-mart-category .emoji-mart-emoji span{
    cursor: pointer;
}

.emoji-mart-category .emoji-mart-emoji:hover:before{
    display: none;
}

#create-post-smiles em-emoji-picker {
  max-height: 228px;
  min-height: 228px;
}
