@import "@sitestyles";

.folder_modal_wrap {
  position: relative;
  background: #fff;
  width: 500px;
  min-height: 300px;
  max-height: 500px;
  border-radius: 4px;
}

.folder_modal_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 40px 80px;
}

.folder_modal_title {
  font-weight: 600;
  font-size: 20px;
  color: $color1;
  margin-bottom: 14px;
}

.folder_modal_list {
  max-height: 300px;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
	width: 4px;
	background: #eee;
	border-radius: 22px;
  }

  &::-webkit-scrollbar-thumb {
	background: linear-gradient(90.66deg, #00317B 0%, #720066 100%);
	border-radius: 9px;
  }

  label {
	padding: 0 10px;
	margin: 0;
	border-radius: 4px;

	&:hover {
	  background: #eee;
	}
  }
}

.folder_modal_btn_wrap {
  position: relative;
  margin-top: 24px;

  span {
	font-size: 14px;
	font-weight: 600;
	color: #d32f2f;
	position: absolute;
	top: -25px;
	left: 50%;
	transform: translateX(-50%);
	width: max-content;
  }
}

.folder_modal_btn {
  display: block;
  width: 219px;
  height: 43px;
  font-size: 16px;
  line-height: 43px;
  background: linear-gradient(90.66deg, #00317B 0%, #720066 100%);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
