@import "@sitestyles";

.popup_draft_form .c3_write_msg_textareas_files .letters_attach_files_item.file_photo img {
	height: 75px;
	width: auto;
	object-fit: cover;
}

.popup_draft_form .c3_write_msg_textareas_files .letters_attach_files_item.file_photo {
	width: auto;
}

.popup_draft_form .c3_write_msg_textareas_files_close {
	margin: auto 10px;
}

.slick-slider {
	width: 100%;
	padding: 0 24px;
}

.c3l_men_list_item_main_btn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.c3l_men_list_item_main_btn.btn-prev {
	left: 0;
}

.c3l_men_list_item_main_btn.btn-next {
	right: 0;
}

.c3l_men_list_item_main_btn.btn-prev img {
	transform: rotate(180deg);
}

.file_audio_wrap {
	display: flex;
	align-items: center;
}

.c3_write_msg_textareas_files_close {
	width: 20px;
	height: 20px;
	background-image: url(../img/c3l-remove-attach.svg);
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 10px;
	cursor: pointer;
}


.popup_compose_letter {
	display: inline-block;
	width: 1060px;
	max-width: 100%;
	background-color: #EBEDFA;
	padding: 32px;
	min-height: 400px;
}

.popup_compose_letter .fancybox-close-small svg {
	color: #CDD3DA;
}

.popup_compose_letter .fancybox-button {
	width: 30px;
	height: 30px;
	padding: 0;
}

/* popup compose letter head */

.popup_compose_head {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
}

.popup_compose_head_search {
	position: relative;
}

.popup_compose_head_search input {
	width: 247px;
	height: 42px;
	display: block;
	outline: none;
	background-color: #fff;
	padding: 0 42px 0 15px;
	font-weight: 500;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.popup_compose_head_search input::placeholder {
	color: rgba(51, 51, 51, 0.3);
}

.popup_compose_head_search button {
	width: 42px;
	height: 42px;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
}

.popup_compose_head_search button img {
	display: block;
	opacity: .6;
	margin: auto;
}

.popup_compose_head_right {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.popup_compose_head_choosen_filters_item {
	border: 1px solid rgba(51, 51, 51, 0.8);
	height: 42px;
	padding: 0 12px 0 12px;
	line-height: 42px;
	position: relative;
	margin-right: 16px;
}

.popup_compose_head_choosen_filters_item img {
	margin-left: 15px;
	cursor: pointer;
}

.popup_compose_head_choose_filter_wrap {
	position: relative;
}

.popup_compose_head_choose_filter_main {
	position: relative;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	background-color: #fff;
	width: 215px;
	height: 42px;
	line-height: 44px;
	font-weight: 500;
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
	padding-left: 14px;
	cursor: pointer;
}

.popup_compose_head_choose_filter_main:after {
	content: '';
	display: block;
	width: 9px;
	height: 5px;
	position: absolute;
	top: 19px;
	right: 15px;
	background-image: url(/img/c3l-choose-filter-arrow.svg);
	transition: .3s;
}

.popup_compose_head_choose_filter_main.active:after {
	transform: rotate(180deg);
}

.popup_compose_head_choose_filter_main img {
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 8px;
}

.popup_compose_head_choose_filter_list_wrap {
	display: none;
	position: absolute;
	left: 0;
	top: 100%;
	background-color: #fff;
	width: 100%;
	padding: 0 14px 14px 14px;
	z-index: 1;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 0 0 5px 5px;
}

.popup_compose_head_choose_filter_list_wrap.active {
	display: block;
}

.popup_compose_head_choose_filter_list {
	border-top: 1px solid #E0E2EB;
	padding-top: 14px;
}

.popup_compose_head_choose_filter_row {
	margin-bottom: 15px;
}

.popup_compose_head_choose_filter_row input {
	display: none;
}

.popup_compose_head_choose_filter_row label {
	display: block;
	position: relative;
	font-weight: 500;
	font-size: 16px;
	color: #858585;
	padding-left: 24px;
	line-height: 1;
	cursor: pointer;
}

.popup_compose_head_choose_filter_row label:before {
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	border: 1px solid $color1;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.popup_compose_head_choose_filter_row label:after {
	content: '';
	display: none;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: $color1;
	position: absolute;
	left: 3px;
	top: 3px;
}

.popup_compose_head_choose_filter_row input:checked + label:after {
	display: block;
}

.popup_compose_head_choose_filter_row input:checked + label {
	color: rgba(51, 51, 51, 0.9);
}

.popup_compose_head_choose_filter_btn {
	width: 106px;
	height: 25px;
	line-height: 24px;
	border: 1px solid $color1;
	border-radius: 5px;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	color: $color1;
	margin: auto;
	cursor: pointer;
}

.popup_compose_head_choose_filter_btn img {
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 10px;
}

/* popup compose letter men list */

.popup_compose_men_list_wrap {
	background-color: #fff;
	padding-bottom: 22px;
	padding-left: 22px;
	border-radius: 8px;
	padding-right: 22px;
}

.popup_compose_men_list_wrap3 {
	padding-bottom: 19px;
	padding-top: 22px;
	margin-top: 23px;
	overflow-x: auto;
}

.popup_compose_men_list_wrap2 {
	padding-bottom: 22px;
	padding-left: 5px;
	margin-bottom: -19px;
	display: grid;
	grid-template-columns: repeat(auto-fill, 300px);
	grid-template-rows: repeat(4, 104px);
	grid-row-gap: 20px;
	grid-column-gap: 20px;
	grid-auto-flow: column;
}

.popup_compose_men_list_wrap3::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background: #F4F4F4;
}

.popup_compose_men_list_wrap3::-webkit-scrollbar-thumb {
	background: #637FAC;
}

.popup_compose_man_item {
}

.popup_compose_man_item input {
	display: none;
}

.popup_compose_man_item_label {
	position: relative;
	display: block;
	width: 300px;
	height: 104px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
	padding: 0 24px;
	cursor: pointer;
}

.popup_compose_man_item_label.letter-dialog {
	box-shadow: 0px 4px 8px rgba(14, 4, 143, 0);
	width: 145px;
	height: 51px;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label {
	background-color: #EBE4FF;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label.letter-dialog {
	background-color: transparent;
}

.popup_compose_man_item_label:before {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
	background-color: #fff;
	border: 2px solid #CDD3DA;
	position: absolute;
	top: 14px;
	right: 14px;
	border-radius: 1px;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label:before {
	border-color: #fff;
	border-radius: 3px;
}

.popup_compose_man_item_label:after {
	content: '';
	display: none;
	width: 12px;
	height: 10px;
	position: absolute;
	top: 22px;
	right: 20px;
	background-image: url(/img/c3l-compose-man-check.svg);
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label:after {
	display: block;
}

.popup_compose_man_item_photo {
	margin-right: 9px;
	position: relative;
}

.popup_compose_man_item_photo:after {
	content: '';
	display: block;
	width: 11px;
	height: 11px;
	background-color: #E0E0E0;
	border: 2px solid #fff;
	border-radius: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}

.online .popup_compose_man_item_photo:after {
	background-color: #27AE60;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label .popup_compose_man_item_photo:after {
	border-color: #EBE4FF;
}

.popup_compose_man_item_photo img {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	object-fit: cover;
	display: block;
}

.popup_compose_man_item_id {
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.6);
}

.popup_compose_man_item_name {
	font-weight: 600;
	font-size: 18px;
	color: $color3;
	margin: 2px 0 4px;

	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.popup_compose_man_item_place {
	font-size: 12px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.6);
}

.popup_compose_man_item_place img {
	height: 12px;
	vertical-align: middle;
	margin-right: 2px;
}

.popup_compose_add_men_btn {
	width: 177px;
	height: 42px;
	background: $color5;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.01em;
	line-height: 42px;
	text-align: center;
	cursor: pointer;
	margin-top: 24px;
	margin-left: auto;
}

.popup_compose_add_men_btn img {
	vertical-align: middle;
	margin-right: 10px;
}

/* popup compose letter end */
/* popup write new letter */

.popup_write_letter {
	display: flex;
	flex-direction: column;
	width: 1100px;
	max-width: 100%;
	background-color: #EBEDFA;
	padding: 32px;
	height: 600px;
	max-height: 100%;
}

.popup_write_letter_head {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
}

.popup_write_letter_fromto {
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: center;
}

.popup_write_letter_fromto:first-child {
	//margin-right: 38px;
}

.popup_write_letter_fromto_text {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: $color1;
}

.popup_write_letter_user {
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	background-color: #fff;
	width: 264px;
	height: 54px;
	-ms-align-items: center;
	align-items: center;
	padding-left: 8px;
	margin-left: 10px;
	cursor: pointer;
}

.popup_write_letter_user:after {
	content: '';
	display: block;
	width: 10px;
	height: 6px;
	position: absolute;
	top: 24px;
	right: 15px;
	background-image: url(/img/c3l-choose-filter-arrow.svg);
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	transition: .3s;
}

.popup_write_letter_user.active:after {
	transform: rotate(180deg);
}

.popup_write_letter_user_photo {
	margin-right: 11px;
	position: relative;
}

.popup_write_letter_user_photo:after {
	content: '';
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: #E0E0E0;
	position: absolute;
	bottom: 0;
	right: 0;
	border: 2px solid #fff;
}

.online .popup_write_letter_user_photo:after {
	background-color: #27AE60;
}

.popup_write_letter_user_photo img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	object-fit: cover;
	display: block;
}

.popup_write_letter_user_name {
	font-weight: 600;
	font-size: 12px;
	color: $color3;
	margin-bottom: 2px;
	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	padding-right: 40px;
}

.popup_write_letter_user_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	font-family: "Roboto", sans-serif;
}

.popup_write_letter_user_to_id {
	position: relative;
	font-size: 10px;
	color: rgba(51, 51, 51, 0.6);
}

.popup_write_letter_user_to_id:after {
	content: '';
	display: inline-block;
	width: 2px;
	height: 2px;
	border-radius: 100%;
	background-color: #B2B2B2;
	margin: 0 4px;
	vertical-align: middle;
}

.popup_write_letter_user_place {
	font-size: 10px;
	letter-spacing: -0.03em;
	color: rgba(51, 51, 51, 0.6);
}

.popup_write_letter_users_hidden {
	display: none;
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	z-index: 2;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	max-height: 300px;
	overflow-y: auto;
}

.popup_write_letter_users_hidden.active {
	display: block;
}

.popup_write_letter_users_hidden:before {
	content: '';
	display: block;
	width: calc(100% - 16px);
	height: 1px;
	background-color: #E0E2EB;
	position: absolute;
	top: 0;
	left: 8px;
	z-index: 2;
}

.popup_write_letter_users_hidden .popup_write_letter_user {
	margin-left: 0;
}

.popup_write_letter_users_hidden .popup_write_letter_user:after {
	display: none;
}

/* popup write new letter write_area*/

.popup_write_letter_write_area {
	margin-top: 16px;
	height: calc(100% - 70px);
	flex: 1;
}

.popup_write_letter_write_area .c3l_letters_list_write_go_to_chat {
	width: 213px;
}

.popup_write_letter .fancybox-close-small svg {
	color: #CDD3DA;
}

.c3l_letters_list_write_area {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.c3l_letters_list_write_top {
	flex: 1;
}

/* column 3 letters write bottom */

.c3l_letters_list_write_bottom {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
	align-items: center;
}

.c3l_letters_list_write_bottom_count {
	font-family: "Roboto", sans-serif;
	color: #00317B;
}

.c3l_letters_list_write_go_to_chat {
	background-color: #fff;
	text-align: center;
	width: 154px;
	height: 42px;
	border: 1px solid $color1;
	color: $color1;
	font-weight: 600;
	line-height: 42px;
	cursor: pointer;
	margin-left: 15px;
}

.c3l_letters_list_write_go_to_chat img {
	vertical-align: middle;
	margin-right: 10px;
	margin-top: -2px;
}

.c3l_letters_list_write_bottom_right {
	/*position: absolute;
	right: 24px;*/
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c3l_letters_list_write_smile {
	position: relative;
	padding: 7px;
	margin-right: 22px;
	cursor: pointer;
}

.c3l_letters_list_write_smile img {
	display: block;
}

.c3l_letters_list_write_smile_list {
	display: none;
	background-color: #F4F4F4;
	position: absolute;
	right: 0;
	bottom: 100%;
	padding: 10px;
}

.c3l_letters_list_write_attach_btn {
	margin-right: 22px;
	cursor: pointer;
}

.c3l_letters_list_write_attach_btn img {
	display: block;
}

/* column 3 letters listbox */

.c3l_letters_list_box_wrap {
	height: calc(100% - 429px);
	overflow-y: auto;
	padding-right: 9px;
	margin-right: -14px;
}
