@import "@sitestyles";


.virtual_gifts {
  position: absolute;
  width: 100%;
  padding: 24px 12px 12px 24px;
  bottom: 69px;
  left: 0;
  border-radius: 15px 15px 0 0;
  background: #FFF;
  box-shadow: 0 -9px 30px 0px rgba(0, 0, 0, 0.05);
  z-index: 3;

  @media screen and (max-width: 1599px) {
	bottom: 95px;
  }

  @media screen and (max-width: 599px) {
    padding: 12px 7px 12px 16px;
    bottom: 90px;
  }
}

.virtual_gifts_title {
  color: #474F5C;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }

  &:before {
    content: '';
    display: block;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background: url('../../../../../img/icon_virtual_gift.svg') center no-repeat;
    background-size: contain;
    margin-right: 16px;

    @media screen and (max-width: 1140px) {
      margin-right: 8px;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
}

.virtual_gifts_container {
  margin-top: 24px;
  display: block;
  height: 320px;
  max-height: 320px;
  overflow-y: scroll;
  padding-right: 12px;

  @media screen and (max-width: 599px) {
    height: 238px;
    max-height: 238px;
    padding-right: 7px;
    margin-top: 12px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background: #C2C2C2;
	border-radius: 8px;
	cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #474F5C;
    border-radius: 8px;
	cursor: pointer;
  }
}

.virtual_gifts_text {
  color: #474F5C;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
  text-transform: capitalize;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.virtual_gifts_list {
  padding: 0;
  margin: 0;
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 16px;

  &:not(:last-child) {
    margin-bottom: 40px;

    @media screen and (max-width: 599px) {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 1499px) and (min-width: 1300px) {
	grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 599px) {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}

.virtual_gift_card {
  display: block;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.virtual_gift_card_button {
  border-radius: 5px;
  border: 1px solid #E6E6E6;
  background: #F8F8F8;
  height: 100%;
  min-height: 180px;
  display: grid;
  grid-row-gap: 8px;
  margin: 0;
  padding: 0;
  width: 100%;
  outline: 0;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover,
  &:focus {
    border-color: #474F5C;
    background: #FFF;

    .virtual_gift_card_name {
      color: #474F5C;
    }

    .virtual_gift_card_price {
      color: #474F5C;

      &:after {
        background: #474F5C;
      }
    }
  }

  @media screen and (max-width: 599px) {
	min-height: 160px;
  }
}

.virtual_gift_card_name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px;
  color: #474F5C;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
  transition: all .3s ease-in-out;
}

.virtual_gift_card_image {
  margin: 0 auto;
  width: 100px;
  height: auto;
  max-width: 100%;
}

.virtual_gifts_close_button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 24px;
  background: #C2C2C2;
  mask: url("../../../../../img/close-one-popup.svg") center no-repeat;
  mask-size: 12px 12px;
  -webkit-mask: url("../../../../../img/close-one-popup.svg") center no-repeat;
  -webkit-mask-size: 12px 12px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover,
  &:focus {
    background: #474F5C;
  }

  @media screen and (max-width: 599px) {
    top: 12px;
    right: 16px;
  }
}
