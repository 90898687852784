@import "@sitestyles";

.header{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: 1px solid $color1;
  flex: 1;
  cursor: pointer;
}

.item.active{
  background-color: $color1;
}

.item_chat{
  border-radius: 10px 0 0 10px;
}

.item_letters{
  cursor: pointer;
  border-radius: 0 10px 10px 0;
}

.item_inner{
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: $color1;
  padding-left: 35px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: left center;
}

.active .item_inner{
  color: #fff;
}

.item_inner_chat{
  background-image: url(../../../img/v2/sender_head_chat.svg);
}
.active .item_inner_chat{
  background-image: url(../../../img/v2/sender_head_chat_a.svg);
}

.item_inner_letters{
  background-image: url(../../../img/v2/sender_head_letters.svg);
}
.active .item_inner_letters{
  background-image: url(../../../img/v2/sender_head_letters_a.svg);
}

@media screen and (max-width: 599px){
  .item{
	height: 30px;
  }

  .item_inner{
	font-size: 12px;
	padding-left: 20px;
  }

  .item_inner_chat,
  .item_inner_letters{
	background-size: 14px;
  }

  .item_chat{
	border-radius: 5px 0 0 5px;
  }

  .item_letters{
	border-radius: 0 5px 5px 0;
  }
}
