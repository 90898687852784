@import "@sitestyles";

.list_block{
	background-color: #6a96b8;
	border-radius: 4px;
	position: relative;
	z-index: 2;
}

.head{
	width: 100%;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title{
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: #fff;
	flex: 1;
	margin-right: 8px;
	height: 100%;
	line-height: 40px;
	padding-left: 13px;
	cursor: pointer;
}

.create_btn{
	width: 38px;
	height: 38px;
	background-image: url(../../../../img/v2/notes-create-white.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	margin-right: 3px;
}

.list{
	height: 537px;
	overflow-y: auto;
	padding: 0 10px 0 12px;
}

.list::-webkit-scrollbar {
	width: 2px;
	background: transparent;
	border-radius: 10px;
}

.list::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 10px;
}
