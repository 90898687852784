@import "@sitestyles";

.send_stat_list_wrap{
  padding: 20px;
  background-color: #EEF1F5;
}

.send_stat_item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.send_stat_profile{
  margin-right: 20px;
}

.send_stat_profile.detail_page{
  align-self: flex-start;
}

.send_stat_photo{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  object-fit: cover;
  object-position: top;
  margin-bottom: 12px;
}

.send_stat_name{
  color: #474f5c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
  text-align: center;
}

.send_stat_id{
  text-align: center;
  font-size: 12px;
}

.send_stat_item_right{
  width: calc(100% - 170px);
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(47, 43, 99,.16);
  background-color: #fff;
  padding: 26px;
}

.send_stat_item_body{
  max-height: 140px;
  overflow-y: auto;
  margin-right: -2px;
}

.send_stat_item_body.detail_page{
  max-height: unset;
}

.send_stat_item_body::-webkit-scrollbar{
  width: 2px;
  background: transparent;
  border-radius: 10px;
}

.send_stat_item_body::-webkit-scrollbar-thumb{
  background: #adbaff;
  border-radius: 10px;
}

.send_stat_item_head{
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #00317B;
  margin-bottom: 8px;
}

.send_stat_head_col{
  color: #535973;
  flex: none;
  font-weight: 500;
}

.send_stat_item_list .content{
  flex: 1;
}

.send_stat_item_list .status{
  width: 70px;
  text-align: center;
}

.send_stat_item_list .date{
  width: 120px;
}

.send_stat_item_list .clear{
  width: 70px;
  text-align: center;
}

.send_stat_item_list .indirect{
  width: 70px;
  text-align: center;
}

.send_stat_item_list .not_answer{
  width: 70px;
  text-align: center;
}

.send_stat_item_row{
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 0;
}

.send_stat_item_row + .send_stat_item_row{
  margin-top: 10px;
}

.send_stat_item_col{
  flex: none;
  color: #00317B;
  font-weight: 500;
}

.send_stat_item_status{
  width: 12px;
  height: 12px;
  border: 2px solid #00317B;
  border-radius: 50%;
  margin: 0 auto;
}

.send_stat_item_status.active{
  border-color: #27AE60;
  background-color: #27ae60;
}

.send_stat_item_col.content{
  flex: none;
  width: calc(100% - 400px);
}

.send_stat_item_body.detail_page .send_stat_item_col.content{
  width: calc(100% - 280px);
}

.send_stat_item_col.content{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 30px;
}

.send_stat_item_col.not_answer{
  width: 70px;
  text-align: center;
}

.send_stat_item_col.effective{
  width: 70px;
  text-align: center;
}

.send_stat_item_detail_btn{
  height: 32px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 auto;
  padding: 0 20px;
  background: $color5;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

@media screen and (max-width: 767px){
  .send_stat_list_wrap{
	padding: 20px 5px;
  }

  .send_stat_item{
	flex-direction: column;
	margin-bottom: 40px;
	box-shadow: 0 0 12px rgba(47,43,99,.16);
	background-color: #fff;
	border-radius: 10px;
	padding-top: 10px;
  }

  .send_stat_photo{
	width: 100px;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
  }

  .send_stat_item_right{
	width: 100%;
	margin-top: 10px;
	padding: 10px;
	box-shadow: none;
  }

  .send_stat_item_col,
  .send_stat_head_col{
	font-size: 12px;
  }

  .send_stat_head_col.effective{
	width: 70px;
	text-align: center;
  }

  .send_stat_item_list .date{
	width: 80px;
  }

  .send_stat_item_col.content,
  .send_stat_item_list .content,
  .send_stat_item_body.detail_page .send_stat_item_col.content{
	width: 300px;
	flex: none;
  }

  .send_stat_item_list{
	overflow: auto;
  }

  .send_stat_item_body{
	overflow: visible;
	width: max-content;
	box-shadow: none;
  }

  .send_stat_item_head{
	width: max-content;
	position: sticky;
	top: 0;
	left: 0;
	width: max-content;
	background-color: #fff;
  }

  .send_stat_profile.detail_page{
	align-self: center;
  }
}
