@import "@sitestyles";

.column-1.wsearch{
	width: 293px;
}

.c3_woman_search_wrap{
	background-color: #f4f4f4;
}

.c3_woman_search_head{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	height: 61px;
	padding: 0 31px;
}

.c3_woman_search_head_range{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c3_woman_search_head_range_text{
	margin-right: 15px;
	font-weight: 600;
	color: #00317B;
}

.c3_woman_search_head_range_select_wrap{
	height: 30px;
}

.c3_woman_search_head_range .cl2h_tab_wide_filter_item_wrap{
	width: 82px;
	margin-right: 10px;
}

.c3_woman_search_head_range .like_select_head{
	height: 30px;
	line-height: 30px;
	background-color: #fff;
	padding-left: 12px;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	font-size: 14px;
	border-radius: 0;
}

.c3_woman_search_head_range .like_select_head:after{
	right: 11px;
}

.c3_woman_search_head_range .like_select_list{
	background-color: #fff;
	padding-top: 10px;
	margin-top: -5px;
}

.c3_woman_search_head_range .like_select_label{
	height: 30px;
	line-height: 30px;
	background-color: #fff;
	padding-left: 12px;
	font-size: 14px;
	border-radius: 0;
	margin: 0 6px;
	padding-left: 6px;
}

.c3_woman_search_head_range .like_select_head.selected + .like_select_list{
	background-color: #fff;
}

.c3_woman_search_head_range_confirm_btn{
	width: 26px;
	height: 26px;
	line-height: 23px;
	border: 2px solid $color5;
	text-align: center;
	margin-left: 6px;
	cursor: pointer;
}

.c3_woman_search_head_filter_btn{
	width: 230px;
	height: 37px;
	line-height: 37px;
	background-color: #fff;
	border: 1px solid $color1;
	/* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05); */
	font-weight: 500;
	color: $color1;
	padding-left: 11px;
	position: relative;
	cursor: pointer;
}

.fill_data_form_select_item_name {
	text-transform: uppercase;
}

.c3_woman_search_head_filter_btn.active{
	height: 50px;
	-ms-align-self: flex-end;
	align-self: flex-end;
	border-color: transparent;
}

.c3_woman_search_head_filter_btn img{
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 10px;
}

.c3_woman_search_head_filter_btn:after{
	content: '';
	display: block;
	width: 9px;
	height: 5px;
	position: absolute;
	right: 13px;
	top: 16px;
	background-image: url(/img/arrow-darkblue.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.c3_woman_search_head_filter_btn.active:after{
	transform: rotate(180deg);
}

.c3_woman_search_head_search_wrap{
	position: relative;
	margin-left: 177px;
}

.c3_woman_search_head_search_input{
	width: 174px;
	height: 30px;
	background-color: #fff;
	padding: 0 30px 0 12px;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #333333;
	outline: none;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3_woman_search_head_search_input::placeholder{
	color: rgba(51, 51, 51, 0.3);
	letter-spacing: -0.02em;
}

.c3_woman_search_head_search_btn{
	width: 30px;
	height: 30px;
	position: absolute;
	top: 0;
	right: 5px;
	background-image: url(/img/search-blue.svg);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	outline: none;
}

.c3_woman_search_head_search_input:not(:placeholder-shown) + .c3_woman_search_head_search_btn{
	background-image: url(/img/cross-blue.svg);
}

.c3_woman_search_list_wrap{
	height: calc(100vh - 61px);
	height: calc(calc(var(--vh, 1vh) * 100) - 61px);
	position: relative;
	margin-left: 31px;
	margin-right: 10px;
	overflow-y: auto;
}

.c3_woman_search_list_wrap::-webkit-scrollbar{
	width: 3px;
	background: #fff;
}

.c3_woman_search_list_wrap::-webkit-scrollbar-thumb{
	background-color: #6783B0;
}

.c3_woman_search_filters_wrap{
	display: none;
	background-color: #fff;
	padding: 24px 48px;
	margin-right: 21px;
	margin-bottom: 16px;
}

.c3_woman_search_filters_show_btn{
	width: 201px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #00317B;
	font-weight: 600;
	letter-spacing: 0.06em;
	color: #fff;
	margin: 6px auto 0;
	cursor: pointer;
}

.c3_woman_search_filters_show_btn img{
	vertical-align: middle;
	margin-bottom: 6px;
	margin-right: 12px;
}

.c3_woman_search_filters_matches_text{
	font-size: 12px;
	color: #9A9A9A;
	text-align: center;
	margin-top: 8px;
}

.c3_woman_search_filters_list{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
}

.c3_woman_search_filters_list_item{
	height: 42px;
}

.c3_woman_search_filters_list_item_wrap{
	width: calc((100% - 48px) / 3);
	margin-bottom: 24px;
}

.c3_woman_search_filters_list_item_name{
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $color3;
	margin-bottom: 3px;
}

.c3_woman_search_filters_list_item_name span{
	text-transform: none;
}

.c3_woman_search_filters_list_item_wrap.two_sel{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
}

.c3_woman_search_filters_list_item_wrap.two_sel .c3_woman_search_filters_list_item_name{
	width: 100%;
}

.c3_woman_search_filters_list_item_wrap.two_sel .c3_woman_search_filters_list_item{
	width: calc(50% - 6px);
}


/**************************
search profile item
**************************/

.c3_woman_search_list_block{
	display: grid;
	grid-template-columns: repeat(auto-fill, 290px);
	padding-bottom: 40px;
	justify-content: center;
	grid-gap: 16px;
	padding-right: 21px;
}

.c3_woman_search_list_item{
	padding-right: 54px;
	padding-bottom: 36px;
	position: relative;
}

.c3_woman_search_list_item_front{
	width: 236px;
	height: 400px;
	background-color: #fff;
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
	padding: 12px 12px 0;
	position: relative;
}

.c3_woman_search_list_item_photo{
	width: 100%;
	height: 328px;
	object-fit: cover;
	display: block;
	margin-bottom: 14px;
}

.c3_woman_search_list_item_name{
	color: #333;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 4px;
}

.c3_woman_search_list_item_info{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c3_woman_search_list_item_id{
	font-family: "Roboto", sans-serif;
	font-size: 10px;
	color: #333333;
	opacity: 0.5;
	margin-right: 10px;
	padding-bottom: 2px;
}

.c3_woman_search_list_item_place{
	font-weight: 500;
	font-size: 10px;
	color: #999999;
	padding-top: 2px;
}

.c3_woman_search_list_item_place img{
	vertical-align: middle;
	height: 12px;
	margin-bottom: 2px;
	margin-right: 5px;
	border-radius: 5px;
}

.c3_woman_search_list_item_back{
	width: 272px;
	height: 412px;
	position: absolute;
	bottom: 0;
	right: 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-ms-align-items: flex-end;
	align-items: flex-end;
	justify-content: space-between;
	background-color: #fff;
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
}

.c3_woman_search_list_item_back_top{
	width: 54px;
	padding-top: 10px;
}

.c3_woman_search_list_item_back_top_btn{
	font-family: "Roboto", sans-serif;
	width: 40px;
	height: 31px;
	background-repeat: no-repeat;
	background-position: center;
	margin-left: 6px;
	cursor: pointer;
	color: rgba(51, 51, 51, 0.8);
	margin-bottom: 6px;
	position: relative;
	z-index: 2;
}

.c3_woman_search_list_item_back_top_btn span{
	display: none;
}

.c3_woman_search_list_item_back_top_btn.letter_btn{
	background-image: url(/img/letter-dark.svg);
}

.c3_woman_search_list_item_back_top_btn.heart_btn{
	background-image: url(/img/heart-dark.svg);
}

.c3_woman_search_list_item_back_top_btn.heart_btn.active{
	background-image: url(/img/heart-dark-filled.svg);
}

.c3_woman_search_list_item_back_bottom{
	width: 100%;
	height: 36px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	padding-right: 6px;
}

.c3_woman_search_list_item_back_bottom_left{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	height: 100%;
	padding-left: 17px;
}

.c3_woman_search_list_item_back_bottom_profile_btn{
	width: 28px;
	height: 28px;
	background-image: url(/img/user-blue.svg);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	margin-right: 7px;
}

.c3_woman_search_list_item_back_bottom_photo_btn{
	width: 43px;
	height: 32px;
	background-image: url(/img/photo-blue.svg);
	background-repeat: no-repeat;
	background-position: center;
	-ms-align-self: flex-start;
	align-self: flex-start;
	box-shadow: 0px 3px 5px rgba(47, 43, 99, 0.16);
	cursor: pointer;
	position: relative;
	z-index: 2;
	background-color: #fff;
}

.c3_woman_search_list_item_back_bottom_chat_btn{
	width: 86px;
	height: 26px;
	line-height: 26px;
	background-color: #00317B;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #fff;
	cursor: pointer;
}

.c3_woman_search_list_item_back_bottom_chat_btn img{
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 8px;
	width: 15px;
}

@media screen and (min-width: 1200px){
	.c3_woman_search_list_item_back_top_btn:hover{
		width: max-content;
		background-position: 10px center;
		padding-left: 41px;
		line-height: 31px;
		background-color: #fff;
		box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
		padding-right: 12px;
	}

	.c3_woman_search_list_item_back_top_btn:hover span{
		display: inline-block;
	}
}


@media screen and (max-width: 1899px){
	.c3_woman_search_filters_list{
		display: grid;
		grid-template-columns: repeat(auto-fit, 390px);
		justify-content: center;
		grid-column-gap: 16px;
		grid-row-gap: 24px;
	}

	.c3_woman_search_filters_list_item_wrap{
		width: 100%;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 1449px){
	.c3_woman_search_head_search_wrap{
		margin-left: 0;
	}
}

@media screen and (max-width: 1299px){
	.c3_woman_search_head_range_text{
		width: 75px;
		text-align: center;
	}
}

@media screen and (max-width: 899px){
	.c3_woman_search_head_filter_btn span{
		display: none;
	}

	.c3_woman_search_head_filter_btn{
		width: 82px;
	}
}

@media screen and (max-width: 699px){
	.c3_woman_search_head{
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: center;
		height: 100px;
	}

	.c3_woman_search_head_range{
		width: 100%;
		justify-content: center;
	}

	.c3_woman_search_head_filter_btn{
		margin-right: 20px;
	}

	.c3_woman_search_head_range_text{
		width: auto;
	}
}

@media screen and (max-width: 599px){
	.c3_woman_search_head_range_text{
		font-size: 12px;
	}

	.c3_woman_search_head_range .cl2h_tab_wide_filter_item_wrap{
		width: 59px;
	}

	.c3_woman_search_head_range .like_select_head{
		padding-left: 5px;
		font-size: 12px;
	}

	.c3_woman_search_head_range .like_select_head:after{
		right: 5px;
	}

	.c3_woman_search_head{
		padding: 0 20px;
	}

	.c3_woman_search_list_wrap{
		margin-left: 20px;
		margin-right: 5px;
	}

	.c3_woman_search_list_block{
		padding-right: 7px;
	}

	.c3_woman_search_list_block{
		grid-template-columns: repeat(auto-fill, 285px);
	}

	.c3_woman_search_filters_list{
		display: block;
	}

	.c3_woman_search_filters_list_item_wrap{
		width: 390px;
		max-width: 100%;
		margin: 0 auto 24px;
	}

	.c3_woman_search_filters_wrap{
		padding: 24px;
	}
}
