@import "@sitestyles";

.loaderDiv{
	height: 100%;
    z-index: 9999;
	display: flex;
}
.loaderImage{
    /*position: absolute;*/
    left: calc(50% - 50px);
    top: calc(50% - 50px);
	display: block;
	margin: auto;
}


:global(.ReactModal__Content.ReactModal__Content--after-open) .loaderDiv{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	background-color: rgba(255,255,255,.1);
}
