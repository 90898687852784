@import "@sitestyles";

.clmn_3_chat_head {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  background-color: $color1;
  border-radius: 5px 5px 0 0;
  padding: 0 20px;
}

.clmn_3_chat_head_green {
	background-color: #04b580;
}

.clmn_3_chat_head_red {
	background-color: #710909;
}

.clmn_3_chat_head_blue {
	background-color: #25abe6;
}

.clmn_3_chat_head_labal {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -33px;
	text-align: center;
	font-size: 15px;
	font-weight: 600;
	padding: 6px 2px;
	border-radius: 0 0 5px 5px;
	z-index: 3;
	cursor: default;

	&.no_contacts {
		color: #F37070;
		background: rgba(255, 255, 255, 0.7);
		border: 2px solid #F37070;
	}

	&.social {
		color: #1F4F74;
		background: #1F4F744D;
		border: 2px solid #1F4F744D;
	}

	&.second {
        bottom: -64px;
    }
}

.clmn_3_chat_head_profile{
  display: flex;
  align-items: center;
}

.clmn_3_chat_head_profile_photo{
  cursor: pointer;
  position: relative;
}

.clmn_3_chat_head_profile_photo:after{
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 3px;
  right: 1px;
  border: 1px solid $color1;
  background-color: #B2B2B2;
  border-radius: 100%;
}

.clmn_3_chat_head_profile_photo.online:after{
  background-color: #27AE60;
}

.clmn_3_chat_head_profile.left .clmn_3_chat_head_profile_photo{
  margin-right: 10px;
}

.clmn_3_chat_head_profile.right .clmn_3_chat_head_profile_photo{
  margin-left: 10px;
}

.clmn_3_chat_head_profile_photo img{
  display: block;
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 100%;
}

.clmn_3_chat_head_profile.right .clmn_3_chat_head_profile_info{
  text-align: right;
}

.clmn_3_chat_head_profile_name{
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.01em;

  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 150px;
}

.clmn_3_chat_head_profile_name span{
  font-size: 10px;
  display: inline-block;
  border: 1px solid #fff;
  padding: 2px 3px 1px;
  vertical-align: bottom;
  margin-left: 3px;
  border-radius: 4px;
}

.clmn_3_chat_head_profile_id{
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.9);
  margin: 4px 0;
}

.clmn_3_chat_head_profile.right .clmn_3_chat_head_profile_place{
  justify-content: flex-end;
}

.clmn_3_chat_head_profile_place{
  font-size: 11px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.clmn_3_chat_head_profile_place img{
  height: 12px;
  margin-right: 3px;
}

.clmn_3_chat_head_nav{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  z-index: 5;
}

.clmn_3_chat_head_nav_btn{
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 30px;
  cursor: pointer;
  background-size: contain;
}

.clmn_3_chat_head_nav_btn:last-child{
  margin-right: 0;
}

.clmn_3_chat_head_nav_btn.favs{
  background-image: url(../../../../img/v2/c3ch-favs.svg);
}

.clmn_3_chat_head_nav_btn.favs2{
  background-image: url(../../../../img/v2/c3ch-favs2.svg);
}

.clmn_3_chat_head_nav_btn.chat{
  background-image: url(../../../../img/v2/sender_head_chat_a.svg);
}

.clmn_3_chat_head_nav_btn.letters{
  background-image: url(../../../../img/v2/c3ch-letters.svg);
}

.clmn_3_chat_head_nav_btn.folder{
  background-image: url(../../../../img/v2/c3ch-folder.svg);
}

.clmn_3_chat_head_nav_btn.double_folder{
  background-image: url(../../../../img/v2/c3ch-double-folder.svg);
}

.clmn_3_chat_head_nav_btn.folder2{
  background-image: url(../../../../img/v2/c3ch-folder2.svg);
}

.clmn_3_chat_head_nav_btn.double_folder2{
  background-image: url(../../../../img/v2/c3ch-double-folder2.svg);
}

.clmn_3_chat_head_nav_btn.black_list{
  //background-image: url(../../../../img/v2/c3ch-black-list.svg);
}

.clmn_3_chat_head_nav_btn.complain{
  background-image: url(../../../../img/v2/triple_dots.svg);
  margin-right: 0;
}

.clmn_3_chat_head_nav_btn.duplicates{
  background-image: url(../../../../img/profile_menu/c3-top-attention.svg);
  margin-right: 0;
}



.clmn_3_chat_head_nav_btn.complaint_banana{
  background-image: url(../../../../img/v2/complaint-banana.svg);
}

.clmn_3_chat_head_nav_btn.complaint_country{
  background-image: url(../../../../img/v2/complaint-flag.svg);
}

.clmn_3_chat_head_nav_btn.complaint_contact{
  background-image: url(../../../../img/v2/complaint-contact.svg);
}

.clmn_3_chat_head_nav_btn.complaint_child{
  background-image: url(../../../../img/v2/complaint-child.svg);
}

.clmn_3_chat_head_nav_btn.pin{
  background-image: url(../../../../img/v2/c3ch-pin.svg);
  background-size: 19px;
}

.clmn_3_chat_head_nav_btn.pinned{
  background-image: url(../../../../img/v2/c3ch-pinned.svg);
  background-size: 19px;
}

.clmn_3_chat_head_mob_nav_btn{
  display: none;
  cursor: pointer;
  opacity: .7;
  transition: .3s;
}

.clmn_3_chat_head_mob_nav_btn img{
  display: block;
  width: 20px;
}

.clmn_3_chat_head_middle{
  position: relative;
  display: flex;
  align-items: center;
}

:global(.chat_header_drafts) .clmn_3_chat_head_middle{
  display: none;
}

.clmn_3_chat_head_nav_btn_wrap{
  position: relative;
  margin-left: 20px;
}

.clmn_3_chat_head_complain_nav{
  /*display: none;*/
  grid-template-columns: repeat(2, 26px);
  grid-gap: 20px;
  position: absolute;
  left: 50%;
  top: calc(100% + 20px);
  transform: translateX(-50%);
  background-color: $color1;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 6px 12px rgba(84, 0, 114, .24);
  display: grid;
  z-index: 5;
}

.duplicates.clmn_3_chat_head_complain_nav {
  grid-template-columns: repeat(6, 75px);
}

.clmn_3_chat_head_complain_mob{
  display: none;

  /*display: grid;*/
  grid-template-columns: repeat(2, 25px);
  grid-gap: 20px;
  grid-column-start: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}

@media screen and (max-width: 1699px){
  .clmn_3_chat_head_mob_nav_btn{
    display: block;
  }

  .clmn_3_chat_head_mob_nav_btn:hover{
    opacity: 1;
  }

  .clmn_3_chat_head_nav{
    display: none;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    position: absolute;
    left: 50%;
    top: calc(100% + 20px);
    transform: translateX(-50%);
    background-color: $color1;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
  }

  .clmn_3_chat_head_nav.active{
    display: grid;
  }

  .clmn_3_chat_head_nav_btn{
    margin-right: 0;
  }
}

@media screen and (max-width: 599px){
  .clmn_3_chat_head{
    height: 50px;
    padding: 0 5px;
  }

  .clmn_3_chat_head_labal {
	font-size: 10px;
	bottom: -29px;

    &.second {
      bottom: -54px;
    }
  }

  .clmn_3_chat_head_profile_photo img{
    width: 40px;
    height: 40px;
  }

  .clmn_3_chat_head_profile_name{
    font-size: 12px;
    -webkit-line-clamp: 1;
    max-width: 120px;
  }

  .clmn_3_chat_head_profile_id{
    font-size: 10px;
    margin: 1px 0;
  }

  .clmn_3_chat_head_nav{
    top: calc(100% + 15px);
    border-radius: 0 0 6px 6px;
  }

  .clmn_3_chat_head_nav_btn{
    display: grid;
  }

  .clmn_3_chat_head_nav_btn_wrap{
    display: none;
  }

  .clmn_3_chat_head_complain_mob{
    display: grid;
  }
}
