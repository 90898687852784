@import "@sitestyles";

.clmn_2_letters{
  width: 370px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.create_letter_btn{
  height: 44px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color5;
  background-size: 200% 100%;
  border-radius: 5px;
  flex: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: .5s;
}

.create_letter_btn:hover{
  background-position: 100% 0;
}

.create_letter_btn img{
  margin-right: 12px;
}

.letters_folders{
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding: 24px;
}

.letters_folder_item{
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 24px;
  border-radius: 4px;
  cursor: pointer;
}

.letters_folder_item.active{
  background: $color4;
}

.letters_folder_img{
  width: 28px;
  height: 28px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
}

.letters_folder_item.mailbox .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-mailbox.svg);
}

.letters_folder_item.drafts .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-drafts.svg);
}

.letters_folder_item.trash .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-trash.svg);
}

.letters_folder_item.bookmarks .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-bookmarks.svg);
}

.letters_folder_item.mailbox.active .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-mailbox-a.svg);
}

.letters_folder_item.drafts.active .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-drafts-a.svg);
}

.letters_folder_item.trash.active .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-trash-a.svg);
}

.letters_folder_item.bookmarks.active .letters_folder_img{
  background-image: url(../../../img/v2/letters-folder/folder-bookmarks-a.svg);
}

.letters_folder_name{
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $color1;
}

.letters_folder_item.active .letters_folder_name{
  color: #fff;
}

.letters_folder_count{
  width: 50px;
  height: 24px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: #EB5757;
}

.letters_filters{
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  padding: 10px 15px;
  flex: 1;
}

.letters_filters_head{
  display: flex;
  align-items: center;
}

.letters_filters_head_title{
  font-weight: bold;
  font-size: 18px;
  color: #535973;
}

.letters_filters_head_freeze{
  margin-left: auto;
  cursor: pointer;
}

.letters_filters_head_freeze img{
  display: block;
}

.letters_filters_head_tumbler{
  margin-left: 20px;
}

.letters_filters_head_tumbler input{
  display: none;
}

.letters_filters_head_tumbler label{
  display: block;
  width: 35px;
  height: 16px;
  border-radius: 19px;
  background-color: #dadada;
  position: relative;
  cursor: pointer;
}

.letters_filters_head_tumbler label:after{
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 2px;
  top: 2px;
  background-color: #fff;
  border-radius: 100%;
  transition: .3s;
}

.letters_filters_head_tumbler input:checked + label{
  background-color: #ECF5F6;
}

.letters_filters_head_tumbler input:checked + label:after{
  left: 21px;
  background-color: #27AE60;
}

.letters_filters_row{
  margin-top: 13px;
}

.letters_filters_row + .letters_filters_row{
  margin-top: 52px;
}

.letters_filters_title{
  font-weight: 600;
  font-size: 15px;
  color: #357F89;
  margin-bottom: 14px;
  text-transform: uppercase;
}

.letters_filters_list{
  padding-left: 30px;
}

.letters_filter_item{
  position: relative;
  font-weight: 500;
  font-size: 14px;
  color: #858585;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  width: max-content;
}

.letters_filter_item:last-child{
  margin-bottom: 0;
}

.letters_filter_item:before{
  content: '';
  box-sizing: border-box;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #87ABC6;
  border-radius: 3px;
  margin-right: 12px;
}

.letters_filter_item_id{
  position: relative;
  margin-left: 9px;
}
.letters_filter_item_id input {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0.04em;
  width: 138px;
  height: 28px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  outline: none;
  padding: 2px 28px 0 7px;
}

.letters_filter_item_id input::placeholder{
  color: #333;
  font-weight: 500;
}

.letters_filter_item_id button {
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.letters_filter_item_id button img {
  display: block;
  margin: auto;
}

.letters_filter_item.active:before{
  border: none;
  background-image: url(../../../img/v2/letters-filter-checked.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1599px){
  .clmn_2_letters{
	width: 220px;
  }

  .letters_folders{
	padding: 12px;
  }

  .letters_folder_item{
	padding-left: 12px;
  }

  .letters_folder_img{
	width: 20px;
	height: 20px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
  }

  .letters_folder_name{
	font-size: 14px;
  }

  .letters_filters_head_title{
	font-size: 16px;
  }

  .letters_filters_head_freeze img{
	width: 18px;
  }

  .letters_filters_head_tumbler{
	margin-left: 15px;
  }

  .letters_filters_title{
	font-size: 14px;
  }

  .letters_filters_list{
	padding-left: 15px;
  }
}

.clmn_2_letters_close{
  display: none;
  width: 30px;
  height: 30px;
  background-image: url(../../../img/cross_white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  position: absolute;
  left: calc(100% + 10px);
  top: 10px;
  cursor: pointer;
}

.clmn_2_letters_scroll{
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.clmn_2_letters_scroll::-webkit-scrollbar{
  width: 3px;
  background: #fff;
  border-radius: 3px;
}

.clmn_2_letters_scroll::-webkit-scrollbar-thumb{
  background: #6a96b8;
  border-radius: 3px;
}

@media screen and (max-width: 999px){
  .clmn_2_letters{
	position: fixed;
	left: -280px;
	top: 0;
	width: 280px;
	height: 100%;
	background-color: #EEF1F5;
	z-index: 6;
	padding: 10px;
	transition: .3s;
  }

  .clmn_2_letters.active{
	left: 0;
  }

  .clmn_2_letters.active .clmn_2_letters_close{
	display: block;
  }
}

@media screen and (max-width: 409px){
  .clmn_2_letters{
	width: 270px;
  }
}
