.calendar_wrap{
  display: flex;
  align-items: center;
  position: relative;
  height: 38px;
  padding: 0 10px;
  border: 1px solid #000;
  border-radius: 5px;

  img{
	width: 15px;
	margin-right: 5px;
  }

  input{
	width: 150px;
	outline: none;
  }

  & + .calendar_wrap{
	margin-left: 10px;
  }
}

.calendar{
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  z-index: 999;
}

@media screen and (max-width: 599px){
  .calendar_wrap{
	width: calc(50% - 5px);
	input{
	  width: auto;
	  flex: 1;
	}
	&:nth-child(3){
	  .calendar{
		right: 0;
		left: unset;
	  }
	}
  }
}
