@import "@sitestyles";

.item{
	padding: 0 12px 12px;
	background-color: #fff;
	border-radius: 6px;
	margin-bottom: 8px;
}

.head{
	min-height: 38px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.title{
	font-weight: bold;
	letter-spacing: 0.01em;
	color: $color1;
	overflow-x: hidden;
	max-width: 180px;
	text-overflow: ellipsis;
}

.buttons{
	display: flex;
}

.button{
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.button.edit{
	background-image: url(../../../../../img/v2/notes-edit-btn.svg);
	margin-right: 7px;
}

.button.delete{
	background-image: url(../../../../../img/v2/notes-delete-btn.svg);
}

.content{
	margin-top: 8px;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: $color1;
	overflow-x: hidden;
	text-overflow: ellipsis;
}
