@import "@sitestyles";

.sender_forms{
  position: relative;
  background-color: #fff;
  padding: 0 11px 11px 11px;
  border-radius: 4px;
}

.sender_forms_inner_error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(92.43deg, rgba(113, 165, 172, 0.6) 3.09%, rgba(99, 134, 158, 0.6) 98.3%);
  border-radius: 8px;
  font-weight: 600;
  color: #06375F;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: default;
}

.sender_forms_top{
  display: flex;
  margin-bottom: 20px;
}

.sender_forms_top_left{
  width: 500px;
  margin-right: 70px;
}

.sender_forms_top_row{
  display: flex;
  align-items: center;
  margin-top: 9px;
}

.sender_forms_top_check{
  margin-top: 10px;
}

.sender_forms_top_row .sender_forms_top_check{
  margin-top: 0;
}

.sender_forms_top_check input{
  display: none;
}

.sender_forms_top_check label{
  display: block;
  width: max-content;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.8);
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.sender_forms_top_check label:before{
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background: #FFFFFF;
  border: 1px solid #D4E1E5;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sender_forms_top_check input:checked + label:before{
  background-color: $color1;
  border-color: $color1;
  background-image: url(../../../../img/v2/sender_forms_check.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.sender_forms_top_right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  padding-top: 12px;
}

.sender_forms_top_save_btn{
  width: 109px;
  height: 49px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color8;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 600;
  color: $color8;
  cursor: pointer;
}

.sender_forms_top_save_btn img{
  margin-right: 9px;
}

.sender_forms_top_clear_btn{
  cursor: pointer;
  font-weight: 600;
  background: linear-gradient(99.84deg, #357F89 4.33%, #1C4B72 98.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sender_forms_middle{
  display: flex;
  margin-bottom: 15px;
}

.sender_forms_middle_left{
  height: 180px;
  background-color: #F7F8FB;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  padding: 15px 5px 15px 17px;
  //margin-right: 10px;
  flex: 1;
}

.sender_forms_middle_left textarea{
  font-family: inherit;
  display: block;
  width: 100%;
  //height: 100%;
  resize: none;
  color: #333;
  font-weight: 500;
  padding-right: 5px;
}

.sender_forms_middle_left textarea::-webkit-scrollbar {
  width: 3px;
  background: transparent;
  border-radius: 3px;
}

.sender_forms_middle_left textarea::-webkit-scrollbar-thumb {
  background: #96B6CF;
  border-radius: 3px;
}

.sender_forms_middle_left textarea::placeholder{
  color: rgba(51, 51, 51, 0.4);
}

.sender_forms_middle_right{
  width: 352px;
  background: #F7F8FB;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  padding: 9px 13px;
  max-width: 40%;
  margin-left: 10px;
}

.sender_forms_middle_right_title{
  font-weight: 500;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.8);
  margin-bottom: 10px;
}

.sender_forms_middle_media_list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sender_forms_middle_media_item{
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.sender_forms_middle_media_item:last-child{
  margin-right: 0;
}

.sender_forms_middle_media_item.photo > img{
  width: auto;
  height: 65px;
  display: block;
  border-radius: 7px;
  min-width: 30px;
}

.sender_forms_middle_media_item.video > video,
.sender_forms_middle_media_item.video > img{
  display: block;
  width: auto;
  min-width: 30px;
  height: 65px;
  object-fit: cover;
  border-radius: 7px;
}

.sender_forms_middle_media_item.audio{
  width: 65px;
  height: 65px;
  background-color: #fff;
  border: 1px solid #E4E5E7;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #357E89;
}

.sender_forms_middle_media_item.audio span{
  display: block;
  white-space: nowrap;
  max-width: 50px;
  margin-top: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sender_forms_middle_media_item.video:after{
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../../../../img/v2/sender_forms_video_play.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.sender_forms_middle_media_item_delete{
  display: none;
  width: 16px;
  height: 16px;
  background-color: #1C4B72;
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  align-items: center;
  justify-content: center;
}

.sender_forms_middle_media_item:hover .sender_forms_middle_media_item_delete{
  display: flex;
}

.sender_forms_middle_media_item_delete img{
  width: 8px;
}

.sender_forms_bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sender_forms_bottom_left{
  display: flex;
  align-items: center;
}

.sender_forms_bottom_symbols{
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #205376;
  margin-right: auto;
  flex: 1;
  text-align: center;
  align-self: center;
}

.sender_forms_bottom_btn{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}

.sender_forms_bottom_btn:last-child{
  margin: 0;
}

.sender_forms_bottom_right{
  display: flex;
  align-items: center;
}

.sender_forms_bottom_translate{
  margin-right: 22px;
}

.sender_forms_bottom_translate input{
  display: none;
}

.sender_forms_bottom_translate label{
  display: block;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #374E6C;
  padding-left: 49px;
  cursor: pointer;
}

.sender_forms_bottom_translate label:before{
  content: '';
  display: block;
  width: 36px;
  height: 16px;
  background-color: #CDD3DA;
  border-radius: 19px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: .3s;
}

.sender_forms_bottom_translate label:after{
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  transition: .3s;
}

.sender_forms_bottom_translate input:checked + label:before{
  background-color: #27AE60;
}

.sender_forms_bottom_translate input:checked + label:after{
  left: 22px;
}

.sender_forms_bottom_send_btn{
  width: 109px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(99.84deg, #357F89 4.33%, #1C4B72 98.64%);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #fff;
  cursor: pointer;
}

.sender_forms_bottom_send_btn img{
  width: 24px;
  margin-right: 5px;
  margin-bottom: 4px;
}
.not_active{
  cursor: not-allowed;
}

@media screen and (min-width: 1000px) and (max-height: 700px){
  .sender_forms_middle_left{
	height: auto;
	padding: 0px;
  }

  .sender_forms_middle_left textarea{
	height: 55px;
	border: none;
	margin: 0;
	padding: 5px;
  }
}

@media screen and (max-width: 999px){
  .sender_forms_middle_left{
	height: auto;
	padding: 5px;
  }

  .sender_forms_middle_left textarea{
	margin-bottom: 0;
  }
}

@media screen and (max-width: 599px){
  .sender_forms_top_save_btn{
	width: 80px;
	height: 30px;
	margin-right: 0;
  }

  .sender_forms_bottom_translate label{
	font-size: 12px;
	padding-left: 0;
	padding-top: 20px;
  }

  .sender_forms_bottom_translate label:before{
	left: 50%;
	top: 0;
	transform: translateX(-50%);
  }

  .sender_forms_bottom_translate label:after{
	top: 2px;
	left: calc(50% - 10px);
	transform: translateX(-50%);
  }

  .sender_forms_bottom_translate input:checked + label:after{
	left: calc(50% + 10px);
  }

  .sender_forms{
	padding: 0 5px 5px;
  }

  .sender_forms_bottom_translate{
	margin-right: 5px;
  }

  .sender_forms_top_right{
	padding-top: 0;
  }

  .sender_forms_top{
	margin-bottom: 5px;
  }

  .sender_forms_top_clear_btn{
	line-height: 30px;
  }
}
