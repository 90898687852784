@import "@sitestyles";

.notes_wrap{
	height: 40px;
	margin-top: 10px;
	position: relative;
}

.notes_position{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
}

.notes_block_head{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border: 1px solid $color1;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
}

.head_title{
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.01em;
	color: $color1;
	flex: 1;
	margin-right: 8px;
	height: 100%;
	line-height: 40px;
	padding-left: 13px;
	cursor: pointer;
}

.head_create_btn{
	width: 38px;
	height: 38px;
	background-image: url(../../../img/v2/notes-create-dark.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	margin-right: 3px;
}
