@import "@sitestyles";

.clmn_3_chat_empty{
	flex: 1;
	height: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
}

.clmn_3_chat_empty img{
	max-width: 98%;
	max-height: 98%;
}
