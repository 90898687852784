@import "@sitestyles";

.c3_smiles_list_wrap {
  position: absolute;
  bottom: -3px;
  right: calc(100% + 15px);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #f2f2f2;
  z-index: 6;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 8px;
  padding: 16px 9px 16px 16px;
  display: none;
  cursor: default;
}

.c3_smile_btn_wrap {
  position: relative;
  grid-area: c3_smile;
  display: flex;
  margin-right: 20px;
  cursor: pointer;
}

.c3_smile_btn_wrap2 {
  position: relative;
}

.c3_smile_btn_wrap2.active .c3_smiles_list_wrap {
  display: block;
}

// .c3_smile_btn_wrap2.active:before{
// 	content: "";
// 	width: 72px;
// 	height: calc(100% + 9px);
// 	position: absolute;
// 	right: -6px;
// 	top: -6px;
// 	background-color: #f2f2f2;
// 	z-index: 2;
// 	border-radius: 0 6px 6px 0;
// 	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
// }

// .c3_smile_btn_wrap2.active:after{
// 	content: "";
// 	width: 13px;
// 	height: 13px;
// 	position: absolute;
// 	left: -20px;
// 	bottom: calc(100% + 6px);
// 	background-image: url(../../../../../img/v2/c3op-smiles.svg);
// }

// .c3_sticker_wrap.active:before{
//     content: "";
//     width: 72px;
//     height: calc(100% + 9px);
//     position: absolute;
//     right: -6px;
//     top: -6px;
//     background-color: #f2f2f2;
//     z-index: 2;
//     border-radius: 0 6px 6px 0;
//     box-shadow: 0 3px 3px rgba(0,0,0,.05);
// }

.c3_smile_btn,
.c3_sticker_btn {
  cursor: pointer;
  width: 46px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 3;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #720066;
}

.c3_smiles_list {
  //width: 329px;
  height: 260px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  padding-right: 5px;

  @media screen and (max-width: 1499px) and (min-width: 1000px) {
	width: 245px;
  }
}

.c3_smiles_list::-webkit-scrollbar {
  width: 4px;
  background: #fff;
  border-radius: 3px;
}

.c3_smiles_list::-webkit-scrollbar-thumb {
  background: #adbaff;
  border-radius: 3px;
}

.c3_smiles_list_item {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.c3_smiles_list_item img {
  width: 38px;
  display: block;
}

.c3_stickers_list_wrap {
  position: absolute;
  bottom: -3px;
  right: calc(100% + 15px);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #f2f2f2;
  padding: 0 9px 6px 16px;
  z-index: 6;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 8px;
  text-align: center;
  overflow-y: hidden;
}

.c3_sticker_wrap {
  position: relative;
}

.c3_stickers_list_rows_wrap {
  padding-right: 7px;
  display: none;
}

.c3_sticker_wrap.active .c3_stickers_list_rows_wrap {
  display: block;
}

.c3_sticker_wrap.active .c3_stickers_list_rows_wrap.hide {
  display: none;
}

.c3_stickers_list_open {
  display: none;
  background: #f2f2f2;
  padding: 0 6px 6px;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.c3_stickers_list_open.active {
  display: block;
}

.c3_stickers_list_row + .c3_stickers_list_row {
  margin-top: 23px;
}

.c3_stickers_list_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  //padding-top: 16px;
}

.c3_stickers_list_head_title {
  font-weight: 600;
  color: $color1;
}

.c3_stickers_list_head_price {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: rgba(51, 51, 51, 0.3);
}

.c3_stickers_list {
  display: flex;
  align-items: center;
}

.c3_stickers_list_item {
  width: 94px;
  height: 94px;
  //margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.c3_stickers_list_item:hover {
  background-color: #d3daff;
}

.c3_stickers_list_item img {
  width: 90px;
  height: 90px;
  object-fit: contain;
  display: block;
}

.c3_stickers_list_open_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-right: 7px;
  padding-top: 3px;
}

.c3_stickers_list_open_back_btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  color: #6f0267;
  cursor: pointer;
}

.c3_stickers_list_open_back_btn img {
  margin-right: 9px;
}

.c3_stickers_list_open_title {
  font-weight: 600;
  font-size: 16px;
  color: $color1;
}

.c3_stickers_list_open_price {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  text-align: right;
  color: rgba(51, 51, 51, 0.3);
  width: 76px;
}

.c3_stickers_list_open_list {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
  width: 100%;
  height: 294px;
  overflow-y: auto;
  margin-bottom: 12px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.c3_stickers_list_open_list::-webkit-scrollbar {
  width: 2px;
  background: #fff;
  border-radius: 3px;
}

.c3_stickers_list_open_list::-webkit-scrollbar-thumb {
  background: $color1;
  border-radius: 3px;
}

.c3_stickers_list_all_btn {
  width: 49px;
  height: 29px;
  line-height: 29px;
  border: 1px solid $color1;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  color: $color1;
  margin-left: 2px;
  cursor: pointer;
}

.clmn_3_chat_bottom_option_btn {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
  cursor: pointer;
}

.clmn_3_chat_bottom_option_btn:last-child {
  margin-right: 0;
}

.c3_smile_btn_wrap2.smiles {
  background-image: url(../../../../../img/v2/c3op-smiles.svg);
  position: relative;
  z-index: 3;
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-repeat: no-repeat;
}

.clmn_3_chat_bottom_option_btn.sticker {
  background-image: url(../../../../../img/v2/c3-msg-sticker-green.svg);
  position: relative;
  z-index: 3;
}

.dis_active {
  cursor: not-allowed;
}


@media screen and (max-width: 999px) {
  .c3_smile_btn_wrap2.smiles {
	width: 24px;
	height: 24px;
	background-size: 24px;
  }

  .c3_smiles_list_wrap,
  .c3_stickers_list_wrap {
	right: unset;
	left: 0;
	bottom: calc(100% + 10px);
  }
}

@media screen and (max-width: 599px) {
  .c3_smile_btn_wrap2.smiles {
	width: 30px;
	height: 30px;
	background-size: 20px;
	background-position: center;
	margin-right: 0;
  }

  .c3_smile_btn_wrap {
	margin-right: 0;
  }

  .c3_smile_btn_wrap2 {
	display: none;
  }
  .c3_sticker_wrap {
	width: 30px;
	height: 30px;
	background-size: 20px;
	margin-right: 0;
  }

  .c3_smiles_list_wrap,
  .c3_stickers_list_wrap {
	position: fixed;
	right: unset;
	left: 10px;
	bottom: 110px;
	padding: 5px;
  }

  .c3_stickers_list_wrap {
	display: none;
  }

  .c3_sticker_wrap.sticker.active .c3_stickers_list_wrap {
	display: block;
  }

  .c3_stickers_list_item {
	width: 45px;
	height: 45px;
  }

  .c3_stickers_list_item img {
	width: 100%;
	height: 100%;
  }

  .c3_stickers_list_row + .c3_stickers_list_row {
	margin-top: 5px;
  }

  .c3_smiles_list {
	width: 290px;
  }

  .c3_stickers_list_open_list {
	//width: 290px;
	//height: 140px;
	grid-template-columns: repeat(3, 1fr);
  }

  .c3_stickers_list_open_title {
	font-size: 14px;
  }

  .c3_stickers_list_open_head {
	height: 38px;
  }
}
