@import "@sitestyles";

.user_avatar {
	display: inline-block;
	width: 72px;
	height: 72px;
	padding-top: 13px;
	border-radius: 60px;
	text-align: center;
	color: white;
	font-size: 35px;
}
