@import "@sitestyles";

.personal_page_wrap {
	flex: 1;
	height: 100%;
	display: flex;

	@media screen and (max-width: 999px) {
		flex-wrap: wrap;
	}
}

.personal_title {
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: -0.02em;
	color: $color1;

	span {
		margin-right: 10px;
	}

	@media screen and (max-width: 999px) {
		height: 32px;
		font-size: 14px;
	}
}

.personal_sort {
  align-self: center;

  &:hover {
    cursor: pointer;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3));
  }
}

.personal_inner_item {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, .05);
	padding-bottom: 5px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.personal_scroll {
	flex: 1;
	overflow-y: auto;
	margin-right: 2px;
	padding: 8px 5px 5px 10px;

	&::-webkit-scrollbar {
		width: 3px;
		background: #fff;
		border-radius: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background: #96B6CF;
		border-radius: 3px;
	}
}

.personal_long_name {
	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.personal_user_item {
	width: 100%;
	height: 61px;
	display: flex;
	align-items: center;
	padding: 0 20px;

	font-size: 16px;
	color: #464F5C;
	font-weight: 600;

	& + .personal_user_item {
		margin-top: 10px;
	}

	&.active {
		background-color: $color1;

		span {
			color: #fff;
		}
	}

	img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 50%;
		margin-right: 18px;

		@media screen and (max-width: 1599px) {
			width: 38px;
			height: 38px;
			margin-right: 12px;
		}
	}

	@media screen and (max-width: 1599px) {
		height: 50px;
		padding: 0 15px;
		font-size: 14px;
	}
}

.personal_mobile_nav {
	width: calc(100% - 10px);
	height: 50px;
	margin: 5px;
	flex: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 4px;
	padding: 0 10px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, .05);
	@media screen and (min-width: 1000px) {
		display: none;
	}
}

.personal_mobile_back {
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 12px;
	color: #B7BDC5;
	cursor: pointer;

	svg {
		margin-right: 8px;
	}
}

.personal_mobile_next {
	height: 34px;
	padding: 0 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid $color1;
	border-radius: 5px;
	margin-left: auto;
	font-weight: 600;
	font-size: 12px;
	color: $color1;
	cursor: pointer;

	svg {
		margin-left: 8px;
	}
}
