@import "@sitestyles";

.c3_woman_profile_page_wrap{
	padding: 28px 10px 0 0;
}

.c3_woman_profile_page_wrap2{
	height: calc(100vh - 128px);
	height: calc(calc(var(--vh, 1vh) * 100) - 128px);
	overflow-y: auto;
	padding: 0 14px 24px 24px;
}

.c3_woman_profile_page_wrap2::-webkit-scrollbar{
	width: 3px;
	background: #fff;
	border-radius: 22px;
}

.c3_woman_profile_page_wrap2::-webkit-scrollbar-thumb {
	background: #ADBAFF;
	border-radius: 9px;
}

.c3_woman_profile_main_block{
	background-color: #fff;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: stretch;
	align-items: stretch;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
}

.c3_woman_profile_main_block_photo{
	margin-right: 43px;
}

.c3_woman_profile_main_block_photo img{
	width: 540px;
	height: 900px;
	object-fit: cover;
	border-radius: 10px;
	display: block;
}

.c3_woman_profile_main_block_info{
	padding: 31px 46px 21px 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.c3_woman_profile_main_block_info_wrap{
	width: 100%;
}

.c3_woman_profile_main_block_top{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-items: center;
	padding-bottom: 25px;
	border-bottom: 1px solid #E0E2EB;
	margin-bottom: 22px;
	width: 100%;
}

.c3_woman_profile_main_block_name{
	font-weight: 600;
	font-size: 36px;
	line-height: 140%;
	color: #00317B;
}

.c3_woman_profile_main_block_id{
	font-size: 18px;
	letter-spacing: 0.06em;
	color: #333333;
}

.c3_woman_profile_main_block_dob{
	width: 100%;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.06em;
	color: $color1;
	margin-top: 5px;
}

.c3_woman_profile_main_block_dob span{
	font-weight: normal;
}

.c3_woman_profile_main_block_place{
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	color: $color1;
	margin-top: 10px;
}

.c3_woman_profile_main_block_place img{
	height: 18px;
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 5px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.c3_woman_profile_main_block_list{
	margin-bottom: 22px;
	width: 100%;
}

.c3_woman_profile_main_block_list_title{
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $color1;
	margin-bottom: 16px;
}

.c3_woman_profile_main_block_list_item{
	font-size: 16px;
	letter-spacing: 0.06em;
	color: $color3;
	margin-bottom: 8px;
	padding-left: 20px;
	text-transform: capitalize;
	line-height: 1.8;
}

.c3_woman_profile_main_block_list_item:last-child{
	margin-bottom: 0;
}

.c3_woman_profile_main_block_list_item span{
	font-weight: 600;
	color: #00317B;
}

.c3_woman_profile_main_block_list_item span.not_specs{
	color: #EB5757;
}

.c3_woman_profile_main_block_buttons{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	-ms-align-self: flex-end;
	align-self: flex-end;
}

.c3_woman_profile_main_block_btn{
	width: 190px;
	height: 44px;
	line-height: 44px;
	background-color: #01317B;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.06em;
	text-align: center;
	margin-left: 22px;
	cursor: pointer;
}

.c3_woman_profile_main_block_btn img{
	vertical-align: middle;
	margin-right: 12px;
}

.c3_woman_profile_second_block{
	background-color: #fff;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	margin-top: 30px;
	padding: 28px;
}

.c3_woman_profile_tabs_wrap{
	margin-bottom: 40px;
}

.c3_woman_profile_tabs_links_wrap{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	margin-bottom: 28px;
}

.c3_woman_profile_tabs_link{
	width: 314px;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $color3;
	text-align: center;
	border-bottom: 1px solid #E0E2EB;
	padding-bottom: 10px;
	cursor: pointer;
}

.c3_woman_profile_tabs_link.active{
	color: #00317B;
	font-weight: bold;
	border-bottom: 3px solid #1A237E;
	padding-bottom: 8px;
}

.c3_woman_profile_tab_block{
	display: none;
}

.c3_woman_profile_tab_block.active{
	display: block;
}

.c3_woman_profile_tab_about{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.c3_woman_profile_tab_about_item{
	width: calc(50% - 28px);
}

.c3_woman_profile_tab_about_item_title{
	font-weight: 600;
	font-size: 24px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $color1;
	margin-bottom: 29px;
}

.c3_woman_profile_tab_about_item_text{
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	line-height: 150%;
	text-align: justify;
	color: #4F4F4F;
}

.c3_woman_profile_tab_blog{
	padding-top: 10px;
}

.c3_woman_profile_tab_blog_item{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	margin-bottom: 30px;
}

.c3_woman_profile_tab_blog_item_photos{
	width: 218px;
	margin-right: 32px;
}

.c3_woman_profile_tab_blog_item_big_photo img{
	width: 218px;
	height: 364px;
	object-fit: cover;
	display: block;
	border-radius: 5px;
}

.c3_woman_profile_tab_blog_item_small_photos{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.c3_woman_profile_tab_blog_item_small_photos img{
	width: 65px;
	height: 108px;
	object-fit: cover;
	display: block;
	border-radius: 5px;
}

.c3_woman_profile_tab_blog_item_content_title{
	font-weight: 600;
	font-size: 24px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $color1;
	margin-bottom: 13px;
}

.c3_woman_profile_tab_blog_item_content_date{
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	color: rgba(51, 51, 51, 0.6);
	margin-bottom: 32px;
}

.c3_woman_profile_tab_blog_item_content_text p{
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	line-height: 150%;
	color: #333333;
	margin-bottom: 30px;
}

.c3_woman_profile_tab_blog_pagination{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 100%;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
}

.c3_woman_profile_tab_blog_pagination_nums{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	margin: 0 10px;
}

.c3_woman_profile_tab_blog_pagination_current{
	font-family: "Roboto", sans-serif;
	width: 28px;
	height: 28px;
	line-height: 30px;
	text-align: center;
	border-radius: 100%;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	color: #69095B;
	margin-right: 8px;
	outline: none;
}

.c3_woman_profile_tab_blog_pagination_all{
	font-family: "Roboto", sans-serif;
	color: #999;
}

.c3_woman_profile_tab_blog_pagination_buttons{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
}

.c3_woman_profile_tab_blog_pagination_btn{
	width: 27px;
	height: 13px;
	border-radius: 5px;
	background-image: url(../img/c3wp-pagin-disable.svg);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.c3_woman_profile_tab_blog_pagination_btn:last-child{
	transform: rotate(180deg);
	margin-left: 5px;
}

.c3_woman_profile_tab_blog_pagination_btn.active{
	background-color: #69095B;
	background-image: url(../img/c3wp-pagin-active.svg);
}

.c3_woman_profile_tab_blog_pagination_btn.active:first-child{
	transform: rotate(180deg);
}

.c3_woman_profile_tab_blog_pagination_btn.active:last-child{
	transform: unset;
}

.c3_woman_profile_page_title{
	font-weight: 600;
	font-size: 24px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $color1;
	margin-bottom: 34px;
}

.c3_woman_profile_gallery_photo_wrap{
	margin-bottom: 50px;
}

.c3_woman_profile_gallery_video_list_wrap{
	width: calc(100vw - 652px);
	overflow-y: auto;
	padding-bottom: 23px;
	position: relative;
}

.c3_woman_profile_gallery_video_list_wrap::-webkit-scrollbar{
	height: 5px;
	background: #E0E2EB;
	border-radius: 22px;
}

.c3_woman_profile_gallery_video_list_wrap::-webkit-scrollbar-thumb {
	background: #00317B;
	border-radius: 9px;
}

.c3_woman_profile_gallery_video_list{
	width: 3000px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.c3_woman_profile_gallery_video_list_item{
	width: 617px;
	background-color: #fff;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	margin-right: 23px;
}

.c3mp_video_list_item_play_btn{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.c3_woman_profile_gallery_video_list_item .c3mp_video_list_item{
	margin: 0;
	width: 100%;
}

.c3_woman_profile_gallery_video_list_item .c3mp_video_list_item_img{
	border-radius: 10px;
	width: 100%;
	object-fit: cover;
}

.c3_woman_profile_gallery_video_list_item_img_wrap{
	position: relative;
	cursor: pointer;
}

.c3_woman_profile_gallery_video_list_item_img{
	width: 100%;
	height: 357px;
	border-radius: 10px;
	object-fit: cover;
	display: block;
}

.c3_woman_profile_gallery_video_list_item_play{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.c3_woman_profile_gallery_video_list_item_play img{
	width: 100px;
}

.c3_woman_profile_gallery_video_list_item_bottom{
	height: 84px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	font-weight: 500;
	font-size: 24px;
	color: #00317B;
	padding: 0 19px;
}

.c3_woman_profile_gallery_photo_top_big_list_wrap{
	width: calc(100vw - 652px);
	overflow: hidden;
	position: relative;
}

.c3_woman_profile_gallery_photo_btn{
	position: absolute;
	top: 50%;
	transform: translateY(calc(-50% - 7px));
	padding: 5px;
	cursor: pointer;
}

.c3_woman_profile_gallery_photo_btn.btn_prev{
	left: 5px;
}

.c3_woman_profile_gallery_photo_btn.btn_next{
	right: 5px;
}


.c3_woman_profile_gallery_photo_top_big_list{
	width: 3000px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.c3_woman_profile_gallery_photo_top_big_item{
	border-radius: 10px;
	overflow: hidden;
	margin-right: 16px;
	flex: none;
	cursor: pointer;
}

.c3_woman_profile_gallery_photo_top_big_item img{
	height: 714px;
	display: block;
	object-fit: cover;
}

.c3_woman_profile_gallery_photo_top_big_item.unpaid img{
	filter: blur(40px);
}

.c3_woman_profile_gallery_photo_top_small_list_wrap{
	width: calc(100vw - 652px);
	padding-bottom: 21px;
	overflow-x: auto;
	margin-top: 20px;
}

.c3_woman_profile_gallery_photo_top_small_list_wrap::-webkit-scrollbar{
	height: 5px;
	background: #E0E2EB;
	border-radius: 22px;
}

.c3_woman_profile_gallery_photo_top_small_list_wrap::-webkit-scrollbar-thumb {
	background: #00317B;
	border-radius: 9px;
}

.c3_woman_profile_gallery_photo_top_small_list{
	/* width: 3000px; */
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}

.c3_woman_profile_gallery_photo_top_small_list_item{
	border-radius: 10px;
	overflow: hidden;
	margin-right: 18px;
	flex: none;
	cursor: pointer;
}

.c3_woman_profile_gallery_photo_top_small_list_item img{
	width: 110.23px;
	height: 183px;
	display: block;
	object-fit: cover;
}

.c3_woman_profile_gallery_photo_top_small_list_item.unpaid img{
	filter: blur(30px);
}

.c3_woman_profile_head{
	height: 100px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
}

.c3_woman_profile_head_item{
	background-color: #fff;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
	height: 76px;
	margin-right: 33px;
	background-color: #fff;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	padding: 0 15px 0 20px;
	border-radius: 10px;
	cursor: pointer;
}

.c3_woman_profile_head_item:last-child{
	margin-right: 0;
}

.c3_woman_profile_head_item.disabled{
	background-color: #FCFCFC;
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: default;
}

.c3_woman_profile_head_item_text{
	flex: none;
	font-weight: 600;
	font-size: 20px;
	color: #00317B;
	background: linear-gradient(90.34deg, #00317B 0%, #720066 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.disabled .c3_woman_profile_head_item_text{
	background: none;
	color: #848484;
	-webkit-background-clip: unset;
	-webkit-text-fill-color: unset;
}

.c3_woman_profile_head_item_img{
	width: 38px;
	height: 38px;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 15px;
	flex: none;
}

.c3wphi_letter .c3_woman_profile_head_item_img{
	background-image: url(../img/c3wpt_mail.svg);
}

.c3wphi_chat .c3_woman_profile_head_item_img{
	background-image: url(../img/c3wpt_chat.svg);
}

@media screen and (max-width: 1899px){
	.c3_woman_profile_page_wrap2{
		height: calc(100vh - 80px);
		height: calc(calc(var(--vh, 1vh) * 100) - 80px);
	}

	.c3_woman_profile_page_wrap{
		padding-top: 0;
	}

	.c3_woman_profile_head{
		justify-content: center;
		height: 70px;
		margin-bottom: 10px;
	}

	.c3_woman_profile_head_item{
		flex-wrap: wrap;
		width: 116px;
		text-align: center;
		padding: 0;
		align-items: flex-end;
		margin-right: 8px;
		height: 62px;
	}

	.c3_woman_profile_head_item.c3wphi_webcam{
		width: 145px;
	}

	.c3_woman_profile_head_item_text{
		font-size: 14px;
		width: 100%;
		margin-bottom: 6px;
	}

	.c3_woman_profile_head_item_img{
		width: 29px;
		height: 29px;
		background-size: contain;
		margin: 5px auto 0;
	}

	.c3_woman_profile_head_item_text br{
		display: none;
	}
}

@media screen and (max-width: 1649px){
	.c3_woman_profile_gallery_photo_top_big_list_wrap,
	.c3_woman_profile_gallery_photo_top_small_list_wrap,
	.c3_woman_profile_gallery_video_list_wrap{
		width: calc(100vw - 567px);
	}

	.c3_woman_profile_main_block_photo img{
		width: 441px;
		height: 793px;
	}

	.c3_woman_profile_main_block_list_title{
		font-size: 16px;
		margin-bottom: 5px;
	}

	.c3_woman_profile_main_block_list_item{
		font-size: 14px;
	}

	.c3_woman_profile_main_block_list{
		margin-bottom: 10px;
	}

	.c3_woman_profile_main_block_name{
		font-size: 30px;
	}
}

@media screen and (max-width: 1499px){
	.c3_woman_profile_gallery_photo_top_big_list_wrap,
	.c3_woman_profile_gallery_photo_top_small_list_wrap,
	.c3_woman_profile_gallery_video_list_wrap{
		width: calc(100vw - 493px);
	}

	.c3_woman_profile_gallery_video_list_item .c3mp_video_list_item_img{
		height: 201px;
	}

	.c3_woman_profile_gallery_photo_top_big_item img{
		width: 300px;
		height: 500px;
	}

	.c3_woman_profile_gallery_photo_top_small_list_item img{
		width: 90px;
		height: 150px;
	}
}

@media screen and (max-width: 1449px){
	.c3_woman_profile_page_wrap{
		padding-top: 0;
	}
	.c3_woman_profile_main_block_name{
		font-size: 26px;
	}

	.c3_woman_profile_main_block_id{
		font-size: 14px;
	}

	.c3_woman_profile_main_block_dob{
		font-size: 14px;
	}

	.c3_woman_profile_main_block_place{
		font-size: 14px;
	}

	.c3_woman_profile_main_block_top{
		padding-bottom: 15px;
		margin-bottom: 10px;
	}

	.c3_woman_profile_main_block_photo img{
		width: 361px;
		height: 670px;
	}

	.c3_woman_profile_main_block_list_item{
		font-size: 12px;
	}

	.c3_woman_profile_main_block_photo{
		padding: 24px 0 24px 24px;
	}

	.c3_woman_profile_main_block_info{
		padding: 24px 10px 24px 0;
	}

	.c3_woman_profile_main_block_btn{
		width: 149px;
		height: 32px;
		line-height: 32px;
		font-size: 14px;
	}

	.c3_woman_profile_main_block_btn img{
		margin-right: 8px;
		margin-bottom: 2px;
	}

	.c3_woman_profile_main_block_btn:first-child img{
		width: 19px;
	}

	.c3_woman_profile_main_block_btn:last-child img{
		width: 21px;
	}

	.c3_woman_profile_gallery_video_list_item{
		width: 348px;
	}

	.c3_woman_profile_gallery_video_list_item_img{
		height: 201px;
	}

	.c3_woman_profile_gallery_video_list_item_bottom{
		height: 38px;
		font-size: 14px;
	}

	.c3_woman_profile_gallery_video_list_item_play img{
		width: 50px;
	}
}

@media screen and (max-width: 1199px){
	.c3_woman_profile_gallery_photo_top_big_list_wrap,
	.c3_woman_profile_gallery_photo_top_small_list_wrap,
	.c3_woman_profile_gallery_video_list_wrap{
		width: calc(100vw - 238px);
	}
}

@media screen and (max-width: 999px){
	.c3_woman_profile_page_wrap2{
		height: calc(100vh - 160px);
		height: calc(calc(var(--vh, 1vh) * 100) - 160px);
	}

	.c3_woman_profile_gallery_photo_top_big_list_wrap,
	.c3_woman_profile_gallery_photo_top_small_list_wrap,
	.c3_woman_profile_gallery_video_list_wrap{
		width: calc(100vw - 108px);
	}

	.c3_woman_profile_gallery_photo_top_big_item img{
		width: 240px;
		height: 400px;
	}

	.c3_woman_profile_gallery_photo_top_small_list_item img{
		width: 46px;
		height: 77px;
	}

	.c3_woman_profile_gallery_video_list_item .c3mp_video_list_item_img{
		height: 167px;
	}

	.c3_woman_profile_gallery_video_list_item{
		width: 275px;
	}

	.c3_woman_profile_gallery_video_list_item_img{
		height: 167px;
	}

	.c3_woman_profile_gallery_video_list_item_play img{
		width: 42px;
	}

	.c3_woman_profile_gallery_photo_top_big_item{
		margin-right: 10px;
	}
}

@media screen and (max-width: 899px){
	.c3_woman_profile_head{
		display: none;
	}

	.c3_woman_profile_page_wrap{
		padding-top: 8px;
	}
	.c3_woman_profile_page_wrap2{
		height: calc(100vh - 88px);
		height: calc(calc(var(--vh, 1vh) * 100) - 88px);
	}
}

@media screen and (max-width: 849px){
	.c3_woman_profile_main_block_buttons{
		display: block;
	}

	.c3_woman_profile_main_block_btn{
		margin: 0 auto 10px;
	}

	.c3_woman_profile_main_block_btn:last-child{
		margin-bottom: 0;
	}
}

@media screen and (max-width: 767px){
	.c3_woman_profile_main_block{
		display: block;
	}

	.c3_woman_profile_main_block_photo{
		margin-right: 0;
		padding-right: 24px;
	}

	.c3_woman_profile_main_block_buttons{
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
	}

	.c3_woman_profile_main_block_btn{
		margin: 0 0 10px 24px;
	}

	.c3_woman_profile_main_block_info{
		padding: 24px;
	}

	.c3_woman_profile_main_block_photo img{
		margin: auto;
	}

	.c3_woman_profile_tabs_link{
		width: 50%;
	}
}

@media screen and (max-width: 599px){
	.c3_woman_profile_page_wrap2{
		height: calc(100vh - 72px);
		height: calc(calc(var(--vh, 1vh) * 100) - 72px);
		padding: 0 5px 16px 10px;
	}

	.c3_woman_profile_tab_about{
		display: block;
	}

	.c3_woman_profile_tab_about_item{
		width: 100%;
		margin-bottom: 30px;
	}

	.c3_woman_profile_tab_about_item:last-child{
		margin-bottom: 0;
	}

	.c3_woman_profile_tab_blog_item{
		display: block;
		margin-bottom: 60px;
		border-bottom: 1px solid #ccc;
	}

	.c3_woman_profile_tab_blog_item_photos{
		margin: 0 auto 24px;
	}

	.c3_woman_profile_tab_blog_item_content_text p{
		margin-bottom: 16px;
	}

	.c3_woman_profile_main_block_photo img{
		width: 200px;
		height: 340px;
	}

	.c3_woman_profile_tabs_link{
		font-size: 14px;
	}

	.c3_woman_profile_tab_about_item_title{
		font-size: 16px;
		margin-bottom: 8px;
	}

	.c3_woman_profile_page_wrap{
		padding-right: 3px;
	}

	.c3_woman_profile_main_block_name{
		font-size: 24px;
	}

	.c3_woman_profile_main_block_id{
		font-size: 12px;
	}

	.c3_woman_profile_main_block_dob{
		font-size: 12px;
		margin-top: 12px;
	}

	.c3_woman_profile_main_block_dob span{
		display: block;
		margin-top: 3px;
	}

	.c3_woman_profile_main_block_place{
		font-size: 12px;
		margin-top: 12px;
	}

	.c3_woman_profile_main_block_place img{
		height: 13px;
	}

	.c3_woman_profile_main_block_buttons{
		display: block;
	}

	.c3_woman_profile_main_block_btn{
		margin: 8px auto 0;
	}

	.c3_woman_profile_gallery_photo_top_big_list_wrap,
	.c3_woman_profile_gallery_photo_top_small_list_wrap,
	.c3_woman_profile_gallery_video_list_wrap{
		width: calc(100vw - 44px);
	}

	.c3_woman_profile_second_block{
		padding: 28px 10px;
	}

	.c3_woman_profile_tab_about_item_text{
		font-size: 12px;
	}
}

.c3_woman_profile_gallery_photo_top_big_item{
	position: relative;
}

.c3_woman_profile_gallery_photo_top_big_item.unpaid .popup_likeher_photo_unpaid_warning {
	display: block;
}
