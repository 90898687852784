@import "@sitestyles";

.clmn_1_bottom_nav {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	-ms-align-items: center;
	align-items: center;
	margin-top: 20px;
}

.clmn_1_bottom_nav.opened {
	position: relative;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.clmn_1_bottom_nav.opened .clmn_1_bottom_menu_list {
	display: block;
}

.clmn_1_bottom_nav .clmn_1_bottom_menu_wrap {
	width: 100%;
}

.clmn_1_bottom_menu_list {
	display: none;
	width: 100%;
	position: absolute;
	bottom: 100%;
	left: 0;
	background-color: $color2;
	border-radius: 5px 5px 0 0;
	padding: 3px;
}

.clmn_1_bottom_menu_item {
	height: 40px;
	margin-bottom: 1px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding-left: 42px;
	font-weight: 500;
	letter-spacing: 0.01em;
	color: rgba(51, 51, 51, 0.7);
	background-repeat: no-repeat;
	background-position: 16px center;
	cursor: pointer;
	border-radius: 3px;
}

.clmn_1_bottom_menu_item:hover {
	background-color: #fff;
}

.clmn_1_bottom_menu_item.active {
	background-color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	color: $color1;
}

.clmn_1_bottom_menu_item.search {
	background-image: url(../../../img/v2/c1-menu/c1b-search.svg);
}

.clmn_1_bottom_menu_item.search.active {
	background-image: url(../../../img/v2/c1-menu/c1b-search-a.svg);
}

.clmn_1_bottom_menu_item.profile {
	background-image: url(../../../img/v2/c1-menu/c1b-profile.svg);
}

.clmn_1_bottom_menu_item.profile.active {
	background-image: url(../../../img/v2/c1-menu/c1b-profile-a.svg);
}

.clmn_1_bottom_menu_item.stats {
	background-image: url(../../../img/v2/c1-menu/c1b-stats.svg);
}

.clmn_1_bottom_menu_item.stats.active {
	background-image: url(../../../img/v2/c1-menu/c1b-stats-a.svg);
}


.clmn_1_bottom_menu_item.sender_stats {
	background-image: url(../../../img/v2/c1-menu/c1b-sender-stats.svg);
}

.clmn_1_bottom_menu_item.sender_stats.active {
	background-image: url(../../../img/v2/c1-menu/c1b-sender-stats-a.svg);
}


.clmn_1_bottom_menu_item.online_offline {
	background-image: url(../../../img/v2/c1-menu/c1b-on-off.svg);
}

.clmn_1_bottom_menu_item.online_offline.active {
	background-image: url(../../../img/v2/c1-menu/c1b-on-off-a.svg);
}


.clmn_1_bottom_menu_item.black_list {
	background-image: url(../../../img/v2/c1-menu/c1b-block.svg);
}

.clmn_1_bottom_menu_item.black_list.active {
	background-image: url(../../../img/v2/c1-menu/c1b-block-a.svg);
}


.clmn_1_bottom_menu_item.tutorial {
	background-image: url(../../../img/v2/c1-menu/c1b-tutorial.svg);
	background-position: 17px center;
}

.clmn_1_bottom_menu_item.tutorial.active {
	background-image: url(../../../img/v2/c1-menu/c1b-tutorial-a.svg);
}

.clmn_1_bottom_menu_item.dis_active {
	cursor: not-allowed;
}

.clmn_1_bottom_menu_item.sign_out {
	background-image: url(../../../img/v2/c1-menu/c1b-sign-out.svg);
}

.clmn_1_bottom_menu_item.sign_out.active {
	background-image: url(../../../img/v2/c1-menu/c1b-sign-out-a.svg);
}

.clmn_1_bottom_menu_btn {
	height: 60px;
	line-height: 60px;
	background-color: $color2;
	box-shadow: 0px -8px 16px rgba(221, 220, 236, 0.32);
	border-radius: 5px;
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	color: $color3;
	cursor: pointer;
}

.clmn_1_bottom_nav.opened .clmn_1_bottom_menu_btn {
	width: 100%;
	border-radius: 0 0 5px 5px;
	position: relative;
}

.clmn_1_bottom_nav.opened .clmn_1_bottom_menu_btn:before {
	content: '';
	display: block;
	width: calc(100% - 20px);
	height: 1px;
	position: absolute;
	left: 10px;
	top: -1px;
	background-color: #fff;
}

.clmn_1_bottom_hide_btn {
	width: 54px;
	height: 60px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../../../img/v2/c1-hide.svg);
	background-color: #fff;
	box-shadow: 0px -8px 16px rgba(221, 220, 236, 0.32);
	border-radius: 5px;
	cursor: pointer;
	display: none; /*delete this*/
}

.clmn_1_bottom_nav.opened .clmn_1_bottom_hide_btn {
	display: none;
}
